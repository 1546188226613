import React from 'react'
import { Badge } from '@mui/material'
import { styled } from '@mui/system'
import { mainColor } from './theme'

export const StyledBadge = styled(Badge)(
    ({ ...props }) => {
        return {
            '& .MuiBadge-badge': {
                backgroundColor: props.badgeColor ?? mainColor,
                color: 'white',
            },
        }
    }
)

export const StyledBadgeDesktop = styled(({ ...props }) => (
    <Badge
        anchorOrigin= {{
            vertical: 'top',
            horizontal: 'left',
        }}
        sx={{
            '& .MuiBadge-badge': {
                left: '-5',
                backgroundColor: props.badgeColor ?? mainColor,
                color: 'white',
            }
        }}
        {...props}
    />
))({})