import PropTypes from 'prop-types'
import { LOGIN } from '../pages/offline/constants/HomeConstants'

const getLabel = (propList, code, labelName, key = 'code', defaultReturn = '') => {
    const found = propList.find(elem => elem[key] == code)
    if (found) {
        if (labelName && found[labelName]) {
            return found[labelName]
        }
        if (found.name) {
            return found.name
        }
        if (found.label) {
            return found.label
        }
        if (found.title) {
            return found.title
        }
    }
    return defaultReturn
}

const createIndex = (tab, keyToUse) => {
    const res = {}
    if (keyToUse) {
        if (tab.length) {
            tab.forEach(o => {
                res[o[keyToUse]] = o
            })
            return res
        }
        return {}
    }
    if (tab.length) {
        tab.forEach(o => {
            res[o.id] = o
        })
        return res
    }
    return {}
}

const removeNullKeys = (obj) => {
    return Object.keys(obj).filter(k => !(obj[k] === undefined || obj[k] === null)).reduce((acc, key) => {
        const r = {}
        r[key] = obj[key]
        return Object.assign({}, acc, r)
    }, {})
}

const getUser = (users) => {
    return users.find(u => u.login == localStorage.getItem(LOGIN)) || {}
}

const getSandreList = (sandreCodes, field) => {
    return sandreCodes.filter(c => c.field === field)
}

const arrayOf = (dto) => {
    return PropTypes.arrayOf(PropTypes.instanceOf(dto))
}

const instanceOf = (dto) => {
    return PropTypes.instanceOf(dto)
}

export { getLabel, getSandreList, getUser, arrayOf, instanceOf, removeNullKeys, createIndex }