import DtoRSEauStrategy from './DtoRSEauStrategy'

export default class DtoRSEauLandState {
    constructor(object) {
        this.landId = object.landId // Int,
        this.landName = object.landName // Option[String],
        this.waterStressScore = object.waterStressScore // Option[Int],
        this.waterStressDelay = object.waterStressDelay // Option[Int],
        this.strategies = object.strategies ? object.strategies.map((s) => new DtoRSEauStrategy(s)) : [] // Seq[RSEauStrategy]
        this.dose = object.dose // Option[Double]
    }
}
