/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { CircularProgress, Grid, TextField } from '@mui/material'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import AgriAction from '../agri/actions/AgriAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import HomeAction from '../../offline/actions/HomeAction'
import LoadingCard from '../components/cards/LoadingCard'
import { MainButton } from '../components/styled/buttons'
import { CHRONICLES_CONSTANTS } from '../agri/constants/AgriConstants'
import moment from 'moment'
import useApplicationSetting from '../../../utils/customHooks/useApplicationSetting'
import { mainColor, veryLightColor } from '../components/styled/theme'
import { isNil, orderBy } from 'lodash'
import { getEvolValue } from '../../../utils/AgriUtils'
import { getDate } from '../../../utils/DateUtil'
import { formatMilliers } from '../../../utils/StringUtil'
import { DeleteOutlined } from '@mui/icons-material'
import { styled } from '@mui/styles'
import ToastrAction from '../components/toasters/ToastrAction'
import { LightCard } from '../components/styled/grid'

const Line = styled(Grid)({
    padding: '16px 8px',
})

const IndexEntriesApp = ({ match }) => {
    const [chroniclesLoaded, setChroniclesLoaded] = useState(true)
    const [pointsLoaded, setPointsLoaded] = useState(false)
    const [measureDate, setMeasureDate] = useState(getDate(moment().valueOf(), 'YYYY-MM-DD'))
    const [endDate, setEndDate] = useState('')
    const [value, setValue] = useState('')

    const dispatch = useDispatch()

    const idInstallation = useMemo(() => Number(match.params.id), [match])
    const idMat = useMemo(() => Number(match.params.idMat), [match])

    const {
        exploitation,
        installations,
        variousMateriels,
        variousMatSituations,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
    }), shallowEqual)

    useEffect(() => {
        dispatch(HomeAction.setTitle([{
            title: i18n.newEntry,
            href: `/index/${idInstallation}/materiels/${idMat}/entries`,
        }]))
    }, [idInstallation, idMat])

    useEffect(() => {
        if (exploitation.idExploitation) {
            if (!installations.length) {
                dispatch(InstallationsAction.fetchInstallationsByExploitationId(exploitation.idExploitation)).then(() => {
                    setPointsLoaded(true)
                })
            } else {
                setPointsLoaded(true)
            }
        }
    }, [exploitation, installations])

    const materielsTypeForIndex = useApplicationSetting('materielsTypeForIndex', (setting) => setting ? setting.split(',').map((id) => Number(id)) : [])

    const installation = useMemo(() => installations.find((i) => i.id === idInstallation) || {}, [installations, idInstallation])
    const materiel = useMemo(() => variousMateriels.find((m) => m.id === idMat) || {}, [variousMateriels, idMat])

    const consoType = useMemo(() => {
        return materielsTypeForIndex.includes(materiel.materielType) ? CHRONICLES_CONSTANTS.TYPE_INDEX : CHRONICLES_CONSTANTS.TYPE_ESTIM
    })

    const addChronicle = () => {
        if (measureDate && !isNil(value)) {
            const link_chronicles = [
                ...exploitation.link_chronicles,
                {
                    matType: 'divers',
                    idMat,
                    measureType: consoType,
                    measureDate: new Date(measureDate).getTime(),
                    value,
                    endDate: consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM ? new Date(endDate).getTime() : undefined,
                    idChronicle: undefined,
                    new: true,
                }
            ]
            const updateExploitation = {
                ...exploitation,
                link_chronicles,
            }
            setChroniclesLoaded(false)
            dispatch(AgriAction.updateExploitation(updateExploitation)).then(() => {
                ToastrAction.success(i18n.entryValidated)
                dispatch(AgriAction.fetchExploitation()).then(() => setChroniclesLoaded(true))
            })
            setMeasureDate(consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM ? endDate : '')
            setValue('')
            setEndDate('')
        } else {
            ToastrAction.error(i18n.fillAllFields)
        }
    }

    const removeChronicle = (idChronicle) => {
        const link_chronicles = [
            ...exploitation.link_chronicles.filter((c) => c.idChronicle !== idChronicle),
            {
                ...exploitation.link_chronicles.find((c) => c.idChronicle === idChronicle),
                mode: 'd',
            }
        ]
        const updateExploitation = {
            ...exploitation,
            link_chronicles,
        }
        setChroniclesLoaded(false)
        dispatch(AgriAction.updateExploitation(updateExploitation)).then(() => {
            ToastrAction.success(i18n.entryDeleted)
            dispatch(AgriAction.fetchExploitation()).then(() => setChroniclesLoaded(true))
        })
    }

    if (pointsLoaded && variousMatSituations.length) {
        const filteredChronicles = exploitation.link_chronicles.filter((c) => c.idMat === idMat && c.mode !== 'd' && c.measureType === consoType)
        const orderedChronicles = orderBy(filteredChronicles, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
        const readingCoefficient = materiel?.counter?.readingCoefficient || 1
        const isEstim = consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM
        return (
            <div style={{ padding: '8px 1.5rem' }}>
                <LightCard container direction='column' sx={{ border: `1px solid ${mainColor}` }}>
                    <Grid item fontSize={22} sx={{ marginBottom: '14px' }}>{installation?.name || `${i18n.point} ${installation?.code ?? ''}`}</Grid>
                    <Grid item container fontSize={14} sx={{ marginBottom: '24px' }}>
                        <Grid item>{materiel.counter ? i18n.counter : i18n.pump}</Grid>
                        <Grid item className='bold' sx={{ marginLeft: '8px' }}>{materiel?.reference || ''}</Grid>
                    </Grid>
                    <TextField
                        id='startDate'
                        label={isEstim ? i18n.startDate : i18n.statementDate}
                        type='date'
                        required
                        variant='outlined'
                        value={measureDate}
                        inputProps={{
                            max: endDate || '9999-12-31'
                        }}
                        onChange={(e) => setMeasureDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ marginBottom: '16px' }}
                    />
                    {isEstim && (
                        <TextField
                            id='endDate'
                            label={i18n.endDate}
                            type='date'
                            inputProps={{
                                min: measureDate,
                                max: '9999-12-31'
                            }}
                            required
                            variant='outlined'
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ marginBottom: '16px' }}
                        />
                    )}
                    <TextField
                        id='value'
                        label={consoType === 1 ? i18n.estim : i18n.index}
                        type='number'
                        value={value}
                        onChange={(e) => setValue(parseInt(e.target.value))}
                        variant='outlined'
                        sx={{ marginBottom: '16px' }}
                        required
                    />
                    <MainButton disabled={!chroniclesLoaded} onClick={() => addChronicle()}>
                        {i18n.add}
                    </MainButton>
                </LightCard>
                <Grid item fontSize={22} sx={{ marginTop: '24px' }}>{i18n.historical}</Grid>
                <Grid
                    container
                    alignItems='center'
                    justifyContent='space-between'
                    sx={{ marginTop: '16px', color: mainColor }}
                >
                    <Line item xs={12} container>
                        {consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM ? (
                            <>
                                <Grid item className='bold' xs={3} sx={{ textAlign: 'start' }}>
                                    {i18n.startDate }
                                </Grid>
                                <Grid item className='bold' xs={3} sx={{ textAlign: 'start' }}>
                                    {i18n.endDate }
                                </Grid>
                                <Grid item className='bold' xs={3} sx={{ textAlign: 'end' }}>
                                    {i18n.estimateM3 }
                                </Grid>
                                <Grid item className='bold' xs={2} sx={{ textAlign: 'end' }}>
                                    {'Cumul (m3)'}
                                </Grid>
                                <Grid item className='bold' xs={1} sx={{ textAlign: 'end' }} />
                            </>
                        ) : (
                            <>
                                <Grid item className='bold' xs={3} sx={{ textAlign: 'start' }}>
                                    {i18n.statementDate}
                                </Grid>
                                <Grid item className='bold' xs={3} sx={{ textAlign: 'end' }}>
                                    {i18n.index}
                                </Grid>
                                <Grid item className='bold' xs={4} sx={{ textAlign: 'end' }}>
                                    {`${i18n.evolution} (m3)`}
                                </Grid>
                                <Grid item className='bold' xs={1} sx={{ textAlign: 'end' }}>{' '}</Grid>
                            </>
                        )}
                    </Line>
                    <Grid item className xs={12} sx={{ borderBottom: '1px solid black' }} />
                    {chroniclesLoaded ? orderedChronicles.map((chronicle, i) => {
                        const backgroundColor = i % 2 ? veryLightColor : 'white'
                        const valueEvol = i !== filteredChronicles.length - 1 ? getEvolValue(consoType, orderedChronicles, chronicle, i) : null
                        if (consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM) {
                            return (
                                <Line item xs={12} container>
                                    <Grid item xs={3} sx={{ textAlign: 'start', paddingLeft: 0 }}>
                                        {getDate(chronicle.measureDate, 'DD/MM')}
                                    </Grid>
                                    <Grid item xs={3} sx={{ textAlign: 'start' }}>
                                        {chronicle.endDate ? getDate(chronicle.endDate, 'DD/MM') : ''}
                                    </Grid>
                                    <Grid item xs={3} sx={{ textAlign: 'end' }}>
                                        {`${formatMilliers(chronicle.value) || 0} m3`}
                                    </Grid>
                                    <Grid item xs={2} sx={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                        {!isNil(valueEvol) ? formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0)) : ''}
                                    </Grid>
                                    <Grid item xs={1} container justifyContent='flex-end'>
                                        <DeleteOutlined className='clickable' onClick={() => removeChronicle(chronicle.idChronicle)} />
                                    </Grid>
                                </Line>
                            )
                        }
                        return (
                            <Line item xs={12} container alignItems='center' sx={{ backgroundColor }}>
                                <Grid item xs={3} sx={{ textAlign: 'start', paddingLeft: 0 }}>
                                    {getDate(chronicle.measureDate)}
                                </Grid>
                                <Grid item xs={3} sx={{ textAlign: 'end' }}>
                                    {formatMilliers(chronicle.value) || 0}
                                </Grid>
                                <Grid item xs={4} sx={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                    {!isNil(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0))}` : ''}
                                </Grid>
                                <Grid item xs={2} container justifyContent='flex-end'>
                                    <DeleteOutlined className='clickable' onClick={() => removeChronicle(chronicle.idChronicle)} />
                                </Grid>
                            </Line>
                        )
                    }) : <Grid item xs={12} container justifyContent='center' sx={{ paddingTop: '16px' }}><CircularProgress /></Grid>}
                </Grid>
            </div>
        )
    }
    return <LoadingCard />
}

IndexEntriesApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            idMat: PropTypes.string,
        })
    }),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
}

export default IndexEntriesApp
