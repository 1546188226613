/* eslint-disable max-nested-callbacks */
/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { FormControlLabel, Grid, Radio, RadioGroup, useTheme } from '@mui/material'
import { compact, uniqBy } from 'lodash'
import { MainButton, TextButton } from '../../components/styled/buttons'
import DtoSamplingPointDecla from '../../agri/dto/exploitation/DtoSamplingPointDecla'
import ReferencialAction from '../../referencials/actions/ReferencialAction'
import AgriAction from '../../agri/actions/AgriAction'
import InstallationsAction from '../../referencials/installations/actions/InstallationsAction'
import CmsAction from '../../cms/actions/CmsAction'
import { hasValue } from '../../../../utils/NumberUtil'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../../referencials/materiels/constants/MaterielConstants'
import LoadingCard from '../../components/cards/LoadingCard'
import { accordionSeparatorGrey, grey, textColor } from '../../components/styled/theme'
import { Add, Edit, HighlightOff, KeyboardArrowLeft } from '@mui/icons-material'
import ModalUsageDesktop from './modals/ModalUsageDesktop'
import { LightCard } from '../../components/styled/grid'
import Table from '../../components/Table'
import { DECLA_POINTS_ADVANCEMENT, POINT_ADVANCEMENT_DECLARATION, POINT_STATUS_DECLARATION, USAGES_CATEGORY } from '../../agri/constants/AgriConstants'
import useSandreList from '../../../../utils/customHooks/useSandreList'
import { DialogContentMUIDesktop, DialogContentTextMUI, DialogMUI, DialogTitleMUIv2 } from '../../components/styled/dialog'
import useLocalStorage from '../../../../utils/customHooks/useLocalStorage'

const PointUsagesPrevi = ({ match }) => {
    const [usageToUpdate, setUsageToUpdate] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [point, setPoint] = useState({})
    const [declarationLoaded, setDeclarationLoaded] = useState(false)
    const [pointToBeRemoved, setPointToBeRemoved] = useState(false)
    const [updateUsage, setUpdateUsage] = useState(false)
    const [openDelModal, setOpenDelModal] = useState(false)
    const [openModalUsage, setOpenModalUsage] = useState(false)
    const [openModalValidationWithoutUsage, setOpenModalValidationWithoutUsage] = useState(false)

    const {
        cultures,
        culturesFamilies,
        usagesCategories,
        declaration,
        variousMateriels,
        exploitation,
        modesIrrigations,
        codesSandre,
        survey,
    } = useSelector(store => ({
        cultures: store.ReferencialReducer.cultures,
        culturesFamilies: store.ReferencialReducer.culturesFamilies,
        usagesCategories: store.ReferencialReducer.usagesCategories,
        declaration: store.AgriReducer.declaration,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        exploitation: store.AgriReducer.exploitation,
        modesIrrigations: store.AgriReducer.modesIrrigations,
        codesSandre: store.ReferencialReducer.codesSandre,
        survey: store.AgriReducer.survey,
    }), shallowEqual)

    const [pointsAdvancement, setPointsAdvancement] = useLocalStorage(DECLA_POINTS_ADVANCEMENT)
    const idInstallation = Number(match?.params?.id)
    const usages = useMemo(() => (declaration ? declaration?.link_declarationInstallation?.find((p) => p.idInstallation === idInstallation) : new DtoSamplingPointDecla({}))?.link_usages, [declaration])
    const [pointWillBeUsed, setPointWillBeUsed] = useState(!!usages?.length)

    const dispatch = useDispatch()

    const theme = useTheme()

    const setDeclaration = (exploit = exploitation) => {
        dispatch(AgriAction.fetchDeclarationByExploitationId(exploit.idExploitation)).then((res) => {
            if (res.idDeclaration) {
                dispatch(AgriAction.fetchSurvey(res.idSurvey)).then(() => {
                    dispatch(InstallationsAction.fetchInstallation(idInstallation)).then(() => {
                        const newPoint = res ? res.link_declarationInstallation.find((p) => p.idInstallation === idInstallation) : new DtoSamplingPointDecla({})
                        setPoint(newPoint)
                        if (newPoint?.link_usages?.length) {
                            setPointWillBeUsed(true)
                        }
                        setDeclarationLoaded(true)
                    })
                })
            }
        })
    }

    useEffect(() => {
        dispatch(CmsAction.fetchCmsByCategory(5))
        dispatch(ReferencialAction.fetchCulturesFamilies()).then(() => {
            dispatch(ReferencialAction.fetchCultures()).then(() => {
                dispatch(AgriAction.fetchModesIrrigations()).then(() => {
                    dispatch(ReferencialAction.fetchUsages()).then(() => {
                        setDataLoaded(true)
                    })
                })
            })
        })
        if (!exploitation.idExploitation) {
            dispatch(AgriAction.fetchExploitation()).then((res) => {
                setDeclaration(res)
            })
        } else {
            setDeclaration()
        }
        window.scrollTo(0, 0)
        return () => dispatch(InstallationsAction.resetInstallation())
    }, [])

    const getMateriels = () => {
        const link_equipments = declaration.link_declarationInstallation.find((p) => p.idInstallation === idInstallation).link_equipments || []
        const newMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode !== 'd')
        const deletedMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'd')
        const deletedCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode === 'd')
        const exploitationPumps = declaration.link_exploitationMateriel.filter((m) => m.siteType === SITU_POINT_PUMP &&
            m.siteCode === idInstallation &&
            !deletedMats.find((mLink) => m.idVarious === mLink.idMat)
        ) || []
        const mergedPumpsIds = uniqBy([
            ...exploitationPumps.map((m) => m.idVarious),
            ...newMats.map((m) => m.idMat)
        ], obj => obj)
        const exploitationCountersId = compact(mergedPumpsIds.flatMap((pumpId) => {
            const pumpSitu = declaration.link_exploitationMateriel.filter((situ) => situ.siteType === SITU_PUMP_COUNTER &&
                situ.siteCode === pumpId &&
                !deletedCounters.find((mLink) => pumpId === mLink.idMat)
            )
            if (pumpSitu) {
                return pumpSitu.map((s) => s.idVarious)
            }
            return null
        }))
        const newCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode !== 'd' && mergedPumpsIds.includes(mLink.idMat))
        const mergedCountersIds = uniqBy([
            ...exploitationCountersId,
            ...newCounters.map((m) => m.idMatAttachment)
        ], obj => obj)
        const materiels = variousMateriels.filter((m) => [...mergedPumpsIds, ...mergedCountersIds].includes(m.id) &&
            (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode)
        )
        return { pumpsIds: mergedPumpsIds, countersIds: mergedCountersIds, materiels }
    }

    const savePoint = () => {
        new Promise(resolve => {
            resolve(setPointsAdvancement([
                ...pointsAdvancement.filter((a) =>
                    a.idDeclaration === declaration.idDeclaration && a.idPoint !== idInstallation
                ),
                {
                    idDeclaration: declaration.idDeclaration,
                    idPoint: idInstallation,
                    status: POINT_ADVANCEMENT_DECLARATION.VALIDATED,
                }
            ]))
        }).then(() => {
            if (!pointWillBeUsed) {
                if (pointToBeRemoved) {
                    dispatch(AgriAction.updateDeclaration({
                        ...declaration,
                        link_declarationInstallation: [
                            ...declaration.link_declarationInstallation.filter(i => i.idInstallation != idInstallation),
                            {
                                ...declaration.link_declarationInstallation.find(i => i.idInstallation === idInstallation),
                                stateCode: POINT_STATUS_DECLARATION.REMOVED
                            }
                        ]
                    }))
                } else {
                    dispatch(AgriAction.updateDeclaration({
                        ...declaration,
                        link_declarationInstallation: [
                            ...declaration.link_declarationInstallation.filter(i => i.idInstallation != idInstallation),
                        ]
                    }))
                }
            } else {
                dispatch(AgriAction.updateDeclaration({
                    ...declaration,
                    link_declarationInstallation: [
                        ...declaration.link_declarationInstallation.filter(i => i.idInstallation != idInstallation),
                        {
                            ...declaration.link_declarationInstallation.find(i => i.idInstallation === idInstallation),
                            stateCode: POINT_STATUS_DECLARATION.USED
                        }
                    ]
                }))
            }
            dispatch(push('/declaration'))
        })
    }

    const onSaveUsage = (newUsages) => {
        const updateDeclaration = {
            ...declaration,
            link_declarationInstallation: [
                ...declaration.link_declarationInstallation.filter((p) =>
                    p.idInstallation !== point.idInstallation
                ),
                { ...point, link_usages: newUsages }
            ],
        }
        setDeclarationLoaded(false)
        setOpenModalUsage(false)
        dispatch(AgriAction.updateDeclaration(updateDeclaration, () => {
            setDeclaration()
        }))
    }

    const getUsageType = (u) => {
        const usage = usagesCategories.find(cat => cat.idUsage === u.idUsage)
        if (usage) {
            return (
                <Grid container>
                    <Grid item xs={12}>{usage?.category === USAGES_CATEGORY.AGRI ? i18n.agri : i18n.nonAgri}</Grid>
                    <Grid item xs={12}>{usage?.description}</Grid>
                </Grid>
            )
        }
        return undefined
    }

    const getCulture = (u) => {
        const culture = cultures.find(cult => cult.id === u.idCulture)
        const cultureCat = culturesFamilies.find(family => family.id === culture?.idFamille)
        if (culture) {
            return (
                <>
                    <Grid container item>{cultureCat?.name}</Grid>
                    <Grid container item>{culture?.name}</Grid>
                </>
            )
        }
        return undefined
    }

    const getTypeIrrigation = (u) => {
        const mode = modesIrrigations.find(m => m.id === u?.irrigationMode)
        const type = codesSandre.find(c => c.field === 'IRRIGATION.CATEGORIES' && c.code === u?.irrigationType)
        if (mode) {
            return (
                <Grid container>
                    <Grid item xs={12}>{type?.name}</Grid>
                    <Grid item xs={12}>{mode?.name}</Grid>
                </Grid>
            )
        }
        return undefined
    }

    const typesSols = useSandreList('USAGES.TYPES_SOLS')
    const profondeurSols = useSandreList('USAGES.PROFONDEUR_SOLS')

    const getIrrigatedGround = (u) => {
        if (u?.area) {
            if (u?.groundDepth) {
                return (
                    <>
                        <Grid container item>{i18n.surface} : {u?.area}</Grid>
                        <Grid container item>{i18n.profondeurSol} : {profondeurSols.find(c => c.code === u?.groundDepth)?.name ?? '-'}</Grid>
                        <Grid container item>{i18n.typeSol} : {typesSols.find(c => c.code === u?.groundType)?.name ?? '-'}</Grid>
                        <Grid container item>{i18n.nbRotations} : {u?.nbRotation}</Grid>
                    </>
                )
            }
            return (
                <Grid container>
                    <Grid container item>{i18n.surface} : {u?.area}</Grid>
                    <Grid container item>{i18n.nbRotations} : {u?.nbRotation}</Grid>
                </Grid>
            )
        }
        return undefined
    }

    const getEstimatedVolume = (usage) => (
        { requestedYearVolume: usage?.requestedYearVolume ?? 0, lowWaterVolume: usage?.lowWaterVolume ?? 0 }
    )

    const tableRows = useMemo(() => (
        usages?.map((u, i) => {
            return (
                {
                    [i18n.usageNum]: { value: `${i18n.usage} #${i + 1}`, textWrap: 'nowrap' },
                    [i18n.usageType]: { value: getUsageType(u) },
                    [i18n.culture]: { value: getCulture(u), textWrap: 'nowrap' },
                    [i18n.typeIrrigation]: { value: getTypeIrrigation(u), },
                    [i18n.irrigatedGround]: { value: getIrrigatedGround(u) },
                    [i18n.requestedVolume]: { value: (
                        getEstimatedVolume(u).lowWaterVolume ?
                            <Grid container>
                                <Grid item container>{i18n.lowWaterVolumeLabel}</Grid>
                                <Grid item container sx={{ color: grey }}>{getEstimatedVolume(u).lowWaterVolume}</Grid>
                                <Grid item container>{i18n.horsEtiage}</Grid>
                                <Grid item container sx={{ color: grey }}>{getEstimatedVolume(u).requestedYearVolume - getEstimatedVolume(u).lowWaterVolume}</Grid>
                            </Grid>
                            :
                            <Grid container>
                                <Grid item container>{i18n.yearVolume}</Grid>
                                <Grid item container sx={{ color: grey }}>{getEstimatedVolume(u).requestedYearVolume}</Grid>
                            </Grid>
                    ) },
                    [i18n.edit]: { value: (
                        <Grid container justifyContent='center'>
                            <TextButton
                                noFullWidth
                                sx={{ width: 'auto', margin: 0, paddingRight: theme.spacing(0.5) }}
                                onClick={() => {
                                    setUsageToUpdate(u)
                                    setUpdateUsage(true)
                                    setOpenModalUsage(true)
                                }}
                                startIcon={<Edit />}
                            >
                                {i18n.edit}
                            </TextButton>
                            <TextButton
                                noFullWidth
                                sx={{ width: 'auto', margin: 0 }}
                                onClick={() => {
                                    setUsageToUpdate(u)
                                    setOpenDelModal(true)
                                }}
                                startIcon={<HighlightOff />}
                            >
                                {i18n.delete}
                            </TextButton>
                        </Grid>
                    ) },
                }
            )
        })
    ), [usages])

    if (!declarationLoaded || !dataLoaded) {
        return <LoadingCard />
    }

    const { countersIds } = getMateriels()
    return (
        <Grid
            container
            item
            xs={12}
            alignContent='start'
            justifyContent='space-between'
            sx={{
                maxHeight: '100%',
                overflowY: 'hidden',
            }}
        >
            <Grid container item alignItems='center' justifyContent='space-between' color={textColor}>
                <Grid item container>
                    <Grid item
                        sx={{ paddingRight: theme.spacing(3) }}
                        className='clickable'
                        onClick={() => dispatch(push('/declaration'))}
                    >
                        <KeyboardArrowLeft sx={{ fontSize: '30px' }}/>
                    </Grid>
                    <Grid item sx={{ fontSize: '22px', lineHeight: '28px' }} >
                        {i18n.backToPointsList}
                    </Grid>
                </Grid>
            </Grid>
            <LightCard container alignItems='center' sx={{ height: 'calc(100% - 3.5rem)', marginTop: theme.spacing(3) }} >
                <Grid container item xs={12} alignContent='start' sx={{ height: '100%', overflowY: 'auto' }}>
                    <Grid
                        item
                        sx={{
                            color: textColor,
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            letterSpacing: '0.15px'
                        }}
                    >
                        {i18n.willUsePointNextYear}
                    </Grid>
                    <Grid
                        item
                        container
                        direction='row'
                        sx={{
                            borderBottom: `1px solid ${accordionSeparatorGrey}`,
                            paddingTop: theme.spacing(2)
                        }}
                    >
                        <RadioGroup
                            row
                            name='pointUse'
                            onChange={(e) => setPointWillBeUsed(Boolean(Number(e.target.value)))}
                            sx={{ color: textColor }}
                        >
                            <FormControlLabel
                                checked={pointWillBeUsed}
                                value={1}
                                control={
                                    <Radio
                                        sx={{
                                            color: textColor,
                                            '&.Mui-checked': {
                                                color: textColor,
                                            },
                                        }}
                                    />
                                }
                                label={i18n.yes}
                            />
                            <FormControlLabel
                                checked={!pointWillBeUsed}
                                value={0}
                                control={
                                    <Radio
                                        sx={{
                                            color: textColor,
                                            '&.Mui-checked': {
                                                color: textColor,
                                            },
                                        }}
                                    />
                                }
                                label={i18n.no}
                            />
                        </RadioGroup>
                    </Grid>
                    {pointWillBeUsed ?
                        <Grid container item sx={{ paddingTop: theme.spacing(2) }}>
                            <Grid
                                item
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    letterSpacing: '0.15px',
                                    color: textColor,
                                }}
                            >
                                {i18n.prevUsagesFor} {survey.year}
                            </Grid>
                            <Grid
                                container
                                item
                                sx={{
                                    backgroundColor: 'white',
                                    borderRadius: '12px',
                                    marginTop: theme.spacing(2),
                                }}
                            >
                                <Table
                                    transparent
                                    rows={tableRows}
                                    headers={[
                                        [
                                            { colSpan: 1, group: 0 },
                                            { value: i18n.usageType, colSpan: 1, group: 1 },
                                            { value: i18n.culture, colSpan: 1, group: 2 },
                                            { value: i18n.typeIrrigation, colSpan: 1, group: 3 },
                                            { value: i18n.irrigatedGround, colSpan: 1, group: 4 },
                                            { value: i18n.requestedVolume, colSpan: 1, group: 5 },
                                            { colSpan: 1, group: 6 },
                                        ]
                                    ]}
                                    headersLabel={[i18n.usageNum, i18n.usageType, i18n.culture, i18n.typeIrrigation, i18n.irrigatedGround, i18n.requestedVolume, i18n.edit]}
                                />
                            </Grid>
                        </Grid>
                        :
                        <>
                            <Grid
                                item
                                sx={{
                                    color: textColor,
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    letterSpacing: '0.15px',
                                    paddingTop: theme.spacing(2)
                                }}
                            >
                                {i18n.wishToClosePoint}
                            </Grid>
                            <Grid
                                item
                                container
                                direction='row'
                            >
                                <RadioGroup
                                    row
                                    name='closePoint'
                                    onChange={(e) => {
                                        setPointToBeRemoved(Boolean(Number(e.target.value)))
                                    }}
                                    sx={{ color: textColor }}
                                >
                                    <FormControlLabel
                                        checked={pointToBeRemoved}
                                        value={1}
                                        control={
                                            <Radio
                                                sx={{
                                                    color: textColor,
                                                    '&.Mui-checked': {
                                                        color: textColor,
                                                    },
                                                }}
                                            />
                                        }
                                        label={i18n.yes}
                                    />
                                    <FormControlLabel
                                        checked={!pointToBeRemoved}
                                        value={0}
                                        control={
                                            <Radio
                                                sx={{
                                                    color: textColor,
                                                    '&.Mui-checked': {
                                                        color: textColor,
                                                    },
                                                }}
                                            />
                                        }
                                        label={i18n.no}
                                    />
                                </RadioGroup>
                            </Grid>
                        </>
                    }
                    {pointWillBeUsed &&
                        <TextButton
                            startIcon={<Add />}
                            onClick={() => {
                                setUsageToUpdate({})
                                setUpdateUsage(false)
                                setOpenModalUsage(true)
                            }}
                            sx={{
                                marginTop: theme.spacing(2)
                            }}
                        >
                            {i18n.add}
                        </TextButton>
                    }
                    <Grid item container justifyContent='flex-end'>
                        <MainButton noFullWidth sx={{ width: 'auto', marginRight: theme.spacing(2) }} reverse onClick={() => window.history.back()}>{i18n.previous}</MainButton>
                        <MainButton
                            noFullWidth
                            sx={{ width: 'auto' }}
                            onClick={() => {
                                if (!usages.length && pointWillBeUsed) {
                                    setOpenModalValidationWithoutUsage(true)
                                } else {
                                    savePoint()
                                }
                            }}
                        >
                            {i18n.nextPoint}
                        </MainButton>
                    </Grid>
                </Grid>
            </LightCard>
            {openModalUsage && (
                <ModalUsageDesktop
                    open={openModalUsage}
                    point={point}
                    update={updateUsage}
                    usage={usageToUpdate}
                    usages={usages}
                    onSave={onSaveUsage}
                    onDelete={onSaveUsage}
                    onClose={() => setOpenModalUsage(false)}
                    countersId={countersIds}
                />
            )}
            {openDelModal &&
                <DialogMUI
                    open={openDelModal}
                    keepMounted
                    onClose={() => {
                        setOpenDelModal(false)
                    }}
                >
                    <DialogTitleMUIv2 onClick={() => setOpenDelModal(false)}>
                        {i18n.useRemoval}
                    </DialogTitleMUIv2>
                    <DialogContentMUIDesktop>
                        <DialogContentTextMUI>
                            {i18n.wantToRemoveUse}{usageToUpdate?.description}
                        </DialogContentTextMUI>
                        <Grid item container justifyContent='center'>
                            <MainButton
                                reverse
                                sx={{ width: 'auto', marginRight: theme.spacing(1) }}
                                onClick={() => {
                                    setOpenDelModal(false)
                                    setUsageToUpdate({})
                                }}
                            >
                                {i18n.cancel}
                            </MainButton>
                            <MainButton
                                sx={{ width: 'auto' }}
                                onClick={() => {
                                    onSaveUsage(point.link_usages.filter((u) => u.idInstallation === Number(match.params.id) && u.idProvisionalUsage !== usageToUpdate.idProvisionalUsage))
                                    setOpenDelModal(false)
                                    setUsageToUpdate({})
                                }}
                            >
                                {i18n.delete}
                            </MainButton>
                        </Grid>
                    </DialogContentMUIDesktop>
                </DialogMUI>
            }
            {openModalValidationWithoutUsage && pointWillBeUsed &&
                <DialogMUI
                    open={openModalValidationWithoutUsage}
                    keepMounted
                    onClose={() => {
                        setOpenModalValidationWithoutUsage(false)
                    }}
                >
                    <DialogTitleMUIv2 onClick={() => setOpenModalValidationWithoutUsage(false)}>
                        {i18n.noForecastUses}
                    </DialogTitleMUIv2>
                    <DialogContentMUIDesktop>
                        <DialogContentTextMUI>
                            {i18n.askFor0m3}
                        </DialogContentTextMUI>
                        <Grid item container justifyContent='center'>
                            <MainButton
                                reverse
                                sx={{ width: 'auto', paddingRight: theme.spacing(1) }}
                                onClick={() => {
                                    setOpenModalValidationWithoutUsage(false)
                                }}
                            >
                                {i18n.enterUse}
                            </MainButton>
                            <MainButton
                                sx={{ width: 'auto' }}
                                onClick={() => {
                                    savePoint()
                                    setOpenModalValidationWithoutUsage(false)
                                }}
                            >
                                {i18n.validAsk}
                            </MainButton>
                        </Grid>
                    </DialogContentMUIDesktop>
                </DialogMUI>
            }
        </Grid>
    )
}

PointUsagesPrevi.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
}

export default PointUsagesPrevi
