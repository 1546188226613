import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Icon, Grid, ClickAwayListener } from '@mui/material'
import { withStyles } from '@mui/styles'
import { MainButton } from '../styled/buttons'
import { HelpTooltip } from '../styled/texts'
import { mainColor } from '../styled/theme'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../styled/dialog'
import { ReactComponent as CloseLogo } from '../../../../ressources/static/svg/Fermer.svg'
import Accordeon from '../Accordeon'
import StyledCard from './StyledCard'

const styles = (theme) => ({
    root: {
        borderRadius: 15,
        border: '1px solid grey',
        // boxShadow: '0px 0px 5px 5px rgb(0 0 0 / 30%)',
    },
    title: {
        fontWeight: 'bold',
        color: 'black',
    },
    paper: {
        backgroundColor: theme.palette.background,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        margin: '10px auto',
        bottom: 'inherit',
        height: '90vh',
        width: '90vw',
        overflowY: 'auto',
    },
    headAccordion: {
        backgroundColor: 'lightgray',
        width: 'auto',
        padding: '8px',
        display: 'flex',
        justifyContent: 'left',
        fontWeight: 'bold',
        border: '1px solid grey',
        height: 30,
    },
    detailsAccordion: {
        padding: '0',
    }
})

class EditableCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openTooltip: false,
        }
    }

    onClickAccordeonIcon = () => {
        this.props.toggleEditMode()
    }

    toggleTooltip = () => {
        const { openTooltip } = this.state
        this.setState({ openTooltip: !openTooltip })
    }

    render() {
        const { title, editTitle, editMode, hideIcon, children, accordeon, expanded, style, colorTitle, styleTitle, color,
            otherIcon, icon, styleContainer, noInnerPadding, className, classes, tooltipIcon, tooltipContent, newStyle } = this.props
        const { openTooltip } = this.state
        if (editMode) {
            return (
                <DialogMUI open>
                    <DialogTitleMUI>
                        <Grid container justifyContent='space-between' alignItems='center'>
                            <b style={{ padding: 10, fontSize: 21 }}>{editTitle || title}</b>
                            <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={this.props.onCancel} />
                        </Grid>
                    </DialogTitleMUI>
                    <DialogContentMUI>
                        {children.length
                            ? children.map((child) => React.cloneElement(child, {}))
                            : React.cloneElement(children, {})}
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <MainButton onClick={this.props.onSave}>
                            {i18n.validate}
                        </MainButton>
                    </DialogActionsMUI>
                </DialogMUI>
            )
        }
        if (accordeon) {
            return (
                <Accordeon
                    className={className}
                    noShadow
                    expanded={expanded}
                    title={title}
                    showIcon={!hideIcon}
                    icon={icon}
                    onClickIcon={this.onClickAccordeonIcon}
                >
                    {children.length
                        ? children.map((child) => React.cloneElement(child, {}))
                        : React.cloneElement(children, {})}
                </Accordeon>
            )
        }
        if (newStyle) {
            return (
                <StyledCard
                    title={title}
                    editMode={editMode}
                    showIcon={!hideIcon}
                    icon={icon}
                    color={color}
                    otherIcon={otherIcon}
                    toggleEditMode={this.props.toggleEditMode}
                    onClickOtherIcon={this.props.onClickOtherIcon}
                    styleContainer={{ padding: '10 10 0 10', ...styleContainer }}
                    styleContent={{ padding: '0 10' }}
                    styleTitle={styleTitle}
                >
                    {children.length
                        ? children.map((child) => React.cloneElement(child, {}))
                        : React.cloneElement(children, {})}
                </StyledCard>
            )
        }
        return (
            <div className={className} style={{ padding: '5 10', ...styleContainer }}>
                <Card className={classes.root} style={style}>
                    {title && (
                        <Grid
                            container
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            style={{
                                backgroundColor: `${colorTitle || 'white'}`,
                                borderBottom: '1px solid grey',
                                padding: '8px',
                            }}
                        >

                            <Grid item>
                                <Typography className={classes.title} color='textSecondary' style={styleTitle}>
                                    <span style={{ height: '100%', width: 4, backgroundColor: mainColor, marginRight: 4 }}>&nbsp;</span>
                                    {title}
                                </Typography>
                            </Grid>
                            {!hideIcon && (
                                <Grid item className='clickable' onClick={this.props.toggleEditMode}>
                                    {!editMode && <Icon>{icon || 'edit'}</Icon>}
                                </Grid>
                            )}
                            {otherIcon && (
                                <Grid item className='clickable' onClick={this.props.onClickOtherIcon}>
                                    {<Icon>{otherIcon}</Icon>}
                                </Grid>
                            )}
                            {tooltipIcon && (
                                <Grid item className='clickable' onClick={this.toggleTooltip}>
                                    <ClickAwayListener onClickAway={() => this.setState({ openTooltip: false })}>
                                        <HelpTooltip
                                            title={tooltipContent}
                                            open={openTooltip}
                                            arrow
                                            disableHoverListener
                                            placement='top'
                                        >
                                            {<Icon>{tooltipIcon}</Icon>}
                                        </HelpTooltip>
                                    </ClickAwayListener>
                                </Grid>
                            )}
                        </Grid>
                    )}
                    <CardContent style={{ padding: noInnerPadding ? 0 : '4px 16px' }}>
                        {children.length
                            ? children.map((child) => React.cloneElement(child, {}))
                            : React.cloneElement(children, {})}
                    </CardContent>
                </Card>
            </div>
        )
    }
}

EditableCard.propTypes = {
    title: PropTypes.string.isRequired,
    editTitle: PropTypes.string,
    editMode: PropTypes.bool,
    toggleEditMode: PropTypes.func,
    otherIcon: PropTypes.string,
    icon: PropTypes.string,
    onClickOtherIcon: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    hideIcon: PropTypes.bool,
    noInnerPadding: PropTypes.bool,
    accordeon: PropTypes.bool,
    expanded: PropTypes.bool,
    newStyle: PropTypes.bool,
    children: PropTypes.element,
    colorTitle: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string,
    tooltipIcon: PropTypes.string,
    tooltipContent: PropTypes.string,
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    style: PropTypes.instanceOf(PropTypes.shape({})),
    styleContainer: PropTypes.instanceOf(PropTypes.shape({})),
    styleTitle: PropTypes.instanceOf(PropTypes.shape({})),
}

EditableCard.defaultProps = {
    editable: false,
    accordeon: false,
}

export default withStyles(styles)(EditableCard)
