import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import DtoManagementUnit from '../../referencials/dto/DtoManagementUnit'
import DtoSandreCode from '../../referencials/dto/DtoSandreCode'
import { Grid, useTheme } from '@mui/material'
import { LightCard } from '../styled/grid'
import LoadingCard from './LoadingCard'
import { veryLightColor } from '../styled/theme'

const AssociatedResourceCardDesktop = ({ title, data, xs=12 }) => {
    const {
        codesSandre,
        managementUnits,
    } = useSelector(store => ({
        codesSandre: store.ReferencialReducer.codesSandre,
        managementUnits: store.ReferencialReducer.managementUnits,
    }), shallowEqual)

    const zre = codesSandre.find((c) => c.field === 'AGRI_PREL_ZRE' && c.code === data.codeZRE) || {}
    const ug = managementUnits.find((u) => u.managementCode === data.managementCode) || {}
    // const sug = managementUnits.find((u) => u.managementCode === data.subManagementCode) || {}

    const getSampleType = () => {
        const codeFind = codesSandre.find((c) => c.field === 'PREL_AGRI.TYPE_PRELEVEMENT' && c.code === data.sampleType)
        return codeFind?.name
    }

    const theme = useTheme()

    if (!codesSandre?.length && !managementUnits?.length) return <LoadingCard xs={2.8} sx={{ backgroundColor: veryLightColor, padding: theme.spacing(4) }}/>

    return (
        <LightCard
            item
            container
            xs={xs}
            direction='column'
            sx={{
                padding: theme.spacing(4)
            }}
        >
            <Grid item fontSize={22} sx={{ fontWeight: 'regular' }}>{title ||i18n.associatedResource}</Grid>
            <Grid container direction='column' sx={{ paddingTop: theme.spacing(3) }}>
                <Grid item container justifyContent='space-between'>
                    <Grid item>{i18n.zre}</Grid>
                    <Grid item sx={{ fontWeight: 'medium' }}>{zre?.name || '-'}</Grid>
                </Grid>
                {/* <Grid item container justifyContent='space-between'>
                    <Grid item>{i18n.miocene}</Grid>
                    <Grid item sx={{ fontWeight: 'medium' }}>{data.miocenezpr ? i18n.yes : i18n.no}</Grid>
                </Grid> */}
                <Grid item container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                    <Grid item>{i18n.ugId}</Grid>
                    <Grid item sx={{ fontWeight: 'medium' }}>{ug?.id || '-'}</Grid>
                </Grid>
                <Grid item container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                    <Grid item>{i18n.ug}</Grid>
                    <Grid item sx={{ fontWeight: 'medium' }}>{ug?.name || '-'}</Grid>
                </Grid>
                {/* <Grid item container justifyContent='space-between'>
                    <Grid item>{`S${i18n.ug} - ID `}</Grid>
                    <Grid item sx={{ fontWeight: 'medium' }}>{sug?.id || '-'}</Grid>
                </Grid>
                <Grid item container justifyContent='space-between'>
                    <Grid item>{`S${i18n.ug}`}</Grid>
                    <Grid item sx={{ fontWeight: 'medium' }}>{sug?.name || '-'}</Grid>
                </Grid> */}
                <Grid item container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                    <Grid item>{i18n.sampleType}</Grid>
                    <Grid item sx={{ fontWeight: 'medium' }}>{getSampleType() || '-'}</Grid>
                </Grid>
            </Grid>
        </LightCard>
    )
}

AssociatedResourceCardDesktop.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.instanceOf(PropTypes.shape({})),
    style: PropTypes.instanceOf(PropTypes.shape({})),
    styleContainer: PropTypes.instanceOf(PropTypes.shape({})),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    xs: PropTypes.number
}

export default AssociatedResourceCardDesktop
