/* eslint-disable no-console */
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AuthRoute from '../src/pages/online/AuthRoute'
import AppStore, { history } from './store/AppStore'
import fr from './ressources/i18n/properties_fr.json'
// import en from './ressources/i18n/properties_en.json'
import Login from './pages/offline/Login'
import AppDesktop from './pages/online/components/AppDesktop'
import MessagesApp from './pages/online/contact/MessagesApp'
import { darkTextColor, mainBlue, secondaryColor, veryLightGrey } from './pages/online/components/styled/theme'
import Password from './pages/offline/Password'
import Actualities from './pages/Actualities'
import MapPoints from './pages/online/points/MapPoints'
import Admin from './pages/offline/Admin'
import PointRestriction from './pages/online/consommation/PointRestriction'
import IndexMaterielsApp from './pages/online/consommation/IndexMaterielsApp'
import IndexEntriesApp from './pages/online/consommation/IndexEntriesApp'
import EventsMaterielsApp from './pages/online/consommation/EventsMaterielsApp'
import EventsEntriesApp from './pages/online/consommation/EventsEntriesApp'
import PointEventsApp from './pages/online/consommation/PointEventsApp'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import SieauTooltip from './pages/online/components/styled/SieauTooltip'
import HomeDesktop from './pages/online/home/HomeDesktop'
import PointsListAppDesktop from './pages/online/consommation/PointsListAppDesktop'
import MyExploitationDesktop from './pages/online/exploitation/MyExploitationDesktop'
import PointDetailsExploitDesktop from './pages/online/exploitation/PointDetailsExploitDesktop'
import MyAccountDesktop from './pages/online/account/MyAccountDesktop'
import AboutDesktop from './pages/AboutDesktop'
import PointDashboardDesktop from './pages/online/consommation/PointDashboardDesktop'
import HomeSurvey from './pages/online/enquete/HomeSurvey'
import Declaration from './pages/online/enquete/Declaration'
import PointDetailsDeclaDesktop from './pages/online/enquete/components/PointDetailsDeclaDesktop'
import PointUsagesPrevi from './pages/online/enquete/components/PointUsagesPrevi'
import TutorielDesktop from './pages/TutorielDesktop'
import IndexMaterielsAppDecla from './pages/online/enquete/components/IndexMaterielsAppDecla'
import MyDocumentsDesktop from './pages/online/documents/MyDocumentsDesktop'

i18n.addLang(fr, ['fr', 'FR'])
// i18n.addLang(en, ['en', 'EN'])
i18n.addLang(fr, 'default')
i18n.init()

const theme = createTheme({
    typography: {
        button: {
            fontSize: '1rem',
            textTransform: 'none',
        },
    },
    palette: {
        primary: {
            main: mainBlue,
        },
        secondary: {
            main: secondaryColor,
        },
        action: {
            disabledBackground: veryLightGrey,
            disabled: 'white',
        },
    },
    components: {
        MuiTable: {
            defaultProps: {
                'aria-label': 'table',
                border: 0,
                backgroundColor: 'transparent'
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom: `solid 1px ${veryLightGrey}`,
                    '&:last-child td, &:last-child th': { border: 0 },
                    backgroundColor: 'transparent'
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    lineHeight: '20px',
                    letterSpacing: '0.25px',
                    backgroundColor: 'transparent'
                },
                head: {
                    fontWeight: 'bold',
                    height: '50px',
                    textWrap: 'wrap',
                    borderBottom: 0,
                    textAlign: 'center',
                    padding: '0',
                    '&:last-child': { paddingRight: '0' },
                },
                body: {
                    padding: '16px 8px',
                    backgroundColor: 'transparent',
                    color: darkTextColor,
                    textAlign: 'start',
                },
            },
            variants: [
                {
                    props: { variant: 'bodyIcon' },
                    style: {
                        padding: '1',
                        width: '32px',
                        cursor: 'pointer',
                    },
                },
                {
                    props: { variant: 'headerIcon' },
                    style: {
                        borderColor: 'black',
                        cursor: 'pointer',
                        paddingRight: '16px',
                    },
                },
            ],
        },
    }

})

function win(res) {
    console.log(`success  ${JSON.stringify(res)}`)
}

function fail(res) {
    console.log(`fail ${JSON.stringify(res)}`)
}

function readTrackingPermission() {
    if (this.platform.is('cordova') && this.platform.is('ios')) {
        if (window.cordova) {
            window.cordova.exec(win, fail, 'idfa', 'getInfo', [])
        }
    }
}

function askTrackingPermission() {
    if (this.platform.is('cordova') && this.platform.is('ios')) {
        if (window.cordova) {
            console.log('trying to request permission ')
            window.cordova.exec(win, fail, 'idfa', 'requestPermission', [])
        }
    }
}

function onDeviceReady() {
    window.open = window.cordova.InAppBrowser.open
}
document.addEventListener('deviceready', onDeviceReady, false)
document.addEventListener('deviceready', readTrackingPermission, false)
document.addEventListener('deviceready', askTrackingPermission, false)

render(
    <ThemeProvider theme={theme}>
        <Provider store={AppStore}>
            <ConnectedRouter history={history}>
                <SieauTooltip />
                <>
                    <Switch>
                        <Route exact path='/login' component={(props) => <Login {...props} />} />
                        <Route exact path='/reset' component={(props) => <Password {...props} />} />
                        <Route exact path='/admin' component={(props) => <Admin {...props} />} />
                        <Route path='/' component={(props) => <AuthRoute {...props} />} >
                            <AppDesktop>
                                <Route exact path='/home' component={(props) => <HomeDesktop {...props} />} />

                                <Route exact path='/message' component={(props) => <MessagesApp {...props} />} />
                                <Route exact path='/actualities' component={(props) => <Actualities {...props} />} />
                                <Route exact path='/tutoriel' component={(props) => <TutorielDesktop {...props} />} />

                                <Route exact path='/documents' component={(props) => <MyDocumentsDesktop {...props} />} />

                                <Route exact path='/dossier' component={(props) => <MyExploitationDesktop {...props} />} />
                                <Route exact path='/dossier/point/:id' component={(props) => <PointDetailsExploitDesktop {...props} />} />

                                <Route exact path='/installations' component={(props) => <PointsListAppDesktop {...props} />} />
                                <Route exact path='/pointsMap' component={(props) => <MapPoints {...props} />} />
                                <Route exact path='/installations/point/:id' component={(props) => <PointDashboardDesktop {...props} />} />
                                <Route exact path='/installations/point/:id/restriction' component={(props) => <PointRestriction {...props} />} />

                                <Route exact path='/installations/point/:id/events' component={(props) => <PointEventsApp {...props} />} />
                                <Route exact path='/installations/point/:id/events/materiels' component={(props) => <EventsMaterielsApp {...props} />} />
                                <Route exact path='/installations/point/:id/events/materiels/:idMat/entries' component={(props) => <EventsEntriesApp {...props} />} />

                                <Route exact path='/index/:id/materiels/:idMat/entries' component={(props) => <IndexEntriesApp {...props} />} />
                                <Route exact path='/index/:id/materiels' component={(props) => <IndexMaterielsApp {...props} />} />

                                <Route exact path='/myaccount/preferences' component={(props) => <MyAccountDesktop {...props} />} />
                                <Route exact path='/myaccount/about' component={(props) => <AboutDesktop {...props} />} />

                                <Route exact path='/declaration/start' component={(props) => <HomeSurvey {...props} />} />
                                <Route exact path='/declaration' component={(props) => <Declaration {...props} />} />
                                <Route exact path='/declaration/point/:id' component={(props) => <PointDetailsDeclaDesktop {...props} />} />
                                <Route exact path='/declaration/point/:id/usages' component={(props) => <PointUsagesPrevi {...props} />} />
                                <Route exact path='/declaration/index/:id/materiels' component={(props) => <IndexMaterielsAppDecla {...props} />} />
                            </AppDesktop>
                        </Route>
                    </Switch>
                </>
            </ConnectedRouter>
        </Provider>
    </ThemeProvider>,
    document.getElementById('app'),
)