import {
    RECEIVE_CONTACT,
    RECEIVE_CHAT,
    RECEIVE_MESSAGE,
    RECEIVE_MESSAGES_OF,
    RECEIVE_CONTACTS,
} from '../constants/ContactConstants'
import DtoContact from '../../referencials/dto/DtoContact'
import { FULL_RESET } from '../../../offline/constants/HomeConstants'
export const store = {
    contact: {},
    contacts: [],
    chat: [],
    message: [],
    listOfMessages: [],
}
export function ContactReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_CONTACT:
            return Object.assign({}, state, {
                contact: new DtoContact(action.contact),
            })
        case RECEIVE_CONTACTS:
            return Object.assign({}, state, {
                contacts: action.contacts.map((c) => new DtoContact(c)),
            })
        case RECEIVE_CHAT:
            return {
                ...state,
                chat: action.chat,
            }
        case RECEIVE_MESSAGE:
            return {
                ...state,
                message: action.message,
            }
        case RECEIVE_MESSAGES_OF:
            return {
                ...state,
                listOfMessages: action.listOfMessages,
            }
        case FULL_RESET:
            return {
                ...store,
            }
        default:
            return state
    }
}
