import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import DtoTankDeclaration from '../../agri/dto/enquete/DtoTankDeclaration'
import DtoTankExploit from '../../agri/dto/exploitation/DtoTankExploit'
import { formatMilliers } from '../../../../utils/StringUtil'
import { hasValue } from '../../../../utils/NumberUtil'
import { LightCard } from '../styled/grid'

const TankCard = ({ title, retenue, noLightCard, sx }) => {
    const {
        codesSandre,
        agriTanksTypes,
    } = useSelector(store => ({
        codesSandre: store.ReferencialReducer.codesSandre,
        agriTanksTypes: store.ReferencialReducer.agriTanksTypes,
    }), shallowEqual)

    const fillingModes = useMemo(() => codesSandre.filter((c) => c.field === 'PREL_AGRI.MODE_REMPLISSAGE'), [codesSandre])
    const fillingPeriodes = useMemo(() => codesSandre.filter((c) => c.field === 'PREL_AGRI.PERIODE_REMPLISSAGE'), [codesSandre])
    const tanksPeriodesDetails = useMemo(() => codesSandre.filter((code) => code.field === 'USAGES.PERIODES_DETAILS'), [codesSandre])

    const tankSaisonnal = useMemo(() => (retenue.link_periodes || []).find((p) => p.idPeriode === 2), [retenue])
    const tankAnnuel = useMemo(() => (retenue.link_periodes || []).find((p) => p.idPeriode === 1), [retenue])
    const tankUnknown = useMemo(() => (retenue.link_periodes || []).find((p) => p.idPeriode === 0), [retenue])
    const fillingPeriod = useMemo(() => tankSaisonnal ? 2 : (tankAnnuel ? 1 : (hasValue(tankUnknown) ? 0 : null)), [retenue, tankSaisonnal, tankAnnuel, tankUnknown])

    const tankType = useMemo(() => agriTanksTypes.find((type) => type.id === retenue.tankType), [retenue, agriTanksTypes])
    const fillingMode = useMemo(() => fillingModes.find((mode) => mode.code === retenue.fillingMode), [retenue, fillingModes])
    const fillingPeriode = useMemo(() => fillingPeriodes.find((c) => c.code === fillingPeriod) || {}, [fillingPeriod, fillingPeriodes])


    const getSelectedPeriod = (idPeriode, tankPeriodes) => {
        return !!tankPeriodes.find((p) => p.idNbPeriode === idPeriode)
    }

    let saisons = ''
    if (tankSaisonnal) {
        tanksPeriodesDetails.map((p) => {
            if (getSelectedPeriod(p.code, retenue.link_periodes)) {
                saisons = `${saisons}${p.name}, `
            }
        })
    }

    const content = (
        <Grid container direction='column' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px' }}>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item>{i18n.id}</Grid>
                <Grid item className='bold'>{retenue?.id ?? '-'}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item xs={2}>{i18n.type}</Grid>
                <Grid item xs={10} sx={{ textAlign: 'end' }}className='bold'>{tankType?.name ?? '-'}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item>{i18n.capacity}</Grid>
                <Grid item className='bold'>{hasValue(retenue?.capacity) ? `${formatMilliers(retenue.capacity)} m3` : '-'}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item>{i18n.recoveryFlow}</Grid>
                <Grid item className='bold'>{retenue?.recoveryFlow ?? '-'}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item>{i18n.reserveFlow}</Grid>
                <Grid item className='bold'>{retenue?.reserveFlow ?? '-'}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item xs={6}>{i18n.fillingMode}</Grid>
                <Grid item xs={6} sx={{ textAlign: 'end' }} className='bold'>{fillingMode?.name ?? '-'}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item xs={6}>{i18n.fillingPeriod}</Grid>
                <Grid item xs={6} sx={{ textAlign: 'end' }} className='bold'>{fillingPeriode?.name ?? '-'}{!!saisons.length && ` (${saisons.slice(0, -2)})`}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between'>
                <Grid item>{i18n.sharedRetention}</Grid>
                <Grid item className='bold'>{retenue?.sharedRetention ? i18n.yes : i18n.no}</Grid>
            </Grid>
        </Grid>
    )

    if (noLightCard) {
        return content
    }

    return (
        <LightCard
            item
            container
            direction='column'
            justifyContent='space-between'
            className='clickable'
            sx={{ marginBottom: '20px', ...sx }}
        >
            <Grid item fontSize={22} className='bold' sx={{ marginBottom: '20px' }}>{title ||i18n.retention}</Grid>
            {content}
        </LightCard>
    )
}

TankCard.propTypes = {
    title: PropTypes.string,
    retenue: PropTypes.instanceOf(DtoTankDeclaration || DtoTankExploit).isRequired,
    noLightCard: PropTypes.bool,
    sx: PropTypes.shape({}),
}

export default TankCard
