import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import DtoExploitation from '../../online/agri/dto/exploitation/DtoExploitation'
import { MainButton } from '../../online/components/styled/buttons'
import { formatPhone } from '../../../utils/StringUtil'
import LittleMap from '../../online/points/LittleMap'
import { isLargeWindow } from '../../../utils/LocalStorageUtils'
import { Container, Item } from '../../online/components/styled/grid'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../online/components/styled/dialog'
import { ReactComponent as CloseLogo } from '../../../ressources/static/svg/Fermer.svg'
import StyledCard from '../../online/components/cards/StyledCard'

function ModalFolder({ open, exploitation, onCancel, onValidate, citiesIndex }) {
    const city = citiesIndex[exploitation.inseeCode] || {}

    return (
        <DialogMUI open={open}>
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <b style={{ padding: 10, fontSize: 21 }}>{exploitation.codification}</b>
                    <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={onCancel} />
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Container
                    className='overflowY'
                    style={{
                        padding: '0 16px',
                    }}
                >
                    <Item xs={12} md={6} className={`padding-bottom-2${isLargeWindow()? ' padding-right-2' : ''}`}>
                        <StyledCard subTitle={exploitation.name || ''} styleSubTitle={{ borderTop: '1px solid black' }} styleContainer={{ padding: 0 }}>
                            <p>{exploitation.structureType || ''}</p>
                            <p>{`${exploitation.city || ''} [${exploitation.inseeCode || ''}]`}</p>
                            <p>{exploitation.address || ''}</p>
                            <p>{formatPhone(exploitation.phoneTel || '')}</p>
                            <p>{formatPhone(exploitation.mobile || '')}</p>
                            <p>{exploitation.email || ''}</p>
                        </StyledCard>
                    </Item>
                    <Item xs={12} md={6} style={{ height: isLargeWindow() ? 500 : 200 }}>
                        {city.x && city.y && (
                            <LittleMap point={city} height={isLargeWindow() ? 500 : 200} />
                        )}
                    </Item>
                </Container>
            </DialogContentMUI>
            <DialogActionsMUI>
                <MainButton onClick={onValidate}>
                    {i18n.accessFolder}
                </MainButton>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

ModalFolder.propTypes = {
    open: PropTypes.bool.isRequired,
    exploitation: PropTypes.instanceOf(DtoExploitation).isRequired,
    onCancel: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
}

const mapStateToProps = (store) => {
    return {
        citiesIndex: store.CityReducer.citiesIndex,
    }
}

export default connect(mapStateToProps)(ModalFolder)