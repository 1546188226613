import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'
import { Container, Item } from '../styled/grid'

// [1/7, '#FD0807'],
// [2/7, '#FF5000'],
// [3/7, '#FF9600'],
// [4/7, '#FFFE00'],
// [5/7, '#85B602'],
// [6/7, '#037F03'],
// [1, '#005100'],

// const getLine = (angle, radius) => {
//     return {
//         type: 'gauge',
//         radius: radius || '75%',
//         center: ['50%', '60%'],
//         startAngle: angle - 1,
//         endAngle: angle + 1,
//         splitNumber: 0,
//         axisTick: { show: false },
//         splitLine: {
//             show: true,
//             length: '100%',
//             lineStyle: {
//                 color: '#FFF'
//             }
//         },
//         axisLine: {
//             lineStyle: {
//                 color: [
//                     [1, 'transparent'],
//                 ],
//             }
//         },
//         detail: { show: true },
//     }
// }

export default class GroundFillingGauge extends PureComponent {
    getOption = () => {
        const { value } = this.props
        return {
            series: [
                // Affichage des couleurs exterieurs
                {
                    type: 'gauge',
                    radius: '110%',
                    center: ['50%', '60%'],
                    min: 0,
                    max: 100,
                    startAngle: 180,
                    endAngle: -90,
                    splitNumber: 2,
                    axisTick: { show: false },
                    splitLine: { show: false },
                    // pointer: {
                    //     width: 15,
                    // },
                    axisLine: {
                        lineStyle: {
                            color: [
                                [0.152, '#FFFFFF'],
                                [0.658, new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#85B602'
                                }, {
                                    offset: 1,
                                    color: '#85B602'
                                }])],
                                // [0.502, '#FFF'],
                                // [0.8, new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                //     offset: 0,
                                //     color: '#85B602'
                                // }, {
                                //     offset: 1,
                                //     color: '#037F03'
                                // }])],
                                // [1, '#FFF']
                            ],
                            // width: '0%',
                            // border: '1px solid black'
                        }
                    },
                    axisLabel: { show: false },
                    detail: { show: false },
                    // data: [{
                    //     value,
                    //     itemStyle: { color: 'transparent' },
                    // }],
                },
                // Affichage des couleurs interieures
                {
                    type: 'gauge',
                    radius: '85%',
                    center: ['50%', '60%'],
                    min: 0,
                    max: 7,
                    startAngle: 180,
                    endAngle: -90,
                    splitNumber: 7,
                    splitLine: { show: false },
                    axisTick: { show: false },
                    axisLine: {
                        lineStyle: {
                            color: [
                                [0.148, '#004D86'],
                                [0.152, '#FFF'], // 0.04 de diff (0.02 * 2)

                                [0.298, '#0070C0'],
                                [0.304, '#FFF'],

                                [0.498, '#0083E6'],
                                [0.502, '#FFF'],

                                [0.658, '#71C2FF'],
                                [0.662, '#FFF'],

                                [0.80, '#BDE3FF'],
                                [1, '#FFF'],
                            ],
                            width: '30%',
                        }
                    },
                    axisLabel: { show: false },
                    detail: { show: false },
                },
                // Affichage des labels exterieurs
                // {
                //     type: 'gauge',
                //     radius: '85%',
                //     center: ['50%', '60%'],
                //     min: 0,
                //     max: 100,
                //     startAngle: 170,
                //     endAngle: -10,
                //     splitLine: { show: false },
                //     splitNumber: 20,
                //     axisTick: { show: false },
                //     axisLine: {
                //         lineStyle: {
                //             color: [
                //                 [1, 'transparent'],
                //             ],
                //         },
                //     },
                //     axisLabel: {
                //         color: 'black',
                //         fontSize: 15,
                //         distance: -55,
                //         formatter: (v) => {
                //             switch (v) {
                //                 case 25:
                //                     return 'Nul'
                //                 case 55:
                //                     return 'Partiel'
                //                 case 90:
                //                     return 'Max'
                //                 default:
                //                     return ''
                //             }
                //         }
                //     },
                //     detail: { show: false },
                // },
                // Affichage des notes
                {
                    type: 'gauge',
                    radius: '85%',
                    center: ['50%', '60%'],
                    min: 0,
                    max: 100,
                    startAngle: 170,
                    endAngle: 5,
                    splitLine: { show: false },
                    splitNumber: 20,
                    axisTick: { show: false },
                    axisLine: {
                        lineStyle: {
                            color: [
                                [1, 'transparent'],
                            ],
                        },
                    },
                    axisLabel: {
                        color: 'white',
                        fontSize: 12,
                        distance: -15,
                        formatter: (v) => {
                            switch (v) {
                                case 5:
                                    return 'Excès'
                                case 25:
                                    return 'Elevé'
                                case 70:
                                    return 'Raisonné'
                                case 95:
                                    return 'Déficitaire'
                                default:
                                    return ''
                            }
                        }
                    },
                    detail: { show: false },
                },
                // Affichage des notes
                {
                    type: 'gauge',
                    radius: '95%',
                    center: ['50%', '60%'],
                    min: 0,
                    max: 100,
                    startAngle: 170,
                    endAngle: -15,
                    splitLine: { show: false },
                    splitNumber: 50,
                    axisTick: { show: false },
                    axisLine: {
                        lineStyle: {
                            color: [
                                [1, 'transparent'],
                            ],
                        },
                    },
                    axisLabel: {
                        color: 'black',
                        fontSize: 12,
                        distance: -15,
                        formatter: (v) => {
                            switch (v) {
                                case 98:
                                    return 'Stress\nprononcé'
                                default:
                                    return ''
                            }
                        }
                    },
                    detail: { show: false },
                },
                // Affichage du titre
                // {
                //     type: 'gauge',
                //     radius: '140%',
                //     center: ['50%', '60%'],
                //     min: 0,
                //     max: 2,
                //     startAngle: 90,
                //     endAngle: 90,
                //     splitLine: { show: false },
                //     splitNumber: 2,
                //     axisTick: { show: false },
                //     axisLine: {
                //         lineStyle: {
                //             color: [
                //                 [1, 'transparent'],
                //             ],
                //         },
                //     },
                //     axisLabel: {
                //         color: 'black',
                //         fontSize: 16,
                //         formatter: (v) => {
                //             switch (v) {
                //                 case 1:
                //                     return 'Gain de points d\'EEE'
                //                 default:
                //                     return ''
                //             }
                //         }
                //     },
                //     detail: { show: false },
                // },
                // getLine(180 - 40),
                // getLine(180 - (40 * 2)),
                // getLine(180 - (40 * 3) - 15, '110%'),
                // getLine(180 - (40 * 4) - 18),
                // Affichage de la fleche
                {
                    type: 'gauge',
                    radius: '80%',
                    center: ['50%', '60%'],
                    min: 0,
                    max: 80,
                    startAngle: 180,
                    endAngle: -36,
                    splitNumber: 7,
                    axisTick: { show: false },
                    splitLine: { show: false },
                    axisLine: {
                        lineStyle: {
                            color: [
                                [1, '#D0CDCE'],
                            ],
                            width: 0,
                        }
                    },
                    axisLabel: { show: false },
                    detail: { show: false },
                    data: [{
                        value,
                        itemStyle: { color: '#000' },
                    }],
                },
            ]
        }
    }

    render() {
        const { title, value } = this.props
        return (
            <Container
                justifyContent='center'
                alignItems='center'
                style={{ width: '100%' }}
            >
                <Item xs={12} className='center valueLabel'>
                    <b>{title}</b>
                </Item>
                <Container
                    justifyContent='center'
                    alignItems='center'
                    style={{ height: 260, width: '100%' }}
                >
                    <ReactEcharts
                        option={this.getOption()}
                        style={{ height: '100%', width: '100%' }}
                        className='react_for_echarts'
                    />
                </Container>
                <Item className='center bold' style={{ marginTop: -120, marginLeft: -110, zIndex: 1 }}>
                    <span style={{ color: 'transparent' }}>% remplissage du sol</span><br />
                    <span style={{ fontSize: '1.4rem' }}>{100 - value}</span>%
                </Item>
                <svg width='300' height='260' viewBox='0 0 300 260' style={{ position: 'absolute' }}>
                    <path id='curve' fill='transparent' d='M77,57 q137,-58 197,67' />
                    <text width='300'>
                        <textPath xlinkHref='#curve' className='bold' style={{ fontSize: 16 }}>
                            Réserve Facilement Utilisable
                        </textPath>
                    </text>
                </svg>
            </Container>
        )
    }
}

GroundFillingGauge.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
    nbParts: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
}