import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from '../../components/toasters/ToastrAction'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization } from '../../../../utils/ActionUtils'
import {
    RECEIVE_ACCOUNT,
    RECEIVE_ACCOUNT_HABILITATION,
    RECEIVE_ACCOUNT_STATISTICS,
    RECEIVE_ACCOUNT_USER_KEYFIGURES,
    RECEIVE_ACCOUNT_USER_SETTINGS,
    SAVED_USER,
} from '../constants/AccountConstants'
import LogAction from '../../../../utils/log/actions/LogAction'

const AccountAction = {
    receiveUser(accountUser) {
        return {
            type: RECEIVE_ACCOUNT,
            accountUser,
        }
    },

    savedUser(user) {
        return {
            type: SAVED_USER,
            user,
        }
    },

    fetchUser(login) {
        return dispatch => Promise.all([
            AccountAction.promiseUser(login),
            AccountAction.promiseAccountHabilitations(login),
            AccountAction.promiseAccountUserKeyfigure(login),
            AccountAction.promiseAccountUserSettings(),
            AccountAction.promiseAccountStatistics(login),
        ]).then(jsonTab => {
            dispatch(AccountAction.receiveUser(jsonTab[0]))
            dispatch(AccountAction.receiveAccountHabilitation(jsonTab[1]))
            dispatch(AccountAction.receiveAccountUserKeyfigures(jsonTab[2]))
            dispatch(AccountAction.receiveAccountUserSettings(jsonTab[3]))
            dispatch(AccountAction.receiveAccountStatistics(jsonTab[4]))
            return jsonTab[0]
        })
    },

    promiseUser(login) {
        return fetch(ApplicationConf.user.get(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    updateUser(user) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.put(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(user)
            }).then(() => {
                dispatch(AccountAction.fetchUser(user.login))
            })
        }
    },

    receiveAccountUserKeyfigures(accountUserKeyFigures) {
        return {
            type: RECEIVE_ACCOUNT_USER_KEYFIGURES,
            accountUserKeyFigures,
        }
    },

    promiseAccountUserKeyfigure(login) {
        return fetch(ApplicationConf.user.getKeyFigures(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchAccountUserKeyfigure(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountUserKeyfigure(login)
                .then((json) => {
                    dispatch(AccountAction.receiveAccountUserKeyfigures(json))
                })
        }
    },

    fetchAccountCGUDate(login) {
        return () => {
            return fetch(ApplicationConf.user.getUserCGUDate(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(checkError)
                .catch(() => {
                    ToastrAction.error(i18n.errorGetUserInfo)
                })
        }
    },
    receiveAccountHabilitation(accountHabilitations) {
        return { type: RECEIVE_ACCOUNT_HABILITATION, accountHabilitations }
    },

    promiseAccountHabilitations(login) {
        return fetch(ApplicationConf.user.habilitations(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchAccountHabilitations(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountHabilitations(login)
                .then((json) => {
                    dispatch(AccountAction.receiveAccountHabilitation(json))
                })
        }
    },
    receiveAccountStatistics(accountStatistics) {
        return { type: RECEIVE_ACCOUNT_STATISTICS, accountStatistics }
    },

    promiseAccountStatistics(login) {
        return fetch(ApplicationConf.user.getStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchAccountStatistics(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountStatistics(login)
                .then((json) => {
                    dispatch(AccountAction.receiveAccountStatistics(json))
                })
        }
    },

    updateAccountCGUStatistic(object) {
        return () => {
            return fetch(ApplicationConf.user.postUserCGUDate(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(object),
            })
        }
    },
    receiveAccountUserSettings(accountUserSettings) {
        return { type: RECEIVE_ACCOUNT_USER_SETTINGS, accountUserSettings }
    },

    promiseAccountUserSettings() {
        return fetch(ApplicationConf.user.settings(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchAccountUserSettings(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountUserSettings(login)
                .then((json) => {
                    dispatch(AccountAction.receiveAccountUserSettings(json))
                })
        }
    },

    changePassword(password) {
        return () => {
            return fetch(ApplicationConf.user.changePassword(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(password),
            })
                .then((json) => {
                    if (json.status === 200) {
                        ToastrAction.success(i18n.elementUpdateSuccess, true)
                    } else {
                        ToastrAction.error(i18n.updateError + i18n.password, true)
                    }
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.password} : ${err}`)
                })
        }
    },
}

export default AccountAction
