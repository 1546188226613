import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import HomeAction from '../../offline/actions/HomeAction'
import DtoRSEauDashboard from '../agri/dto/rseau/DtoRSEauDashboard'
import AgriAction from '../agri/actions/AgriAction'
import { Grid } from '@mui/material'
import StyledCard from '../components/cards/StyledCard'
import GroundFillingGauge from '../components/charts/GroundFillingGauge'
import { formatDate } from '../../../utils/DateUtil'
import DoubleGauge from '../components/charts/DoubleGauge'

class DashboardWaterManagement extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.fetchRSEauData()
    }

    getContentHelp = (date, points, counterpart) => {
        return `Au ${formatDate(date)}, vous avez acquis ${points} points d\'éco efficience\nVotre prime d\'éco efficience s\'élève à ${counterpart}€/ha.\nCette performance vous donne accès au droit d\'eau dérogatoire de niveau 1.`
    }

    render() {
        const { rseau } = this.props
        if (rseau.date) {
            const { EEEGaugeSteps, date, EEE, hasDD1, hasDD2, PSE, ecoNote } = rseau
            const min = EEEGaugeSteps[0]
            const max = EEEGaugeSteps[EEEGaugeSteps.length - 1]
            return (
                <>
                    <Grid item xs={12}>
                        <StyledCard title={i18n.groundFillingLevel} hideIcon styleTitle={{ fontSize: '1.2rem' }}>
                            <GroundFillingGauge value={100 - Math.trunc(rseau.parsimonyScore || 0)} />
                        </StyledCard>
                    </Grid>
                    {EEEGaugeSteps.length && (
                        <Grid item xs={12} className='padding-top-1'>
                            <StyledCard
                                title={`Gains au ${formatDate(date) || ''}`}
                                hideIcon
                                noInnerPadding
                                tooltipIcon='help'
                                tooltipContent={`Au ${formatDate(date)}, vous avez acquis ${EEE || 0} points d\'éco efficience\nVotre prime d\'éco efficience s\'élève à ${PSE || 0}€/ha.\n${hasDD1 || hasDD2 ? `Cette performance vous donne accès au droit d\'eau dérogatoire de niveau ${hasDD2 ? '2' : '1'}.` : ''}`}
                                styleTitle={{ fontSize: '1.2rem' }}
                            >
                                <>
                                    <DoubleGauge
                                        value={EEE}
                                        nbParts={EEEGaugeSteps.length - 1}
                                        min={min}
                                        max={max}
                                        style={{ marginTop: 4 }}
                                    />
                                    <Grid container alignItems='center' direction='column' spacing={1}>
                                        <Grid item>
                                            <span className='textRight' style={{ fontSize: '1rem' }}>{`${i18n.pointsAcquired} : `}</span>
                                            <span className='bold' style={{ fontSize: '1.4rem' }}>{`${EEE || 0}/100 (${ecoNote})`}</span>
                                        </Grid>
                                        <Grid item>
                                            <span className='textRight' style={{ fontSize: '1rem' }}>{`${i18n.considerationAcquired} : `}</span>
                                            <span className='bold' style={{ fontSize: '1.4rem' }}>{`${PSE || 0} €/ha`}</span>
                                        </Grid>
                                        <Grid item>
                                            <span className='textRight' style={{ fontSize: '1rem' }}>{`${i18n.exemptionAcquired} : `}</span>
                                            <span className='bold' style={{ fontSize: '1.4rem' }}>{hasDD2 ? 'Niveau 2' : (hasDD1 ? 'Niveau 1' : 'Aucune')}</span>
                                        </Grid>
                                    </Grid>
                                </>
                            </StyledCard>
                        </Grid>
                    )}
                </>
            )
        }
        return null
    }
}

DashboardWaterManagement.propTypes = {
    fetchRSEauData: PropTypes.func,
    idInstallation: PropTypes.number,
    rseau: PropTypes.instanceOf(DtoRSEauDashboard),
}

const mapStateToProps = (store) => {
    return {
        rseau: store.AgriReducer.rseau,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchRSEauData: AgriAction.fetchRSEauData,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardWaterManagement)