import { compact, isNil, max, min, orderBy, range } from 'lodash'
import React from 'react'
import i18n from 'simple-react-i18n'
import { ReactComponent as EmptyLogo } from '../ressources/static/svg/Niveau1.svg'
import { ReactComponent as Level2Logo } from '../ressources/static/svg/Niveau2.svg'
import { ReactComponent as Level3Logo } from '../ressources/static/svg/Niveau3.svg'
import { ReactComponent as Level4Logo } from '../ressources/static/svg/Niveau4.svg'
import { ReactComponent as Level5Logo } from '../ressources/static/svg/Niveau5.svg'
import { ReactComponent as Level6Logo } from '../ressources/static/svg/Niveau6.svg'
import { ReactComponent as FullLogo } from '../ressources/static/svg/Niveau7.svg'
import { ReactComponent as RestrictionNormaleLogo } from '../ressources/static/svg/Restriction_Normale.svg'
import { ReactComponent as RestrictionViliganceLogo } from '../ressources/static/svg/Restriction_Viligance.svg'
import { ReactComponent as RestrictionAlerteLogo } from '../ressources/static/svg/Restriction_Alerte.svg'
import { ReactComponent as RestrictionAlerteRenforceeLogo } from '../ressources/static/svg/Restriction_AlerteRenforcee.svg'
import { ReactComponent as RestrictionCriseLogo } from '../ressources/static/svg/Restriction_Crise.svg'
import { Grid } from '@mui/material'
import { CHRONICLES_CONSTANTS, POINT_STATUS_DECLARATION, SAMPLE_TYPES } from '../pages/online/agri/constants/AgriConstants'
import moment from 'moment'
import { sieauTooltip } from './FormUtils'
import { CheckCircleOutlined, Error, PlayArrow, Square } from '@mui/icons-material'

const getWaterLeftIcon = (percentLeft, style = {}, color) => {
    if (percentLeft <= 0) {
        return <EmptyLogo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    if (percentLeft <= 20) {
        return <Level2Logo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    if (percentLeft <= 40) {
        return <Level3Logo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    if (percentLeft <= 60) {
        return <Level4Logo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    if (percentLeft <= 80) {
        return <Level5Logo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    if (percentLeft < 100) {
        return <Level6Logo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    if (percentLeft === 100) {
        return <FullLogo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    return <FullLogo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
}

const getRestrictionIcon = (level, style = {}, color) => {
    if (level === -1) {
        return <RestrictionNormaleLogo {...sieauTooltip(i18n.restrictionLevel)} style={style} fill={color} />
    }
    if (level === 1) {
        return <RestrictionViliganceLogo {...sieauTooltip(i18n.restrictionLevel)} style={style} fill={color} />
    }
    if (level === 2) {
        return <RestrictionAlerteLogo {...sieauTooltip(i18n.restrictionLevel)} style={style} fill={color} />
    }
    if (level === 3) {
        return <RestrictionAlerteRenforceeLogo {...sieauTooltip(i18n.restrictionLevel)} style={style} fill={color} />
    }
    if (level === 4) {
        return <RestrictionCriseLogo {...sieauTooltip(i18n.restrictionLevel)} style={style} fill={color} />
    }
    return <RestrictionNormaleLogo {...sieauTooltip(i18n.restrictionLevel)} style={style} fill={color} />
}

const getRestrictionIconV2 = (level, sx) => {
    if (level && level !== 1) {
        return <Error {...sieauTooltip(i18n.restrictionLevel)} sx={sx} />
    }
    return <CheckCircleOutlined {...sieauTooltip(i18n.restrictionLevel)} sx={{ color: 'green', ...sx }} />
}

const formatValueDay = (waterTurn, day, plageActuelle) => {
    return waterTurn[day].split('').map((v, i) => {
        if (i === plageActuelle) {
            return `${v}|`
        }
        return v
    }).join('')
}

const getWaterTurns = (waterTurns, restrictions, waterTurnsSlots) => {
    const allRestrictions = [
        ...restrictions,
        {
            id: 'X',
            color: '#52D100',
            level: 'X',
        },
    ]
    const date = new Date()
    const startHour = 2
    const day = `day${date.getDay() || 7}`
    const hour = Math.abs(date.getHours() - startHour)
    const allLevels = allRestrictions.map((r) => r.level)
    const restrictionMax = max(allLevels)
    const allActualLevels = waterTurns.map((w) => {
        const slot = waterTurnsSlots.find(wts => wts.id === w.idSlot) || { nbSlots: 1, id: -1 }
        const slotsDisplay = range(slot.nbSlots || 1)
        const dureePlage = 24 / slot.nbSlots
        const plageActuelle = Math.trunc(hour / dureePlage)
        const allDays = (() => {
            let week = (day !== 'day1') ? w.day1 : formatValueDay(w, day, plageActuelle)
            week = (day !== 'day2') ? (week + w.day2) : (`${week}${formatValueDay(w, day, plageActuelle)}`)
            week = (day !== 'day3') ? (week + w.day3) : (`${week}${formatValueDay(w, day, plageActuelle)}`)
            week = (day !== 'day4') ? (week + w.day4) : (`${week}${formatValueDay(w, day, plageActuelle)}`)
            week = (day !== 'day5') ? (week + w.day5) : (`${week}${formatValueDay(w, day, plageActuelle)}`)
            week = (day !== 'day6') ? (week + w.day6) : (`${week}${formatValueDay(w, day, plageActuelle)}`)
            week = (day !== 'day7') ? (week + w.day7) : (`${week}${formatValueDay(w, day, plageActuelle)}`)
            return week
        })()
        const allDaysSplit = allDays.split('|')
        const allDaysFromNow = allDaysSplit[1] + allDaysSplit[0]
        let nextIndex = null
        allLevels.forEach((l) => {
            if (l !== restrictionMax) {
                const next = allDaysFromNow.indexOf(l)
                if (next !== -1 && (isNil(nextIndex) || next < nextIndex)) {
                    nextIndex = next
                }
            }
        })
        const value = w[day][slotsDisplay[plageActuelle]] || 'X'
        return { ...allRestrictions.find((r) => `${r.level}` === value), slot, timeNextSampling: nextIndex === -1 ? 0 : (nextIndex * dureePlage) }
    })
    const x = allActualLevels.filter((r) => r.level === 'x')
    const others = orderBy(allActualLevels.filter((r) => r.level !== 'x'), 'level')
    return [...x, ...others][0]
}

const getColorAndLabelWaterTurn = (waterTurnsRestrictions, exploitationWaterTurns, waterTurnsSlots, point) => {
    const waterTurns = exploitationWaterTurns.filter((w) => w.idInstallation === point.id && w.year === moment().year())
    if (waterTurns) {
        const hour = new Date().getHours()
        const res = getWaterTurns(waterTurns, waterTurnsRestrictions, waterTurnsSlots)
        if (res) {
            const allLevels = waterTurnsRestrictions.map((r) => r.level)
            const restrictionMax = max(allLevels)
            const { slot } = res
            const { label, time } = compact((slot.slots || '').split('/').map((s) => {
                const [start, end] = s.split('-')
                const timeRemaining = end - hour
                if ((hour >= parseInt(start) && hour < parseInt(end)) || start === end) {
                    return { label: `${start}h-${end}h${min(allLevels) === res.level ? '' : '*'}`, time: timeRemaining < 0 ? (timeRemaining + 24) : timeRemaining }
                }
                return null
            }))[0] || ''
            return { color: restrictionMax === res.level ? 'red' : res.color, label, max: restrictionMax === res.level, timeNextSampling: res.timeNextSampling + time }
        }
        return { color: 'grey', label: i18n.noWaterTurn }
    }
    return { color: 'grey', label: i18n.noWaterTurn }
}

const getSquare = (color, isLast) => (
    <div
        style={{
            backgroundColor: color || 'white',
            display: 'inline-block',
            width: '20px',
            border: 'solid black 1px',
            borderRight: isLast ? 'solid black 1px' : '',
        }}
    >
        &nbsp;
    </div>
)

const getDayDisplay = (value, dayLabel, slotsDisplay, restrictions) => {
    const allRestrictions = [
        ...restrictions,
        {
            id: 'X',
            color: '#52D100',
            level: 'X',
        },
    ]
    if (value) {
        const slots = value.split('')
        return (
            <Grid container style={{ padding: 8, paddingLeft: 0 }}>
                <Grid item xs={3}>{dayLabel} : </Grid>
                <Grid item xs={9}>{slotsDisplay.map((s) => getSquare((allRestrictions.find(r => `${r.level}` === slots[s]) || {}).color, s === slotsDisplay.length - 1))}</Grid>
            </Grid>
        )
    }
    return null
}

const getTextSlot = (waterTurnSlots) => {
    const slots = waterTurnSlots && waterTurnSlots.slots && waterTurnSlots.slots.split('/')
    return slots && slots.map((slot => {
        const hours = slot && slot.split('-')
        return hours.map(hour => `${hour}h`).join('-') || ''
    })).join('  ') || i18n.notDefined
}

const getEvolValue = (type, chronicles, chronicle, index) => {
    if (type === CHRONICLES_CONSTANTS.TYPE_INDEX) {
        return chronicle.value - chronicles[index + 1].value
    }
    return Math.round(chronicles.slice(index).reduce((acc, d) => acc + d.value, 0))
}

const getIPSLabel = (level) => {
    switch (level) {
        case 'up':
            return i18n.improvement
        case 'right':
            return i18n.stagnation
        case 'down':
            return i18n.degradation
        default:
            return i18n.unknown
    }
}

const getIPSIcon = (level, color) => {
    switch (level) {
        case 'up':
            return <PlayArrow sx={{ color, rotate: '-90deg', fontSize: '50px' }} />
        case 'right':
            return <Square sx={{ color, fontSize: '40px', padding: '5px 0' }} />
        case 'down':
            return <PlayArrow sx={{ color, rotate: '90deg', fontSize: '50px' }} />
        default:
            return undefined
    }
}

const getIPSIconDesktop = (level, color) => {
    switch (level) {
        case 'up':
            return <PlayArrow sx={{ color, rotate: '-90deg', fontSize: '25px' }} />
        case 'right':
            return <Square sx={{ color, fontSize: '15px' }} />
        case 'down':
            return <PlayArrow sx={{ color, rotate: '90deg', fontSize: '25px' }} />
        default:
            return undefined
    }
}

const getStateLabel = (stateCode) => {
    switch (stateCode) {
        case 1:
            return i18n.usedLabel
        case 2: default:
            return i18n.notUsedLabel
        case 3:
            return i18n.closed
    }
}


const getStateLabelDecla = (stateCode) => {
    switch (stateCode) {
        case POINT_STATUS_DECLARATION.USED:
            return i18n.usedLabel
        case POINT_STATUS_DECLARATION.REMOVED :
            return i18n.closed
        default:
            return i18n.notUsedLabel
    }
}

const getTypePrel = (key) => {
    switch (key) {
        case SAMPLE_TYPES.UNDERGROUND:
            return 'PREL_AGRI.NATURE_PRELEVEMENT_ESO'
        case SAMPLE_TYPES.SUPERFICIAL:
            return 'PREL_AGRI.NATURE_PRELEVEMENT_ESU'
        default:
            return 'PREL_AGRI.NATURE_PRELEVEMENT'
    }
}


export { getWaterLeftIcon, getRestrictionIcon, getRestrictionIconV2, getWaterTurns, getDayDisplay, getSquare,
    getTextSlot, getEvolValue, getColorAndLabelWaterTurn, getIPSLabel, getIPSIcon, getIPSIconDesktop,
    getStateLabel, getStateLabelDecla, getTypePrel }
