/* eslint-disable max-len */
import proj4 from 'proj4'
import i18n from 'simple-react-i18n'
import * as olSphere from 'ol/sphere'
import { register } from 'ol/proj/proj4'
import { hasValue, round } from '../NumberUtil'
import { formatMilliers } from '../StringUtil'
import { Icon, Style } from 'ol/style'

const getProjections = () => [
    // add other projections here
    { sandreCode: 2, code: 'EPSG:27562', name: 'Lambert Centre France', proj: '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs' },
    { sandreCode: 5, code: 'EPSG:27572', name: 'Lambert zone II', proj: '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs' },
    { sandreCode: 7, code: 'EPSG:27572', name: 'Lambert II Carto', proj: '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs' },
    { sandreCode: 23, code: 'EPSG:2971', name: 'Guyane CSG67 UTM 22', proj: '+proj=utm +zone=22 +ellps=intl +towgs84=-186,230,110,0,0,0,0 +units=m +no_defs' },
    { sandreCode: 26, code: 'EPSG:2154', name: 'Lambert-93 France', proj: '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs' },
    { sandreCode: 16, code: 'EPSG:4326', name: 'WGS84 UTM30', proj: null },
    { sandreCode: 31, code: 'EPSG:4326', name: 'WGS84G', proj: null },
    { sandreCode: 39, code: 'EPSG:4559', name: 'RRAF 1991 (French Antilles)', proj: '+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs' },
    { sandreCode: 40, code: 'EPSG:2972', name: 'Guyane RGFG95 UTM 22', proj: '+proj=utm +zone=22 +ellps=GRS80 +towgs84=2,2,-2,0,0,0,0 +units=m +no_defs' }
]

const EPSG4326 = 'EPSG:4326'

const EPSG3857 = 'EPSG:3857'

const loadWMSProjections = () => {
    getProjections().filter(p => !!p.proj).forEach(p => {
        proj4.defs(p.code, p.proj)
    })
    register(proj4)
}

const getWGS84Coordinate = (loc) => {
    if (hasValue(loc.projection) && hasValue(loc.x) && hasValue(loc.y)) {
        // Specific cases
        if ([16, 31].includes(loc.projection)) {
            return [+loc.x, +loc.y]
        }
        if (loc.projection === 3003) {
            const result = proj4('+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=1500000 +y_0=0 +ellps=intl +towgs84=-104.1,-49.1,-9.9,0.971,-2.917,0.714,-11.68 +units=m +no_defs', 'WGS84', [+loc.x, +loc.y])
            if (result.includes(NaN)) {
                // eslint-disable-next-line no-console
                console.error(i18n.wrongCoordinates + loc.code)
                return [null, null]
            }
            return result
        }
        if (loc.projection === 0) {
            return [2.4983333333333335, 46.6058333]
        }

        // managed cases
        const found = getProjections().find(p => p.sandreCode === loc.projection)
        if (found) {
            return proj4(found.proj, 'WGS84', [+loc.x, +loc.y])
        }
        // eslint-disable-next-line no-console
        console.error(`${i18n.unknownProjection} : ${loc.projection}`)
    }
    return proj4('+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs', 'WGS84', [+loc.x, +loc.y])
}

const getDistanceLabel = (distance = 0) => {
    if (distance >= 1000) {
        const km = formatMilliers((distance / 1000).toFixed(1))
        if (km >= 10) {
            return `${km.split('.')[0]}km`
        }
        return `${km.endsWith('.0') ? km.replace('.0', '') : km}km`.replace('.', ',')
    }
    return `${distance} m`.replace('.', ',')
}

const getDistance = (station1, station2) => {
    return round(olSphere.getDistance(getWGS84Coordinate(station1), getWGS84Coordinate(station2)), 0)
}

const convertCoordinates = (fromProj, toProj, coordinates) => {
    const toProjFound = getProjections().find(p => p.sandreCode === toProj) || {}
    if (fromProj === 16) { // if from WGS84, no need to pass projection
        return proj4(toProjFound.proj, coordinates)
    }
    const fromProjFound = getProjections().find(p => p.sandreCode === fromProj) || {}
    return proj4(fromProjFound.proj, toProjFound.proj, coordinates)
}

const createIconStyle = (imgsrc, scale, text) => {
    const textObj = hasValue(text) ? new Text({
        text,
        offsetY: -35,
        font: 'bold 22px Arial',
        scale,
    }) : null
    if (imgsrc.startsWith('<svg')) {
        return new Style({
            image: new Icon(({
                scale,
                anchor: [0.5, 0.5],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: `data:image/svg+xml;utf8,${imgsrc}`,
            })),
            text: textObj,
        })
    }
    return new Style({
        image: new Icon(({
            scale,
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: imgsrc,
        })),
        text: textObj,
    })
}

export { getProjections,
    getDistanceLabel,
    getDistance,
    loadWMSProjections,
    getWGS84Coordinate,
    EPSG4326,
    EPSG3857,
    convertCoordinates,
    createIconStyle,
}
