export default class DtoRSEauStrategy {
    constructor(object) {
        this.landId = object.landId // Int,
        this.name = object.name // Option[String],
        this.date = object.date // Option[DateTime] = None,
        this.dose = object.dose // Option[Double] = None,
        this.dose = object.dose // Option[Double] = None,
        this.gain = object.gain // Option[Double] = None,
        this.gainNote = object.gainNote // Option[String] = None
    }
}
