/* eslint-disable no-underscore-dangle */
import { isNil } from 'lodash'
import moment from 'moment'
import UUID from 'uuid'

const getComponentWithId = (id) => {
    const dom = $(id)[0]
    if (!dom) {
        return null
    }
    let internalInstance = dom[Object.keys(dom).find(key => key.startsWith('__reactInternalInstance$'))]
    if (!internalInstance) {
        internalInstance = dom[Object.keys(dom).find(key => key.startsWith('__reactFiber$'))]
        if (!internalInstance) {
            return null
        }
    }
    return internalInstance._debugOwner ? internalInstance._debugOwner.stateNode : internalInstance.return.stateNode
}

const setSieauTooltip = (id, content, position, navMenuStyleActive) => {
    getComponentWithId('#sieautooltip').setTooltip(id, content, position, navMenuStyleActive)
}

// if content is not a simple string, pass it as a function : const content = () => <myReactContent/>
// that would be more optimized (the react content would be loaded just on the tooltip time)
const sieauTooltip = (content, chosenId, position, navMenuStyleActive) => {
    const id = chosenId || UUID.v4()
    return {
        onMouseOver: () => setSieauTooltip(id, typeof content === 'function' ? content() : content, position, navMenuStyleActive),
        onMouseOut: () => setSieauTooltip(null),
        id,
        onMouseDown: () => setSieauTooltip(null),
    }
}

const onChangeDate = (v, callback = () => { }) => {
    if (v.length >= 10) {
        const regex = new RegExp('\\d\\d\\/\\d\\d\\/\\d\\d\\d\\d')
        const date = moment(v, 'DD/MM/YYYY')
        if (v.length === 10 && v[2] === '/' && v[5] === '/' && date.isValid() && regex.test(v)) {
            callback(date.valueOf())
        }
    }
}

const onChangeHour = (v, callback = () => { }) => {
    if (v.length >= 8) {
        const regex = /\d\d:\d\d:\d\d/g
        const date = moment(v, 'HH:mm:ss')
        if (v.length === 8 && v[2] === ':' && v[5] === ':' && date.isValid() && regex.test(v)) {
            callback(date.valueOf())
        }
    }
}

const isValidSiret = (siret) => {
    if ((isNil(siret)) || (siret.length != 14)) {
        return false
    }
    const somme = siret.split('').reduce((sum, val, i) => i % 2 === 0 ? sum + (Number(val)*2 > 9 ? (Number(val)*2)-9 : Number(val)*2) : sum + Number(val), 0)
    if ((somme % 10) == 0) {
        return true
    }
    return false
}

const isValidZIPCode = (ZIPCode) => {
    const regex = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/
    return regex.test(ZIPCode)
}

const isValidEmail = (email) => {
    // eslint-disable-next-line no-control-regex
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
    return regex.test(email)
}

const isValidPhone = (phone) => {
    const regex = /0[1-9]([0-9]{2}){4}/g
    return regex.test(phone)
}

const getSetting = (settingList, parameter) => {
    const found = settingList?.find(s => s.parameter === parameter)
    return found ? found.value : null
}

const getSettingInt = (settingList, parameter) => {
    const found = settingList?.find(s => s.parameter === parameter)
    return found && parseInt(found.value) ? parseInt(found.value) : null
}

export { onChangeHour, onChangeDate, isValidSiret, isValidZIPCode, isValidEmail, isValidPhone, getSetting, getSettingInt, sieauTooltip }
