import { Grid, Paper, TextField } from '@mui/material'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { getDate } from '../../../utils/DateUtil'
import moment from 'moment'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MainButton, TextButton } from './styled/buttons'
import LoadingCard from './cards/LoadingCard'
import SimpleSelect from './forms/SimpleSelect'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import { DialogContentMUI, DialogContentMUIDesktop, DialogContentTextMUI, DialogMUI, DialogTitleMUIv2 } from './styled/dialog'
import { Help } from '@mui/icons-material'
import { darkTextColor, mainColor, textColor } from './styled/theme'
import { isMobile } from 'react-device-detect'

const ModalEventEntry = ({
    installation,
    selectedMaterial,
    setSelectedMaterial,
    openModalNewEvent,
    setOpenModalNewEvent,
}) => {
    const [eventDate, setEventDate] = useState(getDate(moment().valueOf(), 'YYYY-MM-DD'))
    const [idType, setIdType] = useState()
    const [comment, setComment] = useState()
    const [activeHelpContent, setActiveHelpContent] = useState(false)

    const dispatch = useDispatch()

    const {
        exploitation,
        variousMatSituations,
        matEventsTypes,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        matEventsTypes: store.MaterielReducer.matEventsTypes,
    }), shallowEqual)

    const materiel = selectedMaterial

    const saveEvent = () => {
        dispatch(MaterielAction.createMatEvent({
            eventDate: new Date(eventDate).getTime(),
            idType,
            comment,
            materielType: 'divers',
            idMateriel: materiel.id,
        })).then(() => {
            dispatch(MaterielAction.fetchMatEventsByExploitation(exploitation.idExploitation)).then(() =>
                window.history.back()
            )
        })
    }

    const getHelpContent = (isActive) => {
        if (isActive) {
            return (
                <Paper
                    role='dialog'
                    keepMounted
                    tabIndex={-1}
                    sx={{
                        bottom: 'calc(4.5vh + 126px)',
                        padding: '2vh',
                        position: 'absolute',
                        backgroundColor: 'white',
                        borderRadius: '28px',
                        color: textColor,
                        boxShadow: '0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026',
                    }}
                >
                    <Grid container>
                        <Grid item xs={12} sx={{
                            paddingBottom: '24px',
                            fontSize: '14px',
                            lineHeight: '20px',
                            letterSpacing: '0.25px'
                        }}>{i18n.contactAdmin}</Grid>
                        <Grid container item xs={12} flexDirection='row-reverse'>
                            <TextButton
                                item
                                xs={12}
                                noFullWidth
                                justifySelf= 'flex-end'
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    letterSpacing: '0.1px',
                                    fontWeight: 500,
                                }}
                                onClick={() => setActiveHelpContent(false)}
                            >
                                {i18n.close}
                            </TextButton>
                        </Grid>
                    </Grid>

                </Paper>
            )
        }
        return null
    }

    if (variousMatSituations.length) {
        return (
            <DialogMUI open={openModalNewEvent}
                keepMounted
                onClose={() => {
                    setOpenModalNewEvent(false)
                    setSelectedMaterial({})
                }}
            >
                <DialogTitleMUIv2 style={{ padding: '0 0 1.5vh 0' }}
                    onClick={() => {
                        setOpenModalNewEvent(false)
                        setSelectedMaterial({})
                    }}
                >
                    {installation.name || i18n.pointPrelevement } - {installation.code}
                </DialogTitleMUIv2>
                { isMobile ?
                    (
                        <DialogContentMUI style={{ width: '100%' }}>
                            <DialogContentTextMUI style={{ alignSelf: 'flex-start', padding: 0 }}>
                                {i18n.counter} <b>{materiel?.reference ?? ''}</b>
                            </DialogContentTextMUI>
                            <SimpleSelect
                                id='idType'
                                required
                                noNullValue
                                label={i18n.eventType}
                                value={idType}
                                onChange={(v) => setIdType(v)}
                                integerValue
                                options={matEventsTypes}
                                keyValue='id'
                            />
                            <TextField
                                fullWidth
                                id='eventDate'
                                label={i18n.date}
                                type='date'
                                required
                                variant='outlined'
                                value={moment(eventDate).format('YYYY-MM-DD')}
                                inputProps={{
                                    max: '9999-12-31'
                                }}
                                onChange={(e) => setEventDate(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                id='comment'
                                type='text'
                                variant='outlined'
                                label={i18n.comment}
                                placeholder={i18n.comment}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                multiline
                                rows={10}
                                rowsMax={Infinity}
                                InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                                sx={{ marginBottom: '16px' }}
                            />
                            <Grid container
                                alignItems='center'
                                justifyContent='center'
                                columnGap='5px'
                                flexWrap='nowrap'
                                sx={{
                                    fontWeight: 600,
                                    color: darkTextColor,
                                    lineHeight: '20px',
                                    letterSpacing: '0.1px'
                                }}
                            >
                                <Grid item className='clickable' onClick={() => setActiveHelpContent(!activeHelpContent)}>{i18n.question}</Grid>
                                <Help className= 'clickable' onClick={() => setActiveHelpContent(!activeHelpContent)} sx={{ fontSize: '24px', color: mainColor }}/>
                                {getHelpContent(activeHelpContent)}
                            </Grid>
                            <Grid container justifyContent='flex-end'>
                                <MainButton onClick={saveEvent} sx={{ width: '6vw' }} disabled={!comment || !eventDate || !idType}>
                                    {i18n.add}
                                </MainButton>
                            </Grid>
                        </DialogContentMUI>
                    )
                    :
                    (
                        <DialogContentMUIDesktop style={{ width: '100%' }}>
                            <DialogContentTextMUI style={{ alignSelf: 'flex-start', padding: 0 }}>
                                {i18n.counter} <b>{materiel?.reference ?? ''}</b>
                            </DialogContentTextMUI>
                            <SimpleSelect
                                id='idType'
                                required
                                noNullValue
                                label={i18n.eventType}
                                value={idType}
                                onChange={(v) => setIdType(v)}
                                integerValue
                                options={matEventsTypes}
                                keyValue='id'
                            />
                            <TextField
                                fullWidth
                                id='eventDate'
                                label={i18n.date}
                                type='date'
                                required
                                variant='outlined'
                                value={moment(eventDate).format('YYYY-MM-DD')}
                                inputProps={{
                                    max: '9999-12-31'
                                }}
                                onChange={(e) => setEventDate(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                id='comment'
                                type='text'
                                variant='outlined'
                                label={i18n.comment}
                                placeholder={i18n.comment}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                multiline
                                rows={10}
                                rowsMax={Infinity}
                                InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                                sx={{ marginBottom: '16px' }}
                            />
                            <Grid container
                                alignItems='center'
                                justifyContent='center'
                                columnGap='5px'
                                flexWrap='nowrap'
                                sx={{
                                    fontWeight: 'medium',
                                    color: darkTextColor,
                                    lineHeight: '20px',
                                    letterSpacing: '0.1px'
                                }}
                            >
                                <Grid item className='clickable' onClick={() => setActiveHelpContent(!activeHelpContent)}>{i18n.question}</Grid>
                                <Help className= 'clickable' onClick={() => setActiveHelpContent(!activeHelpContent)} sx={{ fontSize: '24px', color: mainColor }}/>
                                {getHelpContent(activeHelpContent)}
                            </Grid>
                            <Grid container justifyContent='center'>
                                <MainButton onClick={saveEvent} disabled={!comment || !eventDate || !idType}>
                                    {i18n.add}
                                </MainButton>
                            </Grid>
                        </DialogContentMUIDesktop>
                    )
                }
            </DialogMUI>
        )
    }
    return <LoadingCard />
}

ModalEventEntry.propTypes = {
    installation: PropTypes.instanceOf(PropTypes.shape({})),
    selectedMaterial: PropTypes.instanceOf(PropTypes.shape({})),
    setSelectedMaterial: PropTypes.func,
    openModalNewEvent: PropTypes.bool,
    setOpenModalNewEvent: PropTypes.func,
}

export default ModalEventEntry