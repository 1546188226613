module.exports = {
    RESET_INSTALLATION: 'RESET_INSTALLATION',
    RECEIVE_INSTALLATION: 'RECEIVE_INSTALLATION',
    RECEIVE_INSTALLATIONS: 'RECEIVE_INSTALLATIONS',
    RECEIVE_ALL_VARIOUS_MATERIELS: 'RECEIVE_ALL_VARIOUS_MATERIELS',
    RECEIVE_VARIOUS_MAT_SITUATIONS: 'RECEIVE_VARIOUS_MAT_SITUATIONS',
    RECEIVE_VARIOUS_MAT_TYPES: 'RECEIVE_VARIOUS_MAT_TYPES',
    RECEIVE_ALL_INSTALLATIONS_GEO: 'RECEIVE_ALL_INSTALLATIONS_GEO',
    RECEIVE_MAP_SITUATIONS: 'RECEIVE_MAP_SITUATIONS',
    RECEIVE_MAP_SITUATIONS_STATION: 'RECEIVE_MAP_SITUATIONS_STATION',
    RECEIVE_MAP_SITUATION_STATS: 'RECEIVE_MAP_SITUATION_STATS',
    RECEIVE_MAP_SITUATIONS_RESULTS: 'RECEIVE_MAP_SITUATIONS_RESULTS',
    RECEIVE_MAP_SITUATIONS_STATION_LAST: 'RECEIVE_MAP_SITUATIONS_STATION_LAST',
    RECEIVE_LINKED_STATIONS: 'RECEIVE_LINKED_STATIONS',
    RECEIVE_ALL_LINKED_STATIONS: 'RECEIVE_ALL_LINKED_STATIONS',
    STATION_TYPE_CONSTANT: {
        PIEZOMETRY: 1,
        PIEZOMETER: 1,
        piezometry: 1,
        piezometer: 1,

        HYDROMETRY: 2,
        hydrometry: 2,
        hydrometricStation: 2,

        QUALITY: 3,
        QUALITOMETER: 3,
        quality: 3,
        qualitometer: 3,

        PLUVIOMETRY: 4,
        pluviometry: 4,
        pluviometer: 4,

        PRODUCTION: 5,
        PRODUCTION_UNIT: 5,
        productionUnit: 5,

        DISTRIBUTION: 6,
        DISTRIBUTION_UNIT: 6,
        distributionUnit: 6,

        INSTALLATION: 7,
        installation: 7,

        contributor: 8,

        RESOURCE: 9,
        resource: 9,

        contact: 10,
    },
    POINT_STATUS: [
        { code: null, label: '' },
        { code: 1, label: 'Utilisé', color: '#B8F397' },
        { code: 2, label: 'Non utilisé', color: '#FFC03E' },
        { code: 3, label: 'Cloturé', color: '#001D3550' },
    ],
    POINT_STATUS_OBJ: {
        USED: 1,
        NOT_USED: 2,
        CLOSED: 3,
    }
}
