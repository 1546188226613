import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid } from '@mui/material'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import { hasValue } from '../../../utils/NumberUtil'
import HomeAction from '../../offline/actions/HomeAction'
import LoadingCard from '../components/cards/LoadingCard'
import { isNil, orderBy } from 'lodash'
import { LightCard } from '../components/styled/grid'
import { ReactComponent as PumpLogo } from '../../../ressources/static/svg/Pump.svg'
import { ReactComponent as CounterLogo } from '../../../ressources/static/svg/Counter.svg'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../referencials/materiels/constants/MaterielConstants'
import { isMobile } from 'react-device-detect'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { textColor } from '../components/styled/theme'
import ModalEventEntry from '../components/ModalEventEntry'

const EventsMaterielsApp = ({ match }) => {
    const [openModalNewEvent, setOpenModalNewEvent] = useState(false)
    const [selectedMaterial, setSelectedMaterial] = useState({})

    const dispatch = useDispatch()

    const idInstallation = useMemo(() => Number(match.params.id), [match])

    const {
        exploitation,
        installations,
        variousMateriels,
        variousMatSituations,
        variousMatTypes,
        codesSandre,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
        codesSandre: store.ReferencialReducer.codesSandre,
    }), shallowEqual)

    const installation = useMemo(() => installations.find((i) => i.id === idInstallation) || {}, [installations, idInstallation])

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(HomeAction.setTitle([{
            title: `${i18n.pointMateriels} ${installation.code ?? ''}`,
            href: `/consommation/point/${idInstallation}/events/materiels`,
        }]))
    }, [installation, idInstallation])

    const { materiels } = useMemo(() => {
        const pointPumps = variousMatSituations.filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation) || []
        const pointCounters = variousMatSituations.filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const idsPumps = pointPumps.map(({ idVarious }) => idVarious)
        const idsCounters = pointCounters.map(({ idVarious }) => idVarious)
        return {
            pumpsIds: idsPumps,
            countersIds: idsCounters,
            materiels: variousMateriels.filter((m) => [...idsPumps, ...idsCounters].includes(m.id) && (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode)),
        }
    }, [idInstallation, variousMateriels, variousMatSituations])

    if (isMobile && variousMatSituations.length) {
        return (
            <div style={{ padding: '8px 1.5rem' }}>
                <Grid container direction='column'>
                    {orderBy(materiels, ['counter', 'pump', 'reference']).map((m) => {
                        const matType = variousMatTypes.find((t) => t.materielType === m.materielType)
                        const isPump = !isNil(m?.pump)
                        const matCategory = codesSandre.find((c) => c.field === 'MAT.MOBILITE' && c.code === m.mobilityCode)
                        return (
                            <LightCard
                                key={m.id}
                                container
                                direction='column'
                                className='clickable'
                                onClick={() => dispatch(push(`/consommation/point/${idInstallation}/events/materiels/${m.id}/entries`))}
                                sx={{ marginBottom: '10px' }}
                            >
                                <Grid item container justifyContent='space-between' className='bold' fontSize={24}>
                                    <Grid item>
                                        {m.counter ? (
                                            <>
                                                <CounterLogo style={{ marginRight: 10 }} />
                                                {i18n.counter}
                                            </>
                                        ) : (
                                            <>
                                                <PumpLogo style={{ marginRight: 10 }} />
                                                {i18n.pump}
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item>{m?.reference ?? ''}</Grid>
                                </Grid>
                                {m?.name && <Grid item className='bold' sx={{ marginTop: '8px', fontSize: '14px' }}>{m.name}</Grid>}
                                <Grid item container justifyContent='space-between' sx={{ marginTop: '15px', fontSize: '14px' }}>
                                    <Grid item>{isPump ? i18n.nature : i18n.type}</Grid>
                                    <Grid item className='bold'>{matType ? matType.name : '-'}</Grid>
                                </Grid>
                                {isPump ? (
                                    <>
                                        <Grid item container justifyContent='space-between' sx={{ marginTop: '15px', fontSize: '14px' }}>
                                            <Grid item>{i18n.categ}</Grid>
                                            <Grid item className='bold'>{matCategory?.name ?? '-'}</Grid>
                                        </Grid>
                                        <Grid item container justifyContent='space-between' sx={{ marginTop: '15px', fontSize: '14px' }}>
                                            <Grid item>{i18n.debitFonctionnement}</Grid>
                                            <Grid item className='bold'>{m?.pump?.operatingFlow ?? '-'}</Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item container justifyContent='space-between' sx={{ marginTop: '15px', fontSize: '14px' }}>
                                            <Grid item>{i18n.mobility}</Grid>
                                            <Grid item className='bold'>{matCategory?.name ?? '-'}</Grid>
                                        </Grid>
                                    </>
                                )}
                            </LightCard>
                        )
                    }
                    )}
                </Grid>
            </div>
        )
    }

    const getMaterials = () => (
        orderBy(materiels, ['counter', 'pump', 'reference']).map((m) => {
            const matType = variousMatTypes.find((t) => t.materielType === m.materielType)
            const isPump = !isNil(m?.pump)
            const matCategory = codesSandre.find((c) => c.field === 'MAT.MOBILITE' && c.code === m.mobilityCode)
            return (
                <Grid
                    item
                    container
                    alignItems='stretch'
                    xs={3}
                >
                    <LightCard
                        key={m.id}
                        container
                        direction='column'
                        className='clickable'
                        sx={{
                            height: 'auto'
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpenModalNewEvent(true)
                            setSelectedMaterial(m)
                        }}
                    >
                        <Grid item container justifyContent='space-between' sx={{ fontSize: '24px', fontWeight: 'medium' }}>
                            <Grid item>
                                {m.counter ? (
                                    <>
                                        <CounterLogo style={{ marginRight: '10px' }} />
                                        {i18n.counter}
                                    </>
                                ) : (
                                    <>
                                        <PumpLogo style={{ marginRight: '10px' }} />
                                        {i18n.pump}
                                    </>
                                )}
                            </Grid>
                            <Grid item>{m?.reference ?? ''}</Grid>
                        </Grid>
                        {!!m?.name && <Grid item className='bold' sx={{ marginTop: '8px', fontSize: '14px' }}>{m.name}</Grid>}
                        <Grid item container justifyContent='space-between' sx={{ marginTop: '15px', fontSize: '14px' }}>
                            <Grid item>{isPump ? i18n.nature : i18n.type}</Grid>
                            <Grid item className='bold'>{matType ? matType.name : '-'}</Grid>
                        </Grid>
                        {isPump ? (
                            <>
                                <Grid item container justifyContent='space-between' sx={{ marginTop: '15px', fontSize: '14px' }}>
                                    <Grid item>{i18n.categ}</Grid>
                                    <Grid item className='bold'>{matCategory?.name ?? '-'}</Grid>
                                </Grid>
                                <Grid item container justifyContent='space-between' sx={{ marginTop: '15px', fontSize: '14px' }}>
                                    <Grid item>{i18n.debitFonctionnement}</Grid>
                                    <Grid item className='bold'>{m?.pump?.operatingFlow ?? '-'}</Grid>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item container justifyContent='space-between' sx={{ marginTop: '15px' }}>
                                    <Grid item>{i18n.mobility}</Grid>
                                    <Grid item className='bold'>{matCategory?.name ?? '-'}</Grid>
                                </Grid>
                            </>
                        )}
                    </LightCard>
                </Grid>
            )
        }
        )
    )

    if (materiels.length) {
        return (
            <Grid
                container
                direction='column'
                item
                xs={12}
                sx={{
                    padding: '5vh',
                    maxHeight: 'calc(100% - 4vh)',
                }}
            >
                <Grid container item paddingBottom='2.5vh' justifyContent='flex-start' color={textColor}>
                    <Grid item
                        sx={{ paddingRight: '1.5vw' }}
                        className='clickable'
                        onClick={() => window.history.back()}
                    >
                        <KeyboardArrowLeft sx={{ fontSize: '30px' }}/>
                    </Grid>
                    <Grid item sx={{ fontSize: '22px', lineHeight: '28px' }} >{i18n.selectConcernedMaterial}</Grid>
                </Grid>
                <Grid container spacing={2} sx={{ maxHeight: '100%', overflowY: 'auto' }}>
                    {getMaterials()}
                </Grid>
                {openModalNewEvent && <ModalEventEntry
                    installation={installation}
                    selectedMaterial={selectedMaterial}
                    openModalNewEvent={openModalNewEvent}
                    setOpenModalNewEvent={setOpenModalNewEvent}
                    setSelectedMaterial={setSelectedMaterial}
                />}
            </Grid>
        )
    }
    return <LoadingCard />
}

EventsMaterielsApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        })
    }),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
}

export default EventsMaterielsApp
