import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import DtoExploitation from '../../online/agri/dto/exploitation/DtoExploitation'
import { MainButton } from '../../online/components/styled/buttons'
import { formatPhone } from '../../../utils/StringUtil'
import { DialogContentMUIDesktop, DialogMUI, DialogTitleMUIv2 } from '../../online/components/styled/dialog'

const ModalFolderDesktop = ({ open, exploitation, onCancel, onValidate }) => (
    <DialogMUI
        open={open}
        keepMounted
        onClose={onCancel}
    >
        <DialogTitleMUIv2 onClick={onCancel}>
            <Grid container justifyContent='space-between' alignItems='center'>
                <b style={{ padding: 10, fontSize: 21 }}>{exploitation.codification} - {exploitation.name || ''}</b>
            </Grid>
        </DialogTitleMUIv2>
        <DialogContentMUIDesktop>
            {/* <Grid container item xs={12}>
                    {city.x && city.y && (
                        <LittleMap point={city}/>
                    )}
                </Grid> */}
            <Grid container item xs={8} direction='column' rowGap='1vh'>
                <Grid item container justifyContent='space-between'>
                    <Grid item>{i18n.structure}</Grid>
                    <Grid item className='bold'>{exploitation.structureType ?? '-'}</Grid>
                </Grid>
                <Grid item container justifyContent='space-between'>
                    <Grid item>{i18n.city}</Grid>
                    <Grid item className='bold'>{`${exploitation.city ?? ''} [${exploitation.inseeCode ?? '-'}]`}</Grid>
                </Grid>
                <Grid item container justifyContent='space-between'>
                    <Grid item>{i18n.address}</Grid>
                    <Grid item className='bold'>{exploitation.address ?? '-'}</Grid>
                </Grid>
                <Grid item container justifyContent='space-between'>
                    <Grid item>{i18n.phoneTel}</Grid>
                    <Grid item className='bold'>{exploitation.phoneTel ? formatPhone(exploitation.phoneTel) : '-'}</Grid>
                </Grid>
                <Grid item container justifyContent='space-between'>
                    <Grid item>{i18n.phoneMobile}</Grid>
                    <Grid item className='bold'>{exploitation.mobile ? formatPhone(exploitation.mobile) : '-'}</Grid>
                </Grid>
                <Grid item container justifyContent='space-between'>
                    <Grid item>{i18n.email}</Grid>
                    <Grid item className='bold'>{exploitation.email ?? '-'}</Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <MainButton onClick={onValidate}>
                    {i18n.accessFolder}
                </MainButton>
            </Grid>
        </DialogContentMUIDesktop>
    </DialogMUI>
)

ModalFolderDesktop.propTypes = {
    open: PropTypes.bool.isRequired,
    exploitation: PropTypes.instanceOf(DtoExploitation).isRequired,
    onCancel: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
}

export default ModalFolderDesktop