import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_DOCUMENTS_FILES,
    RECEIVE_DOCUMENTS_PICTURES,
    RESET_STATION,
} from '../constants/FileConstants'

import ApplicationConf from '../../../../../conf/ApplicationConf'
import {
    checkAuth,
    getAuthorization,
    getJson,
} from '../../../../../utils/ActionUtils'
import LogAction from '../../../../../utils/log/actions/LogAction'
import ToastrAction from '../../../components/toasters/ToastrAction'

const FileAction = {
    receivePictures(pictures) {
        return {
            type: RECEIVE_DOCUMENTS_PICTURES,
            pictures,
        }
    },
    fetchPictures(code, stationType) {
        return (dispatch) => {
            return fetch(ApplicationConf.picture.stationPicture(code, stationType), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then((json = []) => {
                    dispatch(FileAction.receivePictures(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.pictures} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.pictures)
                })
        }
    },
    receiveFiles(files) {
        return {
            type: RECEIVE_DOCUMENTS_FILES,
            files,
        }
    },
    fetchFiles(code, stationType) {
        return (dispatch) => {
            return fetch(ApplicationConf.files.stationFile(code, stationType), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then((json = []) => {
                    dispatch(FileAction.receiveFiles(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.files} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.files)
                })
        }
    },

    deleteFile(file) {
        return () => {
            return fetch(ApplicationConf.files.delete(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(file),
            })
                .then(getJson)
                .then((json) => {
                    if (json.delete === 1) {
                        ToastrAction.success(i18n.elementDeleteSuccess)
                    } else {
                        throw new Error()
                    }
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.deleteError + i18n.element} : ${err}`)
                    ToastrAction.error(i18n.deleteError + i18n.element)
                })
        }
    },

    updateFile(file) {
        return () => {
            return fetch(ApplicationConf.files.edit(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(file),
            })
                .then(getJson)
                .then((json) => {
                    if (json.update === 1) {
                        ToastrAction.success(i18n.elementUpdateSuccess)
                    } else {
                        throw new Error()
                    }
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.element} : ${err}`)
                    ToastrAction.error(i18n.updateError + i18n.element)
                })
        }
    },

    deleteFiles(files) {
        return () => {
            return Promise.all(files.map(file => {
                return fetch(ApplicationConf.files.delete(), {
                    method: 'POST',
                    headers: getAuthorization(),
                    body: JSON.stringify(file),
                }).then(checkAuth).then(getJson)
            }))
                .then(json => {
                    if (json[0].delete === 1) {
                        ToastrAction.success(i18n.elementDeleteSuccess)
                    } else {
                        throw new Error()
                    }
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.deleteError + i18n.element} : ${err}`)
                    ToastrAction.error(i18n.deleteError + i18n.element)
                })
        }
    },

    reset() {
        return {
            type: RESET_STATION,
        }
    },
}

export default FileAction
