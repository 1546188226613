/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { compact } from 'lodash'
import { Grid, useTheme } from '@mui/material'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import TankCard from '../components/cards/TankCard'
import ContactAction from '../contact/actions/ContactAction'
import { POINT_STATUS } from '../referencials/installations/constants/InstallationsConstants'
import { mainColor, veryLightColor } from '../components/styled/theme'
import LoadingCard from '../components/cards/LoadingCard'
import { LightCard } from '../components/styled/grid'
import { ArrowBack, Close, Done, EditOutlined } from '@mui/icons-material'
import { getI18nTitleDataLength } from '../../../utils/StringUtil'
import PumpCardDesktop from '../components/cards/PumpCardDesktop'
import CounterCardDesktop from '../components/cards/CounterCardDesktop'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import LocalizationCardDesktop from '../components/cards/LocalizationCardDesktop'
import AssociatedResourceCardDesktop from '../components/cards/AssociatedResourceCardDesktop'
import OuvrageCardDesktop from '../components/cards/OuvrageCardDesktop'
import useProgressDispatch from '../../../utils/customHooks/useProgressDispatch'
import AccordeonDesktop from '../components/AccordeonDesktop'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import { Input } from '../components/styled/inputs'
import { LINK_TYPE, SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../referencials/materiels/constants/MaterielConstants'
import { getTypePrel } from '../../../utils/AgriUtils'
import moment from 'moment'

const PointDetailsExploitDesktop = ({ match }) => {
    const {
        installation,
        variousMateriels,
        variousMatSituations,
        contacts,
        exploitation,
        codesSandre,
    } = useSelector(store => ({
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        contacts: store.ContactReducer.contacts,
        exploitation: store.AgriReducer.exploitation,
        codesSandre: store.ReferencialReducer.codesSandre,
    }), shallowEqual)

    const [editMode, setEditMode] = useState(false)
    const [pointName, setPointName] = useState()
    const [pump, setPump] = useState()
    const [counter, setCounter] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useDispatch()

    const theme = useTheme()

    const { isLoaded } = useProgressDispatch(() => {
        const promiseContacts = !contacts.length ? [
            dispatch(ContactAction.fetchContacts())
        ] : []

        return [
            dispatch(InstallationsAction.fetchInstallation(match.params.id)).then(res => setPointName(res.name)),
            ...promiseContacts,
        ]
    })

    useEffect(() => {
        return () => dispatch(InstallationsAction.resetInstallation())
    }, [])

    const linkPoint = useMemo(() => (
        exploitation.link_samplings ? exploitation.link_samplings.find((p) => p.idInstallation === installation.id) : null
    ), [exploitation, installation])

    const associatedResources = useMemo(() => {
        const res = linkPoint ? linkPoint?.link_samples[0] : {}
        return {
            ...res,
            managementCode: installation.managementCode,
            subManagementCode: installation.subManagementCode,
        }
    }, [linkPoint])

    const date = useMemo(() => moment().valueOf(), [])

    const getNature = () => {
        const typePrel = getTypePrel(associatedResources.sampleType)
        const codeFind = codesSandre.find((c) => c.field === typePrel && c.code === associatedResources.sampleNature)
        return codeFind ? codeFind.name : '-'
    }

    const getStatus = () => {
        if (linkPoint) {
            const status = POINT_STATUS.find(({ code }) => code === linkPoint.stateCode) || {}
            return status.label || '-'
        }
        return ''
    }

    if (!isLoaded) {
        return <LoadingCard />
    }

    const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === linkPoint?.idInstallation && (!m.situationEndDate || (m.situationEndDate > date)) && (!m.situationDate || (m.situationDate < date))) || []
    const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode) && (!m.situationEndDate || (m.situationEndDate > date)) && (!m.situationDate || (m.situationDate < date))) || []
    const nbTanks = linkPoint?.link_sampleTanks?.length || 0
    const dataMats = [...pointPumps, ...pointCounters].map(p => {
        const mat = variousMateriels.find(m => m.id === p.idVarious)
        const repartition = (exploitation.link_repartition_materiels.find(rep => rep.linkType === LINK_TYPE.REPART_COUNTER && p.siteType === SITU_PUMP_COUNTER && rep.idElement1 === p.siteCode && rep.idElement2 === p.idVarious) || {})[0]?.repartition
        return {
            ...mat,
            repartition
        }
    }).filter(mat => !mat.administrator || (mat.administrator === exploitation.operatorCode))
    const nbMats = dataMats.length || 0

    const savePointName = () => {
        if (editMode) {
            dispatch(InstallationsAction.updateInstallation({ ...installation, name: pointName })).then(() => {
                dispatch(InstallationsAction.fetchInstallation(match.params.id)).then(() => {
                    setEditMode(!editMode)
                })
            })
        } else {
            setEditMode(!editMode)
        }
    }

    const savePumpName = () => {
        setIsLoading(true)
        dispatch(MaterielAction.updateVariousMateriel(pump, () => {
            setIsLoading(false)
            setPump(null)
        }))
    }

    const saveCounterName = () => {
        setIsLoading(true)
        dispatch(MaterielAction.updateVariousMateriel(counter, () => {
            setIsLoading(false)
            setCounter(null)
        }))
    }

    return (
        <Grid
            container
            alignContent='start'
            sx={{ color: mainColor }}
        >
            <Grid container alignItems='center' item fontSize={24}>
                <ArrowBack className='clickable' sx={{ paddingRight: '1vw' }} onClick={() => window.history.back()} />
                {installation?.location}
            </Grid>
            <Grid container justifyContent='space-between' sx={{ paddingTop: theme.spacing(3) }}>
                {linkPoint && installation?.id ?
                    (
                        <LightCard
                            item
                            container
                            xs={2.8}
                            direction='column'
                            sx={{
                                padding: theme.spacing(4)
                            }}
                        >
                            <Grid item container justifyContent='space-between'>
                                <Grid item fontSize={22} sx={{ fontWeight: 'regular' }} >{i18n.descriptive}</Grid>
                                {!editMode ?
                                    (<EditOutlined
                                        className='clickable'
                                        onClick={() => savePointName()}
                                    />)
                                    :
                                    (
                                        <Grid item>
                                            <Close className='clickable' onClick={() => setEditMode(false)}/>
                                            <Done className='clickable' onClick={() => savePointName()} />
                                        </Grid>
                                    )
                                }
                            </Grid>
                            <Grid container direction='column' sx={{ paddingTop: theme.spacing(2) }}>
                                <Grid item container justifyContent='space-between' alignItems='center'>
                                    <Grid item>{i18n.name}</Grid>
                                    {!editMode ?
                                        (<Grid item sx={{ fontWeight: 'medium' }}>{installation?.name || '-'}</Grid>)
                                        :
                                        (<Input
                                            value={pointName}
                                            onKeyDown={(e) => e.key === 'Enter' ? savePointName() : null}
                                            onChange={(e) => setPointName(e.target.value)}
                                            variant='standard'
                                            style={{ width: '50%' }}
                                            inputProps={{ sx: { padding: '0 5px', height: '19px' } }}
                                        />)
                                    }
                                </Grid>
                                <Grid item container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                                    <Grid item>{i18n.code}</Grid>
                                    <Grid item sx={{ fontWeight: 'medium' }}>{installation?.code || '-'}</Grid>
                                </Grid>
                                <Grid item container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                                    <Grid item>{i18n.status}</Grid>
                                    <Grid item sx={{ fontWeight: 'medium' }}>{getStatus()}</Grid>
                                </Grid>
                                <Grid item container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                                    <Grid item>{i18n.nature}</Grid>
                                    <Grid item sx={{ fontWeight: 'medium' }}>{getNature()}</Grid>
                                </Grid>
                            </Grid>
                        </LightCard>
                    )
                    :
                    <LoadingCard xs={2.8} sx={{ backgroundColor: veryLightColor, padding: theme.spacing(4) }} />
                }
                <LocalizationCardDesktop data={installation} xs={2.8}/>
                <AssociatedResourceCardDesktop data={associatedResources} xs={2.8}/>
                {linkPoint ?
                    linkPoint?.link_sampleCasings?.map((ouvrage) => (
                        <OuvrageCardDesktop
                            title={i18n.struct}
                            ouvrage={ouvrage}
                            theme={theme}
                            newStyle
                            hideIcon
                            xs={2.8}
                        />
                    ))
                    :
                    <LoadingCard xs={2.8} sx={{ padding: theme.spacing(4), backgroundColor: veryLightColor }}/>
                }
            </Grid>
            <Grid
                container
                item
                columnSpacing={4}
                sx={{
                    overflow: 'hidden',
                    paddingTop: theme.spacing(3)
                }}
             >
                <Grid item container xs={6}>
                    {!!variousMatSituations.length && !!variousMateriels.length ?
                        (
                            <AccordeonDesktop bodyColor={veryLightColor} sx={{ width: '100%' }} title={`${getI18nTitleDataLength(i18n.materiel, i18n.materiels, nbMats)} (${nbMats})`}>
                                {compact(dataMats.map((d) => {
                                    if (d.pump) {
                                        return (
                                            <Grid item sx={{ padding: '0 24px' }}>
                                                <PumpCardDesktop
                                                    pump={{ ...d, ...d.pump }}
                                                    noLightCard
                                                    setPump={(p) => setPump(p)}
                                                    statePump={pump}
                                                    onClick={() => savePumpName()}
                                                    isLoading={isLoading && pump?.id === d?.id}
                                                    hideIcon={!!counter}
                                                />
                                            </Grid>
                                        )
                                    }
                                    return (
                                        <Grid item sx={{ padding: '0 24px 24px' }}>
                                            <CounterCardDesktop
                                                counter={{ ...d, ...d.counter }}
                                                noLightCard
                                                setCounter={(c) => setCounter(c)}
                                                stateCounter={counter}
                                                onClick={() => saveCounterName()}
                                                isLoading={isLoading && counter?.id === d?.id}
                                                hideIcon={!!pump}
                                            />
                                        </Grid>
                                    )
                                }))}
                            </AccordeonDesktop>
                        )
                        :
                        <LoadingCard sx={{ backgroundColor: veryLightColor, padding: theme.spacing(3) }}/>
                    }
                </Grid>
                <Grid item container xs={6}>
                    {linkPoint ?
                        (
                            <AccordeonDesktop bodyColor={veryLightColor} sx={{ width: '100%' }} title={`${getI18nTitleDataLength(i18n.waterRetention, i18n.waterRetentions, nbTanks)} (${nbTanks})`}>
                                {linkPoint?.link_sampleTanks.map((retenue) => (
                                    <Grid item sx={{ padding: '0 24px' }}>
                                        <TankCard
                                            retenue={retenue}
                                            noLightCard
                                        />
                                    </Grid>
                                ))}
                            </AccordeonDesktop>
                        )
                        :
                        <LoadingCard sx={{ backgroundColor: veryLightColor, padding: theme.spacing(3) }}/>

                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

PointDetailsExploitDesktop.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
}

export default PointDetailsExploitDesktop
