'use strict'
import {
    CITIES,
    RESET_CITY,
    RECEIVE_CITIES_HISTO,
} from '../constants/CityConstants'
import DtoCity from '../dto/DtoCity'
import DtoCityHisto from '../dto/DtoCityHisto'
import { createIndex } from '../../../../../utils/StoreUtils'

export const store = {
    cities: [],
    citiesHisto: [],
    citiesIndex: {},
}

export function CityReducer(state = {}, action) {
    switch (action.type) {
        case CITIES:
            const citiesDto = action.cities.map((city) => new DtoCity(city))
            const cities = citiesDto.filter((city) => city.id)
            return {
                ...state,
                cities,
                citiesIndex: createIndex(cities),
            }
        case RESET_CITY:
            return {
                ...state,
                city: {},
            }
        case RECEIVE_CITIES_HISTO:
            return {
                ...state,
                citiesHisto: action.citiesHisto.map((c) => new DtoCityHisto(c)),
            }
        default:
            return state
    }
}
