import React from 'react'
import { Typography, styled, Tooltip } from '@mui/material'
import { withStyles } from '@mui/styles'

export const SubTitleCard = styled((props) => (
    <Typography
        sx={{
            backgroundColor: 'lightgray',
            border: props.noBorder ? 'none' : '1px solid grey',
            width: 'auto',
            margin: props.margin || '0px -8px',
            padding: props.padding || '8px',
            display: 'flex',
            justifyContent: props.left ? 'left' : 'center',
            fontWeight: props.bold ? 'bold;' : 'auto',
        }}
        {...props}
    />
))({})

export const HelpTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '1rem',
        lineHeight: '25px',
    },
}))(Tooltip)
