import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { Grid, useTheme } from '@mui/material'
import DtoCity from '../../referencials/city/dto/DtoCity'
import { LightCard } from '../styled/grid'
import LoadingCard from './LoadingCard'
import { veryLightColor } from '../styled/theme'

const LocalizationCardDesktop = ({ title, data, xs=12 }) => {
    const {
        citiesIndex,
        cities,
    } = useSelector(store => ({
        citiesIndex: store.CityReducer.citiesIndex,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const getSelectedItem = () => {
        const city = citiesIndex[data.townCode]
        return city || {}
    }

    const theme = useTheme()

    if (cities.length) {
        const city = getSelectedItem()
        return (
            <LightCard
                item
                container
                xs={xs}
                direction='column'
                sx={{
                    padding: theme.spacing(4)
                }}
            >
                <Grid item fontSize={22} sx={{ fontWeight: 'regular' }}>{title ||i18n.localisation}</Grid>
                <Grid container direction='column' sx={{ paddingTop: theme.spacing(3) }}>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.city}</Grid>
                        <Grid item sx={{ fontWeight: 'medium' }}>{city?.name || '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                        <Grid item>{i18n.lieuDit}</Grid>
                        <Grid item sx={{ fontWeight: 'medium' }}>{data?.location || '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                        <Grid item>{i18n.codeParcelle}</Grid>
                        <Grid item sx={{ fontWeight: 'medium' }}>{data?.link_geo?.length ? `${data.link_geo[0].parcel} ${data.link_geo[0].section}` : '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                        <Grid item>{i18n.xLambert}</Grid>
                        <Grid item sx={{ fontWeight: 'medium' }}>{data?.x || '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                        <Grid item>{i18n.yLambert}</Grid>
                        <Grid item sx={{ fontWeight: 'medium' }}>{data?.y || '-'}</Grid>
                    </Grid>
                </Grid>
            </LightCard>
        )
    }
    return <LoadingCard xs={2.8} sx={{ backgroundColor: veryLightColor, padding: theme.spacing(4) }}/>
}

LocalizationCardDesktop.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.instanceOf(PropTypes.shape({})),
    styleContainer: PropTypes.instanceOf(PropTypes.shape({})),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    cities: PropTypes.instanceOf(PropTypes.instanceOf(DtoCity)),
    xs: PropTypes.number,
}

export default LocalizationCardDesktop