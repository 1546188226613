import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { groupBy, range } from 'lodash'
import DtoExploitation from '../../online/agri/dto/exploitation/DtoExploitation'
import { MainButton } from '../../online/components/styled/buttons'
import LittleMap from '../../online/points/LittleMap'
import { isLargeWindow } from '../../../utils/LocalStorageUtils'
import { formatPhone } from '../../../utils/StringUtil'
import { Container, Item } from '../../online/components/styled/grid'
import DtoInstallationGeo from '../../online/referencials/installations/dto/DtoInstallationGeo'
import DtoWaterTurn from '../../online/agri/dto/DtoWaterTurn'
import DtoWaterTurnRestriction from '../../online/agri/dto/DtoWaterTurnRestriction'
import { getDayDisplay, getTextSlot } from '../../../utils/AgriUtils'
import { getWeek } from '../../../utils/DateUtil'
import DtoWaterTurnsSlot from '../../online/agri/dto/DtoWaterTurnsSlot'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../online/components/styled/dialog'
import { ReactComponent as CloseLogo } from '../../../ressources/static/svg/Fermer.svg'

function ModalPoint({ open, point, exploitations, pointWaterTurns, waterTurnsRestrictions, onCancel, onValidate, citiesIndex, waterTurnsSlots }) {
    const city = citiesIndex[point.townCode] || {}
    return (
        <DialogMUI open={open}>
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <b style={{ padding: 10, fontSize: 21 }}>{i18n.point} {point.code || ''}</b>
                    <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={onCancel} />
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Container
                    style={{
                        height: isLargeWindow() ? '-webkit-fill-available' : 'auto',
                    }}>
                    <Container item xs={12} md={6} style={{ marginBottom: 8 }}>
                        <Item xs={12}>
                            <p><b>{point.name || ''}</b></p>
                            <p>{point.parcel && point.section ? `${point.parcel} ${point.section}` : ''}</p>
                            <p>{city ? city.labelWithCode : ''}</p>
                            <p>{point.address || ''}</p>
                            <p>{point.location || ''}</p>
                        </Item>
                        {exploitations.map((e) => {
                            const data = pointWaterTurns.filter((w) => w.idExploitation === e.idExploitation && w.idInstallation === point.id)
                            const groupedSlotData = groupBy(data, 'idSlot')
                            return (
                                <Item xs={12} style={{ height: 'fit-content', padding: 4, border: '1px solid grey', borderRadius: 5 }}>
                                    <Grid container justifyContent='space-between' alignItems='center'>
                                        <b>{e.codification || ''}</b>
                                        <Grid item xs='auto'>
                                            <MainButton onClick={() => onValidate(e.idExploitation)}>
                                                {i18n.accessFolder}
                                            </MainButton>
                                        </Grid>
                                    </Grid>
                                    <p>{e.name || ''}</p>
                                    <p>{e.structureType || ''}</p>
                                    <p>{e.siret || ''}</p>
                                    <p>{e.email || ''}</p>
                                    <p>{formatPhone(e.phoneTel || '')}</p>
                                    <p>{formatPhone(e.mobile || '')}</p>
                                    {Object.keys(groupedSlotData).map(idSlotKey => {
                                        const slot = waterTurnsSlots.find(wts => wts.id === parseInt(idSlotKey)) || { nbSlots: 1, id: -1 }
                                        return (
                                            <>
                                                <p><b>{i18n.ranges} : {getTextSlot(slot)}</b></p>
                                                {groupedSlotData[idSlotKey].map(d => {
                                                    const slotsDisplay = range(slot.nbSlots || 1)
                                                    return (
                                                        <>
                                                            <div style={{ overflowX: 'auto', paddingBottom: 8 }}>
                                                                {data ? getWeek().map((day) => (
                                                                    getDayDisplay(d[day.value], day.label, slotsDisplay, waterTurnsRestrictions)
                                                                )) : i18n.noInformation}
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </>
                                        )
                                    }).flat()}
                                </Item>
                            )
                        })}
                    </Container>
                    <Item xs={12} md={6} style={{ height: isLargeWindow() ? 500 : 200, padding: 4 }}>
                        {point.x && point.y && (
                            <LittleMap point={point} height={isLargeWindow() ? 500 : 200} />
                        )}
                    </Item>
                </Container>
            </DialogContentMUI>
        </DialogMUI>
    )
}

ModalPoint.propTypes = {
    open: PropTypes.bool.isRequired,
    point: PropTypes.instanceOf(DtoInstallationGeo).isRequired,
    exploitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoExploitation)).isRequired,
    pointWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)).isRequired,
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    waterTurnsSlots: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsSlot)),
    onCancel: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
}

const mapStateToProps = (store) => {
    return {
        citiesIndex: store.CityReducer.citiesIndex,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        applicationSettings: store.HomeReducer.applicationSettings,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
    }
}

export default connect(mapStateToProps)(ModalPoint)