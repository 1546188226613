/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { Close, Done, EditOutlined } from '@mui/icons-material'
import { LightCard } from '../styled/grid'
import DtoVariousMateriel from '../../referencials/installations/dto/DtoVariousMateriel'
import LoadingCard from './LoadingCard'
import moment from 'moment'
import { compact } from 'lodash'
import { hasValue } from '../../../../utils/NumberUtil'
import { InputRow } from '../styled/inputs'
import MaterielAction from '../../referencials/materiels/actions/MaterielAction'
import AgriAction from '../../agri/actions/AgriAction'
import { MAT_CATEGORY } from '../../agri/constants/AgriConstants'

const PumpCardDecla = ({ pump, noLightCard, sx, match }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [updatedPump, setUpdatedPump] = useState(pump)
    const [editMode, setEditMode] = useState(false)

    const {
        variousMatTypes,
        codesSandre,
        declaration,
        variousMateriels,
    } = useSelector(store => ({
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
        codesSandre: store.ReferencialReducer.codesSandre,
        declaration: store.AgriReducer.declaration,
        variousMateriels: store.InstallationsReducer.variousMateriels,
    }), shallowEqual)

    const getLinkPumpInstallation = useMemo(() => (
        compact(declaration?.link_declarationInstallation?.map(instal => instal.idInstallation === Number(match.params.id) ?
            compact(instal?.link_equipments.map(equip => !equip.idMatAttachment && equip.mode !== 'd' && equip.idMat === updatedPump.id ? equip : null))
            :
            null
        ))

    ), [declaration, updatedPump])
    /* ?.link_equipements?.map(equip => !equip.idMatAttachment && equip.mode != 'd' && equip.idMat === updatedPump.id ? equip : null))
            ?.link_equipments?.map(equip => !equip.idMatAttachment && equip.idMat === updatedPump.id)
    const getLinkPumpCounter = useMemo(() => (
        declaration.link_declarationInstallation.find(instal => instal.idInstallation === Number(match.params.id))
            ?.link_equipments.find(equip => equip.idMatAttachment === updatedPump.id)
    ), [declaration, updatedPump]) */
    /*
    const getIdCounter = useMemo(() => (
        variousMateriels.find(mat => mat.id === getLinkPumpCounter.idMat)?.reference ?? '-'
    ), [variousMateriels, getLinkPumpCounter]) */

    const dispatch = useDispatch()

    const pumpNature = useMemo(() => variousMatTypes.find((t) => t.materielType === updatedPump.materielType), [updatedPump])
    const pumpCategories = useMemo(() => codesSandre.filter(c => c.field === 'MAT.MOBILITE'))
    const pumpCategory = useMemo(() => pumpCategories.find(c => c.code === updatedPump.mobilityCode), [updatedPump])
    const pumpTypes = useMemo(() => codesSandre.filter(c => c.field === 'POMPES.TYPE'), [codesSandre])
    const pumpType = useMemo(() => pumpTypes.find(t => t.code === updatedPump.pumpType), [updatedPump])

    const updatePump = () => {
        setIsLoading(true)
        dispatch(MaterielAction.updateVariousMateriel({ ...variousMateriels.find(m => m.id === updatedPump.id), name: updatedPump.name }, () => {
            dispatch(AgriAction.updateDeclaration({
                ...declaration,
                link_pumps: [
                    ...declaration.link_pumps.filter(p => p.id !== updatedPump.id),
                    {
                        ...updatedPump,
                        idExploitation: declaration.idExploitation,
                        idSurvey: declaration.idSurvey,
                        idInstallation: Number(match.params.id),
                    }
                ]
            })).then(() => {
                setIsLoading(false)
            })
        }))
    }

    const content = () => {
        if (isLoading) {
            return <LoadingCard />
        }
        return (
            <Grid container direction='column' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px' }}>
                <Grid container rowGap='1vh'>
                    <Grid item container justifyContent='space-between' alignItems='center'>
                        <Grid item sx={{ fontSize: '18px' }}><b>{i18n.pump}</b><br/>{updatedPump?.name} {updatedPump?.reference}</Grid>
                        <EditOutlined className='clickable' onClick={() => setEditMode(true)}/>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.type}</Grid>
                        <Grid item className='bold'>{pumpType?.name || '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.categ}</Grid>
                        <Grid item className='bold'>{pumpCategory?.name || '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.nature}</Grid>
                        <Grid item className='bold'>{pumpNature?.name || '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.assignementDate}</Grid>
                        <Grid item className='bold'>{getLinkPumpInstallation?.startDate ? moment(getLinkPumpInstallation.startDate).format('DD/MM/YYYY') : '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.decommisionDate}</Grid>
                        <Grid item className='bold'>{getLinkPumpInstallation?.endDate ? moment(getLinkPumpInstallation.endDate).format('DD/MM/YYYY') : '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.debitMax}</Grid>
                        <Grid item className='bold'>{updatedPump?.maxFlow ?? '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.debitFonctionnement}</Grid>
                        <Grid item className='bold'>{updatedPump?.operatingFlow ?? '-'}</Grid>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>{i18n.sharedEquipment}</Grid>
                        <Grid item className='bold'>{updatedPump?.isShared ? i18n.yes : i18n.no}</Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const editableContent = () => (
        <Grid container sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px', rowGap: '1.5vh' }}>
            <Grid item container justifyContent='space-between' alignItems='center'>
                <Grid item sx={{ fontSize: '18px' }}><b>{i18n.pump}</b> {updatedPump?.reference}</Grid>
                <Grid item>
                    <Close className='clickable'
                        onClick={() => {
                            setEditMode(false)
                            setUpdatedPump(pump)
                        }}
                    />
                    <Done className='clickable' onClick={() => {
                        setEditMode(false)
                        updatePump()
                    }} />
                </Grid>
            </Grid>
            <InputRow
                label={i18n.name}
                value={updatedPump.name}
                onChange={(e) => setUpdatedPump({
                    ...updatedPump,
                    name: e.target.value
                })}
            />
            <FormControl fullWidth>
                <InputLabel id={i18n.type}>{i18n.type}</InputLabel>
                <Select
                    labelId={i18n.type}
                    label={i18n.type}
                    value={updatedPump.pumpType}
                    onChange={(e) => setUpdatedPump({ ...updatedPump, pumpType: hasValue(e.target.value) ? Number(e.target.value) : null })}
                >
                    {
                        pumpTypes.map(type => (
                            <MenuItem
                                value={type.code}
                                style={{ maxWidth: '100%' }}
                            >
                                {type.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id={i18n.categ}>{i18n.categ}</InputLabel>
                <Select
                    labelId={i18n.categ}
                    label={i18n.categ}
                    value={updatedPump.mobilityCode}
                    onChange={(e) => setUpdatedPump({ ...updatedPump, mobilityCode: hasValue(e.target.value) ? Number(e.target.value) : null })}
                >
                    {
                        pumpCategories.map(cat => (
                            <MenuItem
                                value={cat.code}
                                style={{ maxWidth: '100%' }}
                            >
                                {cat.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id={i18n.nature}>{i18n.nature}</InputLabel>
                <Select
                    labelId={i18n.nature}
                    label={i18n.nature}
                    value={updatedPump.materielType}
                    onChange={(e) => setUpdatedPump({ ...updatedPump, materielType: hasValue(e.target.value) ? Number(e.target.value) : null })}
                >
                    {
                        variousMatTypes.map(type => type.category === MAT_CATEGORY.PUMP && (
                            <MenuItem
                                value={type.materielType}
                                style={{ maxWidth: '100%' }}
                            >
                                {type.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <Grid container item xs={12} justifyContent='space-between'>
                <Grid item xs={5.9}>
                    <InputRow
                        type='date'
                        disabled
                        label={i18n.assignementDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={moment(getLinkPumpInstallation?.startDate).format('YYYY-MM-DD')}
                        onChange={(e) => setUpdatedPump({
                            ...updatedPump,
                            name: e.target.value
                        })}
                    />
                </Grid>
                <Grid item xs={5.9}>
                    <InputRow
                        type='date'
                        disabled
                        label={i18n.decommisionDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={moment(getLinkPumpInstallation?.endDate).format('YYYY-MM-DD')}
                        onChange={(e) => setUpdatedPump({
                            ...updatedPump,
                            name: e.target.value
                        })}
                    />
                </Grid>
            </Grid>
            <InputRow
                type='number'
                label={i18n.debitMax}
                value={updatedPump.maxFlow}
                onChange={(e) => setUpdatedPump({
                    ...updatedPump,
                    maxFlow: Number(e.target.value)
                })}
            />
            <InputRow
                type='Number'
                label={i18n.debitFonctionnement}
                value={updatedPump.operatingFlow}
                onChange={(e) => setUpdatedPump({
                    ...updatedPump,
                    operatingFlow: Number(e.target.value)
                })}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        value={updatedPump?.isShared || null}
                        checked={updatedPump.isShared}
                        onChange={() => setUpdatedPump({ ...updatedPump, isShared: !updatedPump.isShared })}
                    />
                }
                label={i18n.sharedEquipment}
            />
            <InputRow
                label={i18n.comment}
                value={updatedPump.comment}
                multiline
                rows={4}
                onChange={(e) => setUpdatedPump({
                    ...updatedPump,
                    comment: e.target.value ?? null
                })}
            />
        </Grid>
    )

    if (noLightCard) {
        return editMode ? editableContent() : content()
    }

    return (
        <LightCard
            item
            container
            direction='column'
            justifyContent='space-between'
            className='clickable'
            sx={{ marginBottom: '20px', ...sx }}
        >
            {editMode ? editableContent() : content()}
        </LightCard>
    )
}

PumpCardDecla.propTypes = {
    pump: PropTypes.instanceOf(DtoVariousMateriel).isRequired,
    statePump: PropTypes.instanceOf(DtoVariousMateriel).isRequired,
    isLoading: PropTypes.bool.isRequired,
    setPump: PropTypes.func.isRequired,
    noLightCard: PropTypes.bool,
    sx: PropTypes.shape({}),
    onClick: PropTypes.func,
    hideIcon: PropTypes.bool.isRequired,
    match: PropTypes.instanceOf(PropTypes.shape({})),
}

export default PumpCardDecla
