import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, TextField } from '@mui/material'
import { MainButton } from '../../components/styled/buttons'
import { DialogContentMUIDesktop, DialogContentTextMUI, DialogMUI, DialogTitleMUIv2 } from '../../components/styled/dialog'

const ModalUpdatePasswordDesktop = ({
    open,
    toggleModal,
    handleChangeMdp,
    handleChangeNewMdp,
    handleChangeConfirmation,
    onSavePassword,
    firstLogin = false,
    title,
    label,
}) => (
    <DialogMUI
        open={open}
        keepMounted
        onClose={toggleModal}
    >
        {
            firstLogin ?
                (
                    <>
                        <DialogTitleMUIv2 onClick={toggleModal}>
                            {title || i18n.firstLogin}
                        </DialogTitleMUIv2>
                        <DialogContentMUIDesktop>
                            <DialogContentTextMUI style={{ alignSelf: 'flex-start' }}>
                                {label || i18n.pleaseChangePassword}
                            </DialogContentTextMUI>
                        </DialogContentMUIDesktop>
                    </>
                ) : (
                    <>
                        <DialogTitleMUIv2 onClick={toggleModal}>
                            {i18n.changePasswordTitle}
                        </DialogTitleMUIv2>
                        <DialogContentMUIDesktop style={{ width: '100%' }}>
                            <TextField
                                item
                                id='currentPassword'
                                label={i18n.currentPassword}
                                type='password'
                                onChange={handleChangeMdp}
                                variant='outlined'
                                style={{ width: '100%' }}
                            />
                        </DialogContentMUIDesktop>
                    </>
                )
        }
        <DialogContentMUIDesktop style={{ width: '100%' }}>
            <TextField
                item='true'
                id='neewPassword'
                label={i18n.newPassword}
                type='password'
                onChange={handleChangeNewMdp}
                variant='outlined'
                style={{ width: '100%' }}
            />
            <TextField
                item='true'
                id='newPasswordConfirm'
                label={i18n.confirmation}
                type='password'
                onChange={handleChangeConfirmation}
                variant='outlined'
                style={{ width: '100%' }}
            />
            <Grid container alignItems='center' justifyContent='flex-end'>
                <Grid item>
                    <MainButton
                        onClick={onSavePassword}
                        sx={{ margin: 0 }}
                    >
                        {i18n.changePassword}
                    </MainButton>
                </Grid>
            </Grid>
        </DialogContentMUIDesktop>
    </DialogMUI>
)

ModalUpdatePasswordDesktop.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSavePassword: PropTypes.func.isRequired,
    handleChangeMdp: PropTypes.func,
    handleChangeNewMdp: PropTypes.func,
    handleChangeConfirmation: PropTypes.func,
    firstLogin: PropTypes.bool,
    title: PropTypes.string,
    label: PropTypes.string,
}

export default ModalUpdatePasswordDesktop