/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DtoTankDeclaration from '../../agri/dto/enquete/DtoTankDeclaration'
import DtoTankExploit from '../../agri/dto/exploitation/DtoTankExploit'
import { formatMilliers } from '../../../../utils/StringUtil'
import { hasValue } from '../../../../utils/NumberUtil'
import { LightCard } from '../styled/grid'
import { InputRow } from '../styled/inputs'
import AgriAction from '../../agri/actions/AgriAction'
import DtoSamplingPointDecla from '../../agri/dto/exploitation/DtoSamplingPointDecla'
import { getLogin } from '../../../../utils/UserUtils'
import { TANK_FILLING_PERIOD } from '../../agri/constants/AgriConstants'
import { textColor } from '../styled/theme'
import { Close, Done, EditOutlined } from '@mui/icons-material'
import LoadingCard from './LoadingCard'

const TankCard = ({ title, retenue, noLightCard, sx, match }) => {
    const [isEditable, setIsEditable] = useState(false)
    const [updatedRetenue, setUpdatedRetenue] = useState(retenue)
    const [isLoading, setIsLoading] = useState(false)

    const {
        codesSandre,
        agriTanksTypes,
        declaration,
    } = useSelector(store => ({
        codesSandre: store.ReferencialReducer.codesSandre,
        agriTanksTypes: store.ReferencialReducer.agriTanksTypes,
        declaration: store.AgriReducer.declaration
    }), shallowEqual)

    const dispatch = useDispatch()

    const seasons = useMemo(() => codesSandre.filter((c) => c.field === 'USAGES.PERIODES_DETAILS'))

    const fillingModes = useMemo(() => codesSandre.filter((c) => c.field === 'PREL_AGRI.MODE_REMPLISSAGE'), [codesSandre])
    const fillingPeriodes = useMemo(() => codesSandre.filter((c) => c.field === 'PREL_AGRI.PERIODE_REMPLISSAGE'), [codesSandre])
    const tanksPeriodesDetails = useMemo(() => codesSandre.filter((code) => code.field === 'USAGES.PERIODES_DETAILS'), [codesSandre])

    const tankSaisonnal = useMemo(() => (updatedRetenue.link_periodes || []).find((p) => p.idPeriode === TANK_FILLING_PERIOD.SEASONAL), [updatedRetenue])
    const tankAnnuel = useMemo(() => (updatedRetenue.link_periodes || []).find((p) => p.idPeriode === TANK_FILLING_PERIOD.ANNUAL), [updatedRetenue])
    const tankUnknown = useMemo(() => (updatedRetenue.link_periodes || []).find((p) => p.idPeriode === TANK_FILLING_PERIOD.UNKNOWN), [updatedRetenue])
    const fillingPeriod = useMemo(() => tankSaisonnal ? 2 : (tankAnnuel ? 1 : (hasValue(tankUnknown) ? 0 : null)), [updatedRetenue, tankSaisonnal, tankAnnuel, tankUnknown])

    const tankType = useMemo(() => agriTanksTypes.find((type) => type.id === updatedRetenue.tankType), [updatedRetenue, agriTanksTypes])
    const fillingMode = useMemo(() => fillingModes.find((mode) => mode.code === updatedRetenue.fillingMode), [updatedRetenue, fillingModes])
    const fillingPeriode = useMemo(() => fillingPeriodes.find((c) => c.code === fillingPeriod) || {}, [fillingPeriod, fillingPeriodes])

    const handleSeasonChange = (season) => {
        let newTankPeriodes = []
        if (updatedRetenue.link_periodes.find((p) => p.idNbPeriode === Number(season))) {
            newTankPeriodes = [
                ...updatedRetenue.link_periodes,
            ]
            newTankPeriodes = newTankPeriodes.filter((p) => p.idNbPeriode !== null && p.idNbPeriode !== Number(season))
        } else {
            newTankPeriodes = [
                ...updatedRetenue.link_periodes,
                {
                    ...updatedRetenue.link_periodes[0],
                    idNbPeriode: Number(season),
                },
            ]
            newTankPeriodes = newTankPeriodes.filter((p) => p.idNbPeriode !== null)
        }
        if (!newTankPeriodes.length) {
            newTankPeriodes.push({
                idTank: updatedRetenue.idTank || -1,
                idPeriode: 2,
                idNbPeriode: null,
                idSurvey: updatedRetenue.idSurvey
            })
        }
        setUpdatedRetenue({ ...updatedRetenue, link_periodes: newTankPeriodes })
    }

    const point = useMemo(() => declaration
        ? declaration.link_declarationInstallation.find(
            (p) => p.idInstallation === Number(match.params.id),
        )
        : new DtoSamplingPointDecla({}), [declaration])

    const handleRetentionChange = () => {
        const updatedDeclaration = {
            ...declaration,
            link_declarationInstallation: [
                ...declaration.link_declarationInstallation.filter((p) => p.idInstallation !== Number(match.params.id)),
                {
                    ...point,
                    link_sampleTanks: [
                        ...point.link_sampleTanks.filter((t) => t.idTank !== retenue.idTank),
                        {
                            ...updatedRetenue,
                            idSurvey: declaration.idSurvey,
                            idExploitation: declaration.idExploitation,
                            idInstallation: point.idInstallation,
                            link_periodes: (updatedRetenue.link_periodes || []).map((l) => {
                                return {
                                    ...l,
                                    idSurvey: declaration.idSurvey,
                                    updateLogin: getLogin(),
                                }
                            }),
                        }]
                },
            ],
        }
        dispatch(AgriAction.updateDeclaration(updatedDeclaration)).then(() => {
            setIsEditable(false)
            setIsLoading(false)
        })
    }

    const getSelectedPeriod = (idPeriode, tankPeriodes) => {
        return !!tankPeriodes.find((p) => p.idNbPeriode === idPeriode)
    }

    let saisons = ''
    if (tankSaisonnal) {
        tanksPeriodesDetails.map((p) => {
            if (getSelectedPeriod(p.code, updatedRetenue.link_periodes)) {
                saisons = `${saisons}${p.name}, `
            }
        })
    }

    const content = (
        <Grid container sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px', rowGap: '1.5vh' }}>
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item className='bold'>{updatedRetenue?.id ?? '-'}</Grid>
                <EditOutlined
                    className='clickable'
                    onClick={() => setIsEditable(true)}
                />
            </Grid>
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item xs={2}>{i18n.type}</Grid>
                <Grid item xs={10} sx={{ textAlign: 'end' }}className='bold'>{tankType?.name ?? '-'}</Grid>
            </Grid>
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item>{i18n.capacity}</Grid>
                <Grid item className='bold'>{hasValue(updatedRetenue?.capacity) ? `${formatMilliers(updatedRetenue.capacity)} m3` : '-'}</Grid>
            </Grid>
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item>{i18n.recoveryFlow}</Grid>
                <Grid item className='bold'>{updatedRetenue?.recoveryFlow ?? '-'}</Grid>
            </Grid>
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item>{i18n.reserveFlow}</Grid>
                <Grid item className='bold'>{updatedRetenue?.reserveFlow ?? '-'}</Grid>
            </Grid>
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item xs={6}>{i18n.fillingMode}</Grid>
                <Grid item xs={6} sx={{ textAlign: 'end' }} className='bold'>{fillingMode?.name ?? '-'}</Grid>
            </Grid>
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item xs={6}>{i18n.fillingPeriod}</Grid>
                <Grid item xs={6} sx={{ textAlign: 'end' }} className='bold'>{fillingPeriode?.name ?? '-'}{!!saisons.length && ` (${saisons.slice(0, -2)})`}</Grid>
            </Grid>
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item>{i18n.sharedRetention}</Grid>
                <Grid item className='bold'>{updatedRetenue?.sharedRetention ? i18n.yes : i18n.no}</Grid>
            </Grid>
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item>{i18n.usedAt}</Grid>
                <Grid item className='bold'>{updatedRetenue?.usedPercentage ? `${updatedRetenue.usedPercentage}%` : '-'}</Grid>
            </Grid>
        </Grid>
    )

    const editableContent = (
        <Grid container sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px', rowGap: '1.5vh' }}>
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item className='bold'>{updatedRetenue?.id ?? '-'}</Grid>
                <Grid item>
                    <Close className='clickable' onClick={() => {
                        setIsEditable(false)
                        setUpdatedRetenue(updatedRetenue)
                    }}/>
                    <Done className='clickable' onClick={() => {
                        setIsLoading(true)
                        handleRetentionChange()
                    }} />
                </Grid>
            </Grid>
            <FormControl fullWidth>
                <InputLabel id={i18n.retentionType}>{i18n.retentionType}</InputLabel>
                <Select
                    labelId={i18n.retentionType}
                    label={i18n.retentionType}
                    value={updatedRetenue.tankType}
                    onChange={(e) => setUpdatedRetenue({ ...updatedRetenue, tankType: hasValue(e.target.value) ? Number(e.target.value) : null })}
                >
                    {
                        agriTanksTypes.map(type => (
                            <MenuItem
                                value={type.id}
                                style={{ maxWidth: '100%' }}
                            >
                                {type.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <InputRow
                type='Number'
                label={i18n.capacity}
                value={updatedRetenue.capacity}
                InputProps={{
                    endAdornment: updatedRetenue?.usedPercentage ? <InputAdornment position='end'>{i18n.m3}</InputAdornment> : null,
                }}
                onChange={(e) => setUpdatedRetenue({ ...updatedRetenue, capacity: Number(e.target.value) })}
            />
            <InputRow
                type='Number'
                label={i18n.recoveryFlow}
                value={updatedRetenue.recoveryFlow}
                onChange={(e) => setUpdatedRetenue({ ...updatedRetenue, recoveryFlow: Number(e.target.value) })}
            />
            <InputRow
                type='Number'
                label={i18n.reserveFlow}
                value={updatedRetenue.reserveFlow}
                onChange={(e) => setUpdatedRetenue({ ...updatedRetenue, reserveFlow: Number(e.target.value) })}
            />
            <FormControl fullWidth >
                <InputLabel id={i18n.fillingMode}>{i18n.fillingMode}</InputLabel>
                <Select
                    labelId={i18n.fillingMode}
                    label={i18n.fillingMode}
                    value={updatedRetenue.fillingMode}
                    onChange={(e) => setUpdatedRetenue({ ...updatedRetenue, fillingMode: hasValue(e.target.value) ? Number(e.target.value) : null })}
                >
                    {
                        fillingModes.map(mode => (
                            <MenuItem
                                value={mode.code}
                                style={{ maxWidth: '100%' }}
                            >
                                {mode.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <RadioGroup
                row
                name='idUsage'
                onChange={(event) =>
                    event.target.value === 2 ?
                        setUpdatedRetenue({
                            ...updatedRetenue,
                            link_periodes: updatedRetenue.link_periodes.map(period =>
                                (period.idPeriode = Number(event.target.value))
                            )
                        })
                        :
                        setUpdatedRetenue({
                            ...updatedRetenue,
                            link_periodes: [{
                                ...updatedRetenue.link_periodes[0],
                                idPeriode: Number(event.target.value),
                                idNbPeriode: null
                            }]
                        })
                }
            >
                {
                    fillingPeriodes.map(period => (
                        <FormControlLabel
                            checked={period === fillingPeriode}
                            value={period.code}
                            control={
                                <Radio
                                    sx={{
                                        color: textColor,
                                        '&.Mui-checked': {
                                            color: textColor,
                                        },
                                    }}
                                />
                            }
                            label={period.name}
                        />
                    ))
                }
            </RadioGroup>
            {
                tankSaisonnal ?
                    seasons.map((season) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={season.code}
                                    checked={!!updatedRetenue.link_periodes.find(period => period.idNbPeriode === season.code)}
                                    onChange={(e) => handleSeasonChange(e.target.value)}
                                />
                            }
                            label={season.name}
                        />
                    ))
                    :
                    null
            }
            <Grid container item xs={12}>
                <Grid container item xs={6.65} alignItems='center' >
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={updatedRetenue.sharedRetention}
                                checked={updatedRetenue.sharedRetention}
                                onChange={() => setUpdatedRetenue({ ...updatedRetenue, sharedRetention: !updatedRetenue.sharedRetention })}
                            />
                        }
                        label={i18n.sharedRetention}
                    />
                </Grid>
                <Grid item xs={5.25} >
                    <InputRow
                        type='Number'
                        value={updatedRetenue?.usedPercentage}
                        label={i18n.percentUsage}
                        InputProps={{
                            endAdornment: updatedRetenue?.usedPercentage ? <InputAdornment position='end'>%</InputAdornment> : null,
                            inputProps: { min: 0, max: 100 },
                        }}
                        sx={{
                            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                                WebkitAppearance: 'none',
                                margin: 0,
                            },
                            'input[type=number]': {
                                MozAppearance: 'textfield',
                            },
                        }}
                        onChange={(e) => setUpdatedRetenue({
                            ...updatedRetenue,
                            usedPercentage: Number(e.target.value) ? (Number(e.target.value) <= 100 ? Number(e.target.value) : 100) : 0
                        })}
                    />
                </Grid>
            </Grid>
        </Grid>
    )

    if (isLoading) {
        return <LoadingCard />
    }

    if (noLightCard) {
        return isEditable ? editableContent : content
    }

    return (
        <LightCard
            item
            container
            direction='column'
            justifyContent='space-between'
            className='clickable'
            sx={{ marginBottom: '20px', ...sx }}
        >
            <Grid item fontSize={22} className='bold' sx={{ marginBottom: '20px' }}>{title ||i18n.retention}</Grid>
            {isEditable ? editableContent : content}
        </LightCard>
    )
}

TankCard.propTypes = {
    title: PropTypes.string,
    retenue: PropTypes.oneOfType([DtoTankDeclaration, DtoTankExploit]).isRequired,
    noLightCard: PropTypes.bool,
    sx: PropTypes.shape({}),
    match: PropTypes.instanceOf(PropTypes.shape({})),
}

export default TankCard
