import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { darkColor } from '../styled/theme'
import { Icon, Grid, ClickAwayListener } from '@mui/material'
import { HelpTooltip } from '../styled/texts'
import { sieauTooltip } from '../../../../utils/FormUtils'

const StyledCard = ({
    title,
    subTitle,
    color = darkColor,
    children,
    className,
    styleTitle,
    styleSubTitle,
    styleContainer,
    styleContent,
    onClick = () => {},
    showIcon,
    editMode,
    toggleEditMode = () => {},
    icon,
    otherIcon,
    tooltipContentOtherIcon,
    onClickOtherIcon = () => {},
    tooltipIcon,
    tooltipContent,
}) => {
    const [openTooltip, setOpenTooltip] = useState(false)

    return (
        <div className={className} style={{ padding: '5 10', ...styleContainer }} onClick={onClick}>
            <Card style={{ borderRadius: 0, boxShadow: 'none', borderBottom: `1px solid ${color === 'white' ? 'black' : color}` }}>
                {title && (
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        style={{ backgroundColor: color, padding: !showIcon && !otherIcon ? '12 8' : 8, fontWeight: 'bold', color: color === 'white' ? 'black' : 'white', ...styleTitle }}
                    >
                        <Grid item>
                            {title}
                        </Grid>
                        {showIcon && (
                            <Grid item className='clickable' onClick={toggleEditMode}>
                                {!editMode && <Icon>{icon || 'edit'}</Icon>}
                            </Grid>
                        )}
                        {otherIcon && (
                            <Grid item className='clickable' onClick={onClickOtherIcon} {...sieauTooltip(tooltipContentOtherIcon)}>
                                {<Icon>{otherIcon}</Icon>}
                            </Grid>
                        )}
                        {tooltipIcon && (
                            <Grid item className='clickable' onClick={() => setOpenTooltip(true)}>
                                <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                                    <HelpTooltip
                                        title={tooltipContent}
                                        open={openTooltip}
                                        arrow
                                        disableHoverListener
                                        placement='top'
                                    >
                                        {<Icon>{tooltipIcon}</Icon>}
                                    </HelpTooltip>
                                </ClickAwayListener>
                            </Grid>
                        )}
                    </Grid>
                )}
                {subTitle && (
                    <Typography
                        style={{
                            backgroundColor: 'white',
                            padding: 8,
                            fontSize: 21,
                            fontWeight: 'bold',
                            color: color === 'white' ? 'black' : color,
                            borderBottom: `1px solid ${color === 'white' ? 'black' : color}`,
                            ...styleSubTitle,
                        }}
                    >
                        {subTitle}
                    </Typography>
                )}
                <CardContent style={{ padding: 8, color: color === 'white' ? 'black' : color, ...styleContent }}>
                    {children}
                </CardContent>
            </Card>
        </div>
    )
}

StyledCard.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.element,
    color: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.instanceOf(PropTypes.shape({})),
    styleContainer: PropTypes.instanceOf(PropTypes.shape({})),
    styleContent: PropTypes.instanceOf(PropTypes.shape({})),
    styleTitle: PropTypes.instanceOf(PropTypes.shape({})),
    styleSubTitle: PropTypes.instanceOf(PropTypes.shape({})),
    otherIcon: PropTypes.string,
    tooltipContentOtherIcon: PropTypes.string,
    icon: PropTypes.string,
    tooltipIcon: PropTypes.string,
    tooltipContent: PropTypes.string,
    showIcon: PropTypes.bool,
    editMode: PropTypes.bool,
    toggleEditMode: PropTypes.func,
    onClickOtherIcon: PropTypes.func,
}

export default StyledCard