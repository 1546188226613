/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, TextField } from '@mui/material'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import HomeAction from '../../offline/actions/HomeAction'
import LoadingCard from '../components/cards/LoadingCard'
import { MainButton } from '../components/styled/buttons'
import moment from 'moment'
import { mainColor } from '../components/styled/theme'
import { getDate } from '../../../utils/DateUtil'
import { LightCard } from '../components/styled/grid'
import SimpleSelect from '../components/forms/SimpleSelect'
import { push } from 'react-router-redux'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'


const EventsEntriesApp = ({ match }) => {
    const [eventDate, setEventDate] = useState(getDate(moment().valueOf(), 'YYYY-MM-DD'))
    const [idType, setIdType] = useState()
    const [comment, setComment] = useState()

    const dispatch = useDispatch()

    const idInstallation = useMemo(() => Number(match.params.id), [match])
    const idMateriel = useMemo(() => Number(match.params.idMat), [match])

    const {
        exploitation,
        installations,
        variousMateriels,
        variousMatSituations,
        matEventsTypes,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        matEventsTypes: store.MaterielReducer.matEventsTypes,
    }), shallowEqual)

    useEffect(() => {
        dispatch(HomeAction.setTitle([{
            title: i18n.newEntry,
            href: `/consommation/point/${idInstallation}/events/materiels/${idMateriel}/entries`,
        }]))
    }, [idInstallation, idMateriel])

    const installation = useMemo(() => installations.find((i) => i.id === idInstallation) || {}, [installations, idInstallation])
    const materiel = useMemo(() => variousMateriels.find((m) => m.id === idMateriel) || {}, [variousMateriels, idMateriel])

    const saveEvent = () => {
        dispatch(MaterielAction.createMatEvent({
            eventDate: new Date(eventDate).getTime(),
            idType,
            comment,
            materielType: 'divers',
            idMateriel,
        })).then(() => {
            dispatch(MaterielAction.fetchMatEventsByExploitation(exploitation.idExploitation)).then(() => dispatch(push(`/consommation/point/${idInstallation}/events`)))
        })
    }

    if (variousMatSituations.length) {
        return (
            <div style={{ padding: '8px 1.5rem' }}>
                <LightCard container direction='column' sx={{ border: `1px solid ${mainColor}` }}>
                    <Grid item fontSize={22} sx={{ marginBottom: '14px' }}>{installation?.name || `${i18n.point} ${installation?.code ?? ''}`}</Grid>
                    <Grid item container fontSize={14} sx={{ marginBottom: '24px' }}>
                        <Grid item>{materiel.counter ? i18n.counter : i18n.pump}</Grid>
                        <Grid item className='bold' sx={{ marginLeft: '8px' }}>{materiel?.reference ?? ''}</Grid>
                    </Grid>
                    <SimpleSelect
                        id='idType'
                        required
                        noNullValue
                        label={i18n.eventType}
                        value={idType}
                        onChange={(v) => setIdType(v)}
                        integerValue
                        options={matEventsTypes}
                        keyValue='id'
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        id='eventDate'
                        label={i18n.date}
                        type='date'
                        required
                        variant='outlined'
                        value={moment(eventDate).format('YYYY-MM-DD')}
                        inputProps={{
                            max: '9999-12-31'
                        }}
                        onChange={(e) => setEventDate(e.target.value)}
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        item
                        id='comment'
                        type='text'
                        variant='outlined'
                        label={i18n.comment}
                        placeholder={i18n.comment}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        multiline
                        rows={10}
                        rowsMax={Infinity}
                        InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                        sx={{ marginBottom: '16px' }}
                    />
                    <MainButton onClick={saveEvent} sx={{ marginBottom: '16px' }}>
                        {i18n.add}
                    </MainButton>
                    <MainButton reverse onClick={() => history.back()}>
                        {i18n.previous}
                    </MainButton>
                </LightCard>
            </div>
        )
    }
    return <LoadingCard />
}

EventsEntriesApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            idMat: PropTypes.string,
        })
    }),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
}

export default EventsEntriesApp
