import React, { Component } from 'react'
import PropTypes from 'prop-types'
import UUID from 'uuid'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { OSM, Vector as VectorSource } from 'ol/source'
import { Point } from 'ol/geom'
import { Style, Icon as Marker } from 'ol/style'
import { fromLonLat } from 'ol/proj'
import { Feature, Map, View } from 'ol'
import { ScaleLine, Rotate, defaults as defaultControls } from 'ol/control'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import { getWGS84Coordinate } from '../../../utils/mapUtils/CoordinateUtils'
import markerOrange from '../../../ressources/static/media/markers/marker_orange.png'
import markerGreen from '../../../ressources/static/media/markers/marker_green.png'
import markerGrey from '../../../ressources/static/media/markers/marker_grey.png'
import EditableCard from '../components/cards/EditableCard'

class LittleMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: UUID.v4()
        }
    }

    componentDidMount() {
        this.setMap()
    }

    getMarker = (stateCode) => {
        switch (stateCode) {
            case 1:
                return { marker: markerGreen, color: 'green' }
            case 2:
                return { marker: markerOrange, color: 'orange' }
            case 3:
                return { marker: markerGrey, color: 'grey' }
            default:
                return { marker: markerOrange, color: 'orange' }
        }
    }

    setMap = () => {
        const { point, stateCode } = this.props
        const { id } = this.state
        const olMap = new Map({
            target: `ol-map-${id}`,
            controls: defaultControls().extend([
                new ScaleLine({
                    units: 'metric',
                }),
                new Rotate()
            ]),
            layers: [
                new TileLayer({
                    source: new OSM()
                }),
            ],
        })
        const coordinates = getWGS84Coordinate(point)
        const { marker } = point.marker ? point : this.getMarker(stateCode)
        const markersLayer = new VectorLayer({
            className: 'clickable',
            zIndex: 100,
            source: new VectorSource({
                features: [
                    new Feature({
                        geometry: new Point(fromLonLat(coordinates)),
                    })
                ]
            }),
            style: new Style({
                image: new Marker({
                    anchor: [0.5, 1],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    scale: 0.75,
                    src: marker,
                })
            }),
        })
        olMap.addLayer(markersLayer)
        olMap.setView(new View({
            center: fromLonLat(coordinates),
            zoom: 14,
        }))
    }

    render() {
        const { styleContainer, style, height, card } = this.props
        const { id } = this.state

        if (card) {
            return (
                <EditableCard noInnerPadding styleContainer={styleContainer} style={{ height, ...style }}>
                    <div id={`ol-map-${id}`} className='ol-little-map' style={{ height }} />
                </EditableCard>
            )
        }
        return (
            <div id={`ol-map-${id}`} className='ol-little-map margin-bottom-1' style={{ height, ...style }} />
        )
    }
}

LittleMap.propTypes = {
    point: PropTypes.instanceOf(DtoInstallation),
    stateCode: PropTypes.number,
    height: PropTypes.string,
    card: PropTypes.bool,
    styleContainer: PropTypes.instanceOf(PropTypes.shape({})),
    style: PropTypes.instanceOf(PropTypes.shape({})),
}

export default LittleMap