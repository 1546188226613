// import DtoPluvioMeasureJ from './DtoPluvioMeasureJ'
import DtoRSEauLandState from './DtoRSEauLandState'

export default class DtoRSEauDashboard {
    constructor(object) {
        // Ecran 1
        this.date = object.date // DateTime, // J
        this.totalWaterVolume = object.totalWaterVolume // Option[Double],
        this.authorizedPercent = object.authorizedPercent // Option[Double], // TODO A calculer avec agri_prel_volume
        this.sparedWaterAmount = object.sparedWaterAmount // Option[Double],
        this.parsimonyScore = object.parsimonyScore // Option[Int] valeur de -100 à 100
        this.EEE = object.EEE // Option[Int] valeur de 0 à Max EE (paramètre variable défini par michael)
        this.PSE = object.PSE // Option[Int]
        this.totalWaterVolume = object.totalWaterVolume // Option[Double]

        this.previousEEE = object.previousEEE // Option[Double],
        this.previousPSE = object.previousPSE // Option[Double],

        this.nextEEE = object.nextEEE // Option[Double],
        this.nextPSE = object.nextPSE // Option[Double],

        this.EEEGaugeSteps = object.EEEGaugeSteps // Seq[Double], sections la 2e jauge
        this.ecoNote = object.ecoNote // Option[String],
        // Ecran 2
        this.ressourceDisponibility = object.ressourceDisponibility // Option[Int],
        this.nextThreshold = object.nextThreshold // Option[String], // "A", "AR", "V", ou "C", toujours défini
        this.nbDaysThreshold = object.nbDaysThreshold // Option[Int], // si non défini, mettre une flèche stable
        this.thresholdDirection = object.thresholdDirection // Option[String], // "up" ou "down"

        this.merit = object.merit // Option[String] // "Faible", "Moyen" ou "Fort"
        this.meritComment = object.meritComment // Option[String]

        this.restrictionDelay = object.restrictionDelay // Option[Int],
        this.restrictionDate = object.restrictionDate // Option[DateTime],
        this.DD1 = object.DD1 // Option[DateTime],
        this.DD2 = object.DD2 // Option[DateTime],
        this.hasDD1 = object.hasDD1 // Option[DateTime],
        this.hasDD2 = object.hasDD2 // Option[DateTime],
        // Ecran 3
        // this.pluvioMeasures = object.pluvioMeasures && object.pluvioMeasures.length ? object.pluvioMeasures.map((pM) => new DtoPluvioMeasureJ(pM)) : [] // Seq[PluvioMeasureJ],
        // Ecran 4
        this.lands = object.lands && object.lands.length ? object.lands.map((l) => new DtoRSEauLandState(l)) : [] // Seq[RseauLandState]
    }
}
