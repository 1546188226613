import React from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material'
import { mainColor, veryLightColor } from './styled/theme'
import { ExpandMore } from '@mui/icons-material'

export default function Accordeon({
    title,
    disabled,
    expanded,
    children,
    sx,
}) {
    return (
        <Accordion
            disabled={disabled}
            defaultExpanded={expanded}
            disableGutters
            sx={{
                boxShadow: 'none',
                color: mainColor,
                borderRadius: '20px',
                '&:before': {
                    display: 'none',
                },
                ...sx,
            }}
            slotProps={{ transition: { unmountOnExit: true, timeout: 250 } }}
        >
            <AccordionSummary
                expandIcon={<ExpandMore style={{ color: mainColor }} />}
                sx={{ backgroundColor: veryLightColor, padding: '24px', borderRadius: '20px' }}
            >
                <Grid
                    container
                    direction='column'
                    justifyContent='space-between'
                    className='clickable'
                >
                    <Grid item fontSize={22} className='bold'>{title}</Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: veryLightColor, borderRadius: '20px', padding: '24px' }}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

Accordeon.propTypes = {
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    expanded: PropTypes.bool,
    children: PropTypes.element,
    sx: PropTypes.shape({}),
}