import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'
import { Container, Item } from '../styled/grid'

// [1/7, '#FD0807'],
// [2/7, '#FF5000'],
// [3/7, '#FF9600'],
// [4/7, '#FFFE00'],
// [5/7, '#85B602'],
// [6/7, '#037F03'],
// [1, '#005100'],

const parts = [
    { label: 'A', color: '#005100' },
    { label: 'B', color: '#037F03' },
    { label: 'C', color: '#85B602' },
    { label: 'D', color: '#FFFE00' },
    { label: 'E', color: '#FF9600' },
    { label: 'F', color: '#FF5000' },
    { label: 'G', color: '#FD0807' },
    { label: 'H', color: 'black' },
]

const getColors = (nbParts) => {
    const colors = []
    for (let index = nbParts; index > 0; index--) {
        const part = parts[index - 1]
        if (part) {
            colors.push([ (nbParts - index + 1)/nbParts, part.color ])
        }
    }
    return colors
}

export default class DoubleGauge extends PureComponent {
    getOption = () => {
        const { value, nbParts, min, max } = this.props
        return {
            series: [
                // Affichage des couleurs des dérogations (DD)
                {
                    type: 'gauge',
                    radius: '100%',
                    center: ['50%', '60%'],
                    min,
                    max,
                    startAngle: 216,
                    endAngle: -36,
                    splitNumber: nbParts,
                    axisTick: { show: false },
                    splitLine: { show: false },
                    axisLine: {
                        lineStyle: {
                            color: [
                                [2/nbParts, new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 1,
                                    color: '#DAE3F3'
                                }, {
                                    offset: 0,
                                    color: '#DAE3F3'
                                }])],
                                [5/nbParts, new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 1,
                                    color: '#00B0F0'
                                }, {
                                    offset: 0,
                                    color: '#00B0F0'
                                }])],
                                [1, new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#2E75B6'
                                }, {
                                    offset: 1,
                                    color: '#2E75B6'
                                }])]
                            ],
                            width: '30%',
                        }
                    },
                    axisLabel: { show: false },
                    detail: {
                        show: false,
                        fontWeight: 'bold',
                        fontSize: 25,
                        formatter: (info) => {
                            const val = (`${info}`).split('.')
                            val.push('€/ha')
                            return val[0] + (val[1])
                        },
                    },
                    data: [{
                        value,
                        itemStyle: { color: 'transparent' },
                    }],
                },
                // Affichage des couleurs des 7 parts
                {
                    type: 'gauge',
                    radius: '80%',
                    center: ['50%', '60%'],
                    min: 0,
                    max: nbParts,
                    startAngle: 216,
                    endAngle: -36,
                    splitNumber: nbParts,
                    splitLine: { show: false },
                    axisTick: { show: false },
                    axisLine: {
                        lineStyle: {
                            color: getColors(nbParts),
                            width: '40%',
                        }
                    },
                    axisLabel: { show: false },
                    detail: { show: false },
                },
                // Affichage des labels de dérogations (DD)
                {
                    type: 'gauge',
                    radius: '80%',
                    center: ['50%', '60%'],
                    min: 0,
                    max: 150,
                    startAngle: 216,
                    endAngle: -36,
                    splitLine: { show: false },
                    splitNumber: 30,
                    axisTick: { show: false },
                    axisLine: {
                        lineStyle: {
                            color: [
                                [1, 'transparent'],
                            ],
                        },
                    },
                    axisLabel: {
                        color: 'white',
                        fontSize: 18,
                        distance: -50,
                        formatter: (v) => {
                            switch (v) {
                                case 75:
                                    return 'DD1'
                                case 125:
                                    return 'DD2'
                                default:
                                    return ''
                            }
                        }
                    },
                    detail: { show: false },
                },
                // Affichage des notes (A, ..., F)
                {
                    type: 'gauge',
                    radius: '80%',
                    center: ['50%', '60%'],
                    min: 0,
                    max: nbParts,
                    startAngle: 216,
                    endAngle: -36,
                    splitLine: {
                        show: false,
                    },
                    splitNumber: nbParts * 2,
                    axisTick: { show: false },
                    axisLine: {
                        lineStyle: {
                            color: [
                                [1, 'transparent'],
                            ],
                        },
                    },
                    axisLabel: {
                        color: 'white',
                        fontSize: 18,
                        distance: -15,
                        formatter: (v) => {
                            const part = parts[nbParts - v -0.5]
                            return part ? part.label : ''
                        }
                    },
                    detail: { show: false },
                },
                // Affichage de la fleche
                {
                    type: 'gauge',
                    radius: '100%',
                    center: ['50%', '60%'],
                    min,
                    max,
                    startAngle: 216,
                    endAngle: -36,
                    splitNumber: nbParts,
                    axisTick: { show: false },
                    splitLine: {
                        show: true,
                        length: '100%',
                        lineStyle: {
                            color: '#000'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: [
                                [1, '#D0CDCE'],
                            ],
                            width: 0,
                        }
                    },
                    axisLabel: { show: false },
                    detail: { show: false },
                    data: [{
                        value,
                        itemStyle: { color: '#000' },
                    }],
                },
                // Valeurs en €
                {
                    type: 'gauge',
                    radius: '150%',
                    center: ['50%', '60%'],
                    min: -0,
                    max: 100,
                    startAngle: 216 - (2 * 36),
                    endAngle: 72,
                    splitNumber: 2,
                    axisTick: { show: false },
                    splitLine: { show: false },
                    title: { show: false },
                    axisLine: {
                        lineStyle: {
                            color: [
                                [1, 'transparent'],
                            ],
                        }
                    },
                    axisLabel: {
                        show: true,
                        fontSize: 15,
                        fontWeight: 'bolder',
                        color: '#000',
                        shadowColor: '#000',
                        shadowBlur: 10,
                        formatter: (v) => {
                            return `${v} €`
                        }
                    },
                },
                // Valeurs en €
                {
                    type: 'gauge',
                    radius: '160%',
                    center: ['50%', '60%'],
                    min: 150,
                    max: 250,
                    startAngle: 36,
                    endAngle: -36,
                    splitNumber: 2,
                    axisTick: { show: false },
                    splitLine: { show: false },
                    title: { show: false },
                    axisLine: {
                        lineStyle: {
                            color: [
                                [1, 'transparent'],
                            ],
                        }
                    },
                    axisLabel: {
                        show: true,
                        fontSize: 15,
                        fontWeight: 'bolder',
                        color: '#000',
                        shadowColor: '#000',
                        shadowBlur: 10,
                        formatter: (v) => {
                            return `${v} €`
                        }
                    },
                },
            ]
        }
    }

    render() {
        const { title, style } = this.props
        return (
            <Container
                justifyContent='center'
                alignItems='center'
                style={{ width: '100%', ...style }}
            >
                <Item xs={12} className='center valueLabel'>
                    <b>{title}</b>
                </Item>
                <Container
                    justifyContent='center'
                    alignItems='center'
                    style={{ height: 260, width: '100%' }}
                >
                    <ReactEcharts
                        option={this.getOption()}
                        style={{ height: '100%', width: '100%' }}
                        className='react_for_echarts'
                    />
                </Container>
            </Container>
        )
    }
}

DoubleGauge.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
    nbParts: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    style: PropTypes.instanceOf(PropTypes.object),
}