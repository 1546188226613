'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    CITIES,
    RESET_CITY,
    RECEIVE_CITIES_HISTO,
} from '../constants/CityConstants'
import ApplicationConf from '../../../../../conf/ApplicationConf'
import { aquaFetchV2, checkAuth, getAuthorization, getJson } from '../../../../../utils/ActionUtils'
import ToastrAction from '../../../components/toasters/ToastrAction'
import LogAction from '../../../../../utils/log/actions/LogAction'

const CityAction = {
    receiveCities(cities) {
        return { type: CITIES, cities }
    },
    fetchCities() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.referencial.cities(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().CityReducer.cities })
                .then((json = []) => {
                    dispatch(CityAction.receiveCities(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError}${i18n.cities} : ${err}`)
                    ToastrAction.error(`${i18n.fetchError}${i18n.cities}`)
                })
        }
    },

    receiveCitiesHisto(citiesHisto) {
        return { type: RECEIVE_CITIES_HISTO, citiesHisto }
    },
    fetchCitiesHisto() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.citiesHisto(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json) => {
                    dispatch(CityAction.receiveCitiesHisto(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                    ToastrAction.error(i18n.fetchError)
                })
        }
    },

    resetCity() {
        return { type: RESET_CITY }
    },
}

export default CityAction
