import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { makeStyles } from '@mui/styles'
import { Card, Grid, Modal, Paper } from '@mui/material'
import { MainButton } from '../../online/components/styled/buttons'
import DtoCMSEvent from '../../online/cms/dto/DtoCMSEvent'
import { sortById } from '../../../utils/ObjectUtils'

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        margin: '10px auto',
        bottom: 'inherit',
        height: '90vh',
        width: '90vw',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '8px',
        boxShadow: 'none',
    },
    text: {
        fontSize: '16px',
        boxShadow: 'none',
        textAlign: 'justify',
        width: '100%',
        border: 'none',
        font: 'unset',
        resize: 'none',
        backgroundColor: 'white',
        color: 'black',
        opacity: 'unset',
    },
}))

export default function ModalCGU({ open, cgu, onRefuse, onValidate }) {
    const classes = useStyles()

    const showCMS = () => {
        cgu.sort(sortById)
        return cgu.map((cms) => {
            return (
                <Grid key={cms.id} item xs={12}>
                    <Paper className={classes.title}>{cms.title}</Paper>
                    <div dangerouslySetInnerHTML={{ __html: cms.comment || '' }} />
                </Grid>
            )
        })
    }

    return (
        <Modal
            open={open}
            className={classes.paper}
            onClose={onRefuse}
            style={{
                bottom: 'inherit',
                zIndex: '1000',
            }}
        >
            <Card className='overflowY' style={{ padding: '14px', height: '100%' }}>
                {showCMS()}
                <MainButton margintop onClick={onValidate}>
                    {i18n.validate}
                </MainButton>
                <MainButton reverse='true' onClick={onRefuse} margintop>
                    {i18n.cancel}
                </MainButton>
            </Card>
        </Modal>
    )
}

ModalCGU.propTypes = {
    open: PropTypes.bool.isRequired,
    onRefuse: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    cgu: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)).isRequired,
}
