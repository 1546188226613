import { Grid } from '@mui/material'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { InputRow } from '../../../components/styled/inputs'
import PropTypes from 'prop-types'
import DtoDeclaration from '../../../agri/dto/enquete/DtoDeclaration'
import { MainButton } from '../../../components/styled/buttons'
import { useDispatch, useSelector } from 'react-redux'
import AgriAction from '../../../agri/actions/AgriAction'
import { DECLA_LAST_STEP, DECLARATION_STATUS } from '../../../agri/constants/AgriConstants'

const CommentsStep = ({ newDeclaration, setNewDeclaration, setValidationStep, xs }) => {
    const {
        exploitation,
        declaration,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        declaration: store.AgriReducer.declaration,
    }))

    const [editMode, setEditMode] = useState(declaration.lastStep === DECLA_LAST_STEP.COMMENTS_STEP && declaration.statusCode !== DECLARATION_STATUS.SENT)
    const dispatch = useDispatch()

    return (
        <Grid item xs={xs} container sx={{ height: 'auto', rowGap: '2vh' }}>
            <InputRow
                label={i18n.comment}
                disabled={!editMode}
                value={newDeclaration.comment}
                multiline
                rows={10}
                onChange={(e) => setNewDeclaration({
                    ...newDeclaration,
                    comment: e.target.value ?? null
                })}
            />
            <Grid container justifyContent='center'>
                {
                    editMode ?
                        <MainButton noFullWidth sx={{ width: 'auto', margin: 0 }} onClick={
                            () => {
                                setEditMode(false)
                                dispatch(AgriAction.updateDeclaration({ ...newDeclaration, lastStep: DECLA_LAST_STEP.COMMENTS_STEP })).then(() => {
                                    dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation)).then(() => {
                                        setValidationStep(true)
                                        window.scrollTo(0, 0)
                                    })
                                })
                            }
                        }>
                            {i18n.validComments}
                        </MainButton>
                        :
                        (declaration.statusCode !== DECLARATION_STATUS.SENT &&
                            <MainButton noFullWidth sx={{ width: 'auto', margin: 0 }} onClick={ () => {
                                setEditMode(true)
                                dispatch(AgriAction.updateDeclaration({ ...newDeclaration, lastStep: DECLA_LAST_STEP.COMMENTS_STEP, statusCode: DECLARATION_STATUS.ONGOING })).then(() => {
                                    dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation)).then(() => {
                                        setValidationStep(false)
                                    })
                                })
                            }}
                            >
                                {i18n.changeComments}
                            </MainButton>
                        )
                }
            </Grid>
        </Grid>
    )
}

CommentsStep.propTypes = {
    newDeclaration: PropTypes.instanceOf(DtoDeclaration),
    setNewDeclaration: PropTypes.func,
    setValidationStep: PropTypes.func,
    xs: PropTypes.number,
}

export default CommentsStep