import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import { compact, sortBy } from 'lodash'
import { MainButton } from '../online/components/styled/buttons'
import { InputRow } from '../online/components/styled/inputs'
import { CMS_PATH } from '../../conf/basepath'
import DtoCMSEvent from '../online/cms/dto/DtoCMSEvent'
import { isRunningApp } from '../../utils/LocalStorageUtils'
import ToastrAction from '../online/components/toasters/ToastrAction'
import HomeAction from './actions/HomeAction'
import { getSetting, getSettingInt, sieauTooltip } from '../../utils/FormUtils'
import CmsAction from '../online/cms/actions/CmsAction'
import defaultHeader from '../../ressources/static/media/login_header.jpg'
import defaultLogo from '../../ressources/static/media/iryqua.png'
import LogAction from '../../utils/log/actions/LogAction'
import aquasysLogoPath from '../../ressources/pictures/favicon.png'
import { isMobile } from 'react-device-detect'
import { textColor, veryLightColor, mainColor } from '../online/components/styled/theme'
import { useState, createRef, forwardRef } from 'react'
import { ArrowBack } from '@mui/icons-material'
import ModalUpdatePasswordDesktop from '../online/account/components/ModalUpdatePasswordDesktop'

const styles = () => ({
    logoTop: {
        textAlign: 'center',
        width: '100%',
        height: '15%',
    },
    img: {
        height: '100%',
        width: 'auto',
    },
})

const CodeInput = forwardRef(({ onValueChange = () => {} }, ref) => {
    const [value, setValue] = useState('')
    const handleChange = (event) => {
        const inputValue = event.target.value
        if ((inputValue >= 0 && inputValue <= 9) || !inputValue) {
            setValue(inputValue)
            onValueChange(inputValue)
        }
    }


    return (
        <InputRow type='number' value={value} onChange={handleChange} maxLength='1' inputProps={{ min: 0, style: { textAlign: 'center' }, ref }}
            sx={{
                maxWidth: '56px',
                'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                },
                'input[type=number]': {
                    MozAppearance: 'textfield',
                },
            }}
        />
    )
})

CodeInput.propTypes = {
    onValueChange: PropTypes.func,
}

const PinCode = ({ setCode }) => {
    const [pinValue, setPinValue] = useState(['', '', '', ''])
    const inputRefs = Array.from({ length: 4 }, () => createRef())

    const handleValueChange = (input, value) => {
        setPinValue((prev) => {
            if (!value) {
                prev[input] = ''
                return prev
            }
            prev[input] = value
            return prev
        })

        if (input < 3 && value) {
            inputRefs[input + 1].current.focus()
        }

        setCode(pinValue.join(''))
    }


    return (
        <Grid container item sx={{ width: '60%', gap: '8px' }} justifyContent='center'>
            <Grid item container xs={2.5} justifyContent='center'>
                <CodeInput ref={inputRefs[0]} onValueChange={(value) => handleValueChange(0, value)} />
            </Grid>
            <Grid item container xs={2.5} justifyContent='center'>
                <CodeInput ref={inputRefs[1]} onValueChange={(value) => handleValueChange(1, value)}/>
            </Grid>
            <Grid item container xs={2.5} justifyContent='center'>
                <CodeInput ref={inputRefs[2]} onValueChange={(value) => handleValueChange(2, value)}/>
            </Grid>
            <Grid item container xs={2.5} justifyContent='center'>
                <CodeInput ref={inputRefs[3]} onValueChange={(value) => handleValueChange(3, value)}/>
            </Grid>
        </Grid>
    )
}

PinCode.propTypes = {
    setCode: PropTypes.func,
}

class Password extends Component {
    constructor(props) {
        super(props)
        this.state = {
            identifiant: '',
            openModal: false,
            images: JSON.parse(localStorage.getItem('IRYQUA_images')) || [],
            backgroundUrl: localStorage.getItem('IRYQUA_backgroundUrl') || defaultHeader,
            logoUrl: localStorage.getItem('IRYQUA_logoUrl') || defaultLogo,
            hasResetCode: false,
            needLogin: false,
            resetCode: null,
            settingsLoaded: false,
            newMdp: null,
            mdpConfirmation: null,
        }
    }

    setCode = (code) => {
        this.setState({ resetCode: code })
    }

    componentDidMount() {
        const { applicationSettings, cmsEvents } = this.props
        if (!cmsEvents.length) {
            this.props.fetchCMSEvents().then(() => {
                if (!applicationSettings.length) {
                    this.props.fetchApplicationSettings().then(() => this.setLogos())
                } else {
                    this.setLogos()
                }
            })
        } else {
            this.setLogos()
        }
    }

    setLogos = () => {
        const { applicationSettings, cmsEvents } = this.props
        const applicationName = applicationSettings.find(({ parameter }) => parameter === 'applicationName') || {}
        if (applicationName.value) {
            document.title = applicationName.value
            $('#favicon').attr('href', getSetting(applicationSettings, 'applicationFavicon') || aquasysLogoPath)
        }
        const cmsHomeId = getSettingInt(applicationSettings, 'iryquaCmsAccueil')
        const cmsHome = cmsEvents.find((c) => c.id === cmsHomeId)
        const logoUrl = cmsHome?.document?.length ? CMS_PATH + cmsHome.document[0]?.name : ''
        const cmsBackgroundId = getSettingInt(applicationSettings, 'iryquaCmsFondAccueil')
        const cmsBackground = cmsEvents.find((c) => c.id === cmsBackgroundId)
        const backgroundUrl = cmsBackground?.document?.length ? CMS_PATH + cmsBackground.document[0]?.name : ''
        const idCategPartenaires = getSettingInt(applicationSettings, 'iryquaCategorieCmsPartenaires')
        const partenaires = cmsEvents.filter((c) => c.idCategory === idCategPartenaires)
        const images = compact(sortBy(partenaires, 'subtitle').map((c) => {
            if (c?.document[0]) {
                return {
                    link: c.link,
                    docName: CMS_PATH + c.document[0].name,
                }
            }
            return null
        }))
        localStorage.removeItem('IRYQUA_backgroundUrl')
        localStorage.removeItem('IRYQUA_logoUrl')
        localStorage.removeItem('IRYQUA_images')
        localStorage.setItem('IRYQUA_backgroundUrl', backgroundUrl)
        localStorage.setItem('IRYQUA_logoUrl', logoUrl)
        localStorage.setItem('IRYQUA_images', JSON.stringify(images))
        this.setState({
            backgroundUrl,
            logoUrl,
            settingsLoaded: true,
            images,
        })
    }

    handleChangeValue = (value) => {
        this.setState({ identifiant: value })
    }

    toggleModal = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    onSubmit = () => {
        const { identifiant } = this.state
        if (identifiant) {
            this.props.logJournal(identifiant, `Demande de mdp oublié pour l'identifiant : ${identifiant}`, 'FORGET_PASSWORD')
            this.props.resetPassword(identifiant, false).then((json) => {
                if (!json) {
                    ToastrAction.info(i18n.sendEmail)
                    this.setState({ hasResetCode: true, needLogin: false })
                }
            })
        } else {
            ToastrAction.error(i18n.fillAllFields, true)
        }
    }

    openWebSite = (link) => {
        if (!isRunningApp() && link) {
            window.open(link, '_blank')
        }
    }

    getLogoPartenaires = (mobile) => {
        const { classes } = this.props
        const { images } = this.state
        return images.map((i, index) => {
            if (mobile) {
                return (
                    <Grid item key={index} sx={{ height: '10vw', minHeight: '40%' }}>
                        <Grid
                            container
                            direction='row'
                            justifyContent='space-around'
                            alignItems='center'
                            sx={{ height: '100%' }}
                        >
                            <img
                                src={i.docName}
                                alt={i.link}
                                className={`${classes.img} clickable`}
                                onClick={() => this.openWebSite(i.link)}
                                style={{
                                    height: '100%',
                                    width: 'auto',
                                    maxWidth: '100%',
                                }}
                            />
                        </Grid>
                    </Grid>
                )
            }
            return (
                <Grid container item key={index} justifyContent='center'>
                    <img
                        src={i.docName}
                        alt={i.link}
                        className={`${classes.img} clickable`}
                        onClick={() => this.openWebSite(i.link)}
                        style={{
                            height: '100%',
                            maxHeight: '100%',
                            width: '40%',
                        }}
                    />
                </Grid>
            )
        })
    }

    onValidateResetCode = (login, resetCode) => {
        if (login) {
            if (resetCode) {
                this.props.verifyResetCode(login, resetCode.toString()).then(valide => {
                    if (valide) {
                        this.setState({ openModal: true })
                    }
                })
            } else {
                ToastrAction.error('Veuillez renseigner le code qui vous a été envoyé par mail', true)
            }
        } else {
            ToastrAction.error('Veuillez renseigner votre identifiant', true)
        }
    }

    toggleModalPassword = () => {
        this.setState(({ openModal }) => ({ openModal: !openModal }))
    }

    onUpdatePassword = () => {
        const { newMdp, mdpConfirmation, resetCode } = this.state
        const { applicationSettings } = this.props
        if (
            newMdp !== null &&
            newMdp.length &&
            mdpConfirmation !== null &&
            mdpConfirmation.length
        ) {
            if (newMdp === mdpConfirmation) {
                const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                if (regex.test(newMdp)) {
                    this.props.updatePassword(newMdp, resetCode).then(() => {
                        ToastrAction.success(i18n.passwordUpdatedSuccessfully)
                        this.props.push('/login')
                    })
                } else {
                    ToastrAction.error(regexHelp, true)
                }
            } else {
                ToastrAction.error('Les mots de passe ne sont pas identiques', true)
            }
        }
    }

    getPanel = () => {
        const { hasResetCode, identifiant, resetCode, needLogin } = this.state
        const back = () => {
            history.back()
            window.scrollTo(0, 0)
        }
        if (isMobile) {
            if (hasResetCode) {
                return (
                    <Grid
                        container
                        className='overflowY'
                        direction='row'
                        justifyContent='space-evenly'
                        alignItems='center'
                        sx={{ height: '40%', padding: '0 20px' }}
                    >
                        <Grid item container direction='column' justifyContent='center' alignItems='center'>
                            <p style={{ textAlign: 'center' }}>
                                {i18n.enterCodeDescription}
                            </p>
                        </Grid>
                        {needLogin && (
                            <Grid item xs={12}>
                                <InputRow
                                    item
                                    id='identifiant'
                                    label={i18n.id}
                                    type='text'
                                    value={identifiant}
                                    onChange={(event) => this.handleChangeValue(event.target.value)}
                                    variant='outlined'
                                    required
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <InputRow
                                item
                                id='password'
                                label={i18n.code}
                                type='number'
                                inputProps={{ min: 0, max: 9999 }}
                                value={resetCode}
                                onChange={(event) => this.setState({ resetCode: event.target.value })}
                                variant='outlined'
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MainButton onClick={() => this.onValidateResetCode(identifiant, resetCode)}>
                                {i18n.validate}
                            </MainButton>
                        </Grid>
                        <Grid item xs={12}>
                            <MainButton
                                id='backToLogin'
                                reverse='true'
                                onClick={() => this.setState({ hasResetCode: false })}
                            >
                                {i18n.back}
                            </MainButton>
                        </Grid>
                    </Grid>
                )
            }
            return (
                <Grid
                    container
                    className='overflowY'
                    direction='row'
                    justifyContent='space-evenly'
                    alignItems='center'
                    sx={{ height: '40%', padding: '0 20px' }}
                >
                    <Grid item container direction='column' justifyContent='center' alignItems='center'>
                        <h4 style={{ margin: '0' }}>{ i18n.forgetPassword }</h4>
                        <p style={{ textAlign: 'center' }}>
                            {i18n.enterIdDescriptionMobile}
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <InputRow
                            item
                            id='password'
                            label={i18n.id}
                            type='text'
                            value={identifiant}
                            onChange={(event) => this.handleChangeValue(event.target.value)}
                            variant='outlined'
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MainButton onClick={this.onSubmit}>
                            {i18n.send}
                        </MainButton>
                    </Grid>
                    <Grid item xs={12}>
                        <MainButton id='hasCode' onClick={() => this.setState({ hasResetCode: true, needLogin: true })}>
                            {i18n.alreadyHaveCode}
                        </MainButton>
                    </Grid>
                    <Grid item xs={12}>
                        <MainButton
                            id='cancel'
                            reverse='true'
                            onClick={() => this.props.push('/login')}
                        >
                            {i18n.cancel}
                        </MainButton>
                    </Grid>
                </Grid>
            )
        }
        return (
            <Grid
                container
                item
                sx={{ width: '45vw' }}
            >
                <Grid item container justifyContent='flex-end' xs={2} className='clickable' onClick={ back }>
                    <ArrowBack {...sieauTooltip(i18n.back)} sx={{ paddingRight: '1vw', height: 25, color: mainColor }} />
                </Grid>
                <Grid
                    item
                    container
                    xs={8}
                    direction='column'
                    justifyItems='center'
                    alignItems= 'center'
                    gap='1rem'
                >
                    <Grid item sx={{ width: '100%', fontSize: '22px', lineHeight: '28px', color: textColor }} >
                        {i18n.resetYourPassword}
                    </Grid>
                    <Grid item sx={{ width: '100%', fontSize: '12px', lineHeight: '1.5', color: textColor }}>
                        {i18n.enterIdDescription}
                    </Grid >
                    <Grid item sx={{ width: '100%' }}>
                        <InputRow
                            item
                            id='password'
                            label={i18n.id}
                            type='text'
                            value={identifiant}
                            onChange={(event) => this.handleChangeValue(event.target.value)}
                            variant='outlined'
                            required
                        />
                    </Grid>
                    <Grid item sx={{ width: '60%' }}>
                        <MainButton onClick={this.onSubmit} little sx={{ fontSize: '14px' }} >
                            {i18n.getCode}
                        </MainButton>
                    </Grid>
                    <Grid container item alignItems='center'>
                        <Grid item width='100%' xs={5}>
                            <hr/>
                        </Grid>
                        <Grid item xs={2} textAlign='center'>
                            { i18n.or }
                        </Grid>
                        <Grid item xs={5}>
                            <hr/>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ width: '100%', fontSize: '22px', lineHeight: '28px', color: textColor }}>
                        {i18n.alreadyHaveCodeTitle}
                    </Grid>
                    <Grid item sx={{ width: '100%', fontSize: '12px', lineHeight: '1.5', color: textColor }}>
                        {i18n.alreadyHaveCodeDescription}
                    </Grid>
                    <PinCode setCode={this.setCode} />
                    <Grid item sx={{ width: '60%' }}>
                        <MainButton onClick={() => this.onValidateResetCode(identifiant, resetCode)} little sx={{ fontSize: '14px' }} >
                            {i18n.resetThePassword}
                        </MainButton>
                    </Grid>
                </Grid>
                <Grid item xs={2}/>
            </Grid>

        )
    }


    render() {
        const { backgroundUrl, logoUrl, settingsLoaded, openModal } = this.state
        const { classes, applicationSettings } = this.props
        const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription') || {}
        const modal = (<>{settingsLoaded && openModal && (
            <ModalUpdatePasswordDesktop
                title={i18n.changePassword}
                label={regexHelp.value}
                open={openModal}
                toggleModal={this.toggleModalPassword}
                handleChangeNewMdp={(e) => this.setState({ newMdp: e.target.value })}
                handleChangeConfirmation={(e) => this.setState({ mdpConfirmation: e.target.value })}
                onSavePassword={this.onUpdatePassword}
                firstLogin
            />
        )}</>)
        if (isMobile) {
            return (
                <div
                    style={{
                        height: '100%',
                        margin: '-90px 0',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', height: '15%' }}>
                        <Grid
                            container
                            direction='row'
                            justifyContent='center'
                            alignItems='flex-end'
                            sx={{
                                backgroundImage: `url(${backgroundUrl})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        />
                    </div>
                    <Grid className={classes.logoTop}>
                        <img src={logoUrl} alt='' width='190px' />
                    </Grid>
                    {this.getPanel()}
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-around'
                        alignItems='center'
                        sx={{
                            overflowY: 'auto',
                            backgroundColor: 'white',
                            height: '30%',
                        }}
                    >
                        {this.getLogoPartenaires(true)}
                    </Grid>
                    { modal }
                </div>
            )
        }
        return (
            <Grid container position='fixed' zIndex='1' height='100vh' backgroundColor={veryLightColor} padding='5vh 0 5vh 5vw'>
                <Grid container item xs={7}
                    direction = 'column'
                    justifyContent='center'
                    alignItems='center'
                    borderRadius= '20px'
                    backgroundColor= '#FFF'
                >
                    {this.getPanel()}
                </Grid>
                <Grid container item xs={5}
                    justifyContent='space-around'
                    alignItems='center'
                    sx={{
                        overflowY: 'auto',
                        maxHeight: '100%',
                    }}>
                    <Grid
                        container
                        direction='column'
                        gap='10vh'
                    >
                        {this.getLogoPartenaires(false)}
                    </Grid>
                </Grid>
                { modal }
            </Grid>
        )
    }
}

const mapDispatchToProps = {
    resetPassword: HomeAction.resetPassword,
    fetchApplicationSettings: HomeAction.fetchApplicationSettings,
    verifyResetCode: HomeAction.verifyResetCode,
    updatePassword: HomeAction.updatePassword,
    fetchCMSEvents: CmsAction.fetchCMSEvents,
    logJournal: LogAction.logJournal,
    push,
}

const mapStateToProps = (store) => {
    return {
        applicationSettings: store.HomeReducer.applicationSettings,
        cmsEvents: store.CmsReducer.cmsEvents,
    }
}

Password.propTypes = {
    updatePassword: PropTypes.func,
    resetPassword: PropTypes.func,
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    fetchApplicationSettings: PropTypes.func,
    verifyResetCode: PropTypes.func,
    push: PropTypes.func,
    fetchCMSEvents: PropTypes.func,
    logJournal: PropTypes.func,
    cmsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    classes: PropTypes.instanceOf(PropTypes.shape({})),
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Password))
