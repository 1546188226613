/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { CircularProgress, Grid } from '@mui/material'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import HomeAction from '../../offline/actions/HomeAction'
import LoadingCard from '../components/cards/LoadingCard'
import { mainColor, veryLightColor } from '../components/styled/theme'
import { compact, isNil, orderBy } from 'lodash'
import { getDate } from '../../../utils/DateUtil'
import { styled } from '@mui/styles'
import { Info } from '@mui/icons-material'
import { MainButton } from '../components/styled/buttons'
import { push } from 'react-router-redux'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import { hasValue } from '../../../utils/NumberUtil'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../components/styled/dialog'
import { getMenuHeight } from '../../../utils/StyleUtils'

const Line = styled(Grid)({
    padding: '16px 8px',
})

const PointEventsApp = ({ match }) => {
    const [chroniclesLoaded] = useState(true)
    const [pointsLoaded, setPointsLoaded] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [eventDialog, setEventDialog] = useState('')

    const dispatch = useDispatch()

    const idInstallation = useMemo(() => Number(match.params.id), [match])
    const idMat = useMemo(() => Number(match.params.idMat), [match])

    const {
        exploitation,
        installations,
        variousMateriels,
        variousMatSituations,
        matEventsExploitation,
        matEventsTypes,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        matEventsExploitation: store.MaterielReducer.matEventsExploitation,
        matEventsTypes: store.MaterielReducer.matEventsTypes,
    }), shallowEqual)

    const installation = useMemo(() => installations.find((i) => i.id === idInstallation) || {}, [installations, idInstallation])

    useEffect(() => {
        dispatch(HomeAction.setTitle([{
            title: i18n.eventsOnPoint + (installation?.code ?? ''),
            href: `/consommation/point/${idInstallation}/events`,
        }]))
        if (!isNil(exploitation.idExploitation)) {
            dispatch(MaterielAction.fetchMatEventsByExploitation(exploitation.idExploitation))
        }
    }, [idInstallation, idMat, installation, exploitation.idExploitation])

    useEffect(() => {
        if (exploitation.idExploitation) {
            if (!installations.length) {
                dispatch(InstallationsAction.fetchInstallationsByExploitationId(exploitation.idExploitation)).then(() => {
                    setPointsLoaded(true)
                })
            } else {
                setPointsLoaded(true)
            }
        }
    }, [exploitation, installations])

    const { pumpsIds, countersIds, materiels } = useMemo(() => {
        const pointPumps = variousMatSituations.filter((m) => m.siteType === 7 && m.siteCode === idInstallation) || []
        const pointCounters = variousMatSituations.filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const idsPumps = pointPumps.map(({ idVarious }) => idVarious)
        const idsCounters = pointCounters.map(({ idVarious }) => idVarious)
        return {
            pumpsIds: idsPumps,
            countersIds: idsCounters,
            materiels: variousMateriels.filter((m) => [...idsPumps, ...idsCounters].includes(m.id) && (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode)),
        }
    }, [idInstallation, variousMateriels, variousMatSituations])

    if (pointsLoaded && variousMatSituations.length) {
        const eventsMat = matEventsExploitation.filter(({ idMateriel }) => [...pumpsIds, ...countersIds].includes(idMateriel))
        return (
            <div style={{ padding: '8px 1.5rem', color: mainColor }}>
                {compact(materiels.map((m) => {
                    const events = eventsMat.filter(({ idMateriel }) => idMateriel === m.id)
                    if (!events.length) {
                        return null
                    }
                    return (
                        <>
                            <Grid item fontSize={22} sx={{ marginTop: '24px' }}>{`${m.counter ? i18n.counter : i18n.pump} ${m?.reference}` ?? ''}</Grid>
                            <Grid
                                container
                                alignItems='center'
                                justifyContent='space-between'
                                sx={{ marginTop: '16px' }}
                            >
                                <Line item xs={12} container>
                                    <Grid item className='bold' xs={6} sx={{ textAlign: 'start' }}>
                                        {i18n.eventType}
                                    </Grid>
                                    <Grid item className='bold' xs={4} sx={{ textAlign: 'end' }}>
                                        {i18n.date}
                                    </Grid>
                                    <Grid item className='bold' xs={2} sx={{ textAlign: 'end' }}>
                                        {''}
                                    </Grid>
                                </Line>
                                <Grid item className xs={12} sx={{ borderBottom: `1px solid ${mainColor}` }} />
                                {chroniclesLoaded ? orderBy(events, 'eventDate', 'desc').map((e, i) => {
                                    const eventTypeName = matEventsTypes.find((t) => t.id === e.idType)?.name ?? i18n.unknown
                                    return (
                                        <Line item xs={12} container alignItems='center' sx={{ backgroundColor: i % 2 ? veryLightColor : 'white' }}>
                                            <Grid item xs={6} sx={{ textAlign: 'start', paddingLeft: 0 }}>
                                                {eventTypeName}
                                            </Grid>
                                            <Grid item xs={4} sx={{ textAlign: 'end' }}>
                                                {getDate(e.eventDate) ?? ''}
                                            </Grid>
                                            <Grid item xs={2} sx={{ textAlign: 'end' }}>
                                                {e.comment && (
                                                    <Info
                                                        onClick={() => {
                                                            setOpenDialog(true)
                                                            setEventDialog({ ...e, typeLabel: eventTypeName, mat: m.reference })
                                                        }}
                                                        sx={{ color: mainColor }}
                                                    />
                                                )}
                                            </Grid>
                                        </Line>
                                    )
                                }) : <Grid item xs={12} container justifyContent='center' sx={{ paddingTop: '16px' }}><CircularProgress /></Grid>}
                            </Grid>
                        </>
                    )
                }))}
                <Grid item style={{ position: 'sticky', bottom: getMenuHeight() }}>
                    <MainButton onClick={() => dispatch(push(`/consommation/point/${idInstallation}/events/materiels`))}>
                        {i18n.reportIncident}
                    </MainButton>
                </Grid>
                <DialogMUI open={openDialog} fullScreen={false}>
                    <DialogTitleMUI sx={{ backgroundColor: 'white', padding: '24px', color: mainColor, fontSize: '24px' }}>
                        {eventDialog.mat}
                    </DialogTitleMUI>
                    <DialogContentMUI sx={{ color: mainColor, padding: '0 10px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>{`${i18n.eventType} : ${eventDialog.typeLabel}`}</Grid>
                            <Grid item>{`${i18n.date} : ${getDate(eventDialog.eventDate)}` ?? ''}</Grid>
                            <Grid item>{`${i18n.comment} : ${eventDialog.comment}`}</Grid>
                        </Grid>
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <Grid
                            sx={{ paddingTop: '10px' }}
                            onClick={() => {
                                setOpenDialog(false)
                                setEventDialog('')
                            }}
                        >
                            {i18n.close}
                        </Grid>
                    </DialogActionsMUI>
                </DialogMUI>
            </div>
        )
    }
    return <LoadingCard />
}

PointEventsApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            idMat: PropTypes.string,
        })
    }),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
}

export default PointEventsApp
