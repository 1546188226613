// const getArrestColor = (level) => {
//     switch (level) {
//         case 3:
//             return '#AF0000'
//         case 2:
//             return '#E77B00'
//         case 1:
//             return '#E5BE00'
//         case 0: default:
//             return '#002D41'
//     }
// }
import i18n from 'simple-react-i18n'

const getArrestColor = typeArrest => {
    switch (typeArrest) {
        case i18n.crisis || 3:
            return 'red'
        case i18n.reinforcedAlert || 2:
            return 'orange'
        case i18n.warning || 1:
            return 'yellow'
        case i18n.vigilance || 0:
            return 'lightgrey'
        default:
            return 'lightgrey'
    }
}

const getRestrictionColor = (level) => {
    switch (level) {
        case 4: // Crise
            return '#AF0000'
        case 3: // Alerte renforcée
            return '#E77B00'
        case 2: // Alerte
            return '#E5BE00'
        case 1: // Vigilance
            return '#002D41'
        case -1: default: // Normal
            return '#00AF64'
        // case 2:
        //     return '#1964A8'
        // case 1: default:
        //     return '#002D41'
    }
}

const hexToRGBColor = hexColor => {
    if (hexColor) {
        const rgb = hexColor.substring(1).match(/.{2}/g).map(x => parseInt(x, 16))
        return `rgb(${rgb.join(',')})`
    }
    return null
}

/* Convert a hex string to an RGB triplet */
const convertToRGB = (hex) => {
    const hexPart = hex.charAt(0) === '#' ? hex.substring(1, 7) : hex
    const formattedHex = hexPart.length === 3 ? `${hexPart[0]}${hexPart[0]}${hexPart[1]}${hexPart[1]}${hexPart[2]}${hexPart[2]}` : hexPart
    return [
        parseInt(formattedHex.substring(0, 2), 16),
        parseInt(formattedHex.substring(2, 4), 16),
        parseInt(formattedHex.substring(4, 6), 16),
    ]
}

const getDarkerColor = (color) => {
    const rgb = convertToRGB(color)
    return `rgb(${rgb[0] - 70},${rgb[1] - 70},${rgb[2] - 70})`
}

export { getArrestColor, getRestrictionColor, hexToRGBColor, convertToRGB, getDarkerColor }