/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid } from '@mui/material'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import LoadingCard from '../components/cards/LoadingCard'
import { mainColor, screenColor } from '../components/styled/theme'
import { isNil } from 'lodash'
import { getDate } from '../../../utils/DateUtil'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import { DialogActionsMUI, DialogContentMUIDesktop, DialogMUI, DialogTitleMUIv2 } from '../components/styled/dialog'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../referencials/materiels/constants/MaterielConstants'
import Table from '../components/Table'
import { ReactComponent as PumpLogo } from '../../../ressources/static/svg/Pump.svg'
import { ReactComponent as CounterLogo } from '../../../ressources/static/svg/Counter.svg'

const PointEventsAppDesktop = ({ match, sx }) => {
    const [pointsLoaded, setPointsLoaded] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [eventDialog, setEventDialog] = useState('')

    const dispatch = useDispatch()

    const idInstallation = useMemo(() => Number(match.params.id), [match])
    const idMat = useMemo(() => Number(match.params.idMat), [match])

    const {
        exploitation,
        installations,
        variousMateriels,
        variousMatSituations,
        matEventsExploitation,
        matEventsTypes,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        matEventsExploitation: store.MaterielReducer.matEventsExploitation,
        matEventsTypes: store.MaterielReducer.matEventsTypes,
    }), shallowEqual)

    const installation = useMemo(() => installations.find((i) => i.id === idInstallation) || {}, [installations, idInstallation])

    useEffect(() => {
        if (!isNil(exploitation.idExploitation)) {
            dispatch(MaterielAction.fetchMatEventsByExploitation(exploitation.idExploitation))
        }
    }, [idInstallation, idMat, installation, exploitation.idExploitation])

    useEffect(() => {
        if (exploitation.idExploitation) {
            if (!installations.length) {
                dispatch(InstallationsAction.fetchInstallationsByExploitationId(exploitation.idExploitation)).then(() => {
                    setPointsLoaded(true)
                })
            } else {
                setPointsLoaded(true)
            }
        }
    }, [exploitation, installations])

    const { pumpsIds, countersIds } = useMemo(() => {
        const pointPumps = variousMatSituations.filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation) || []
        const pointCounters = variousMatSituations.filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const idsPumps = pointPumps.map(({ idVarious }) => idVarious)
        const idsCounters = pointCounters.map(({ idVarious }) => idVarious)
        return {
            pumpsIds: idsPumps,
            countersIds: idsCounters,
        }
    }, [idInstallation, variousMateriels, variousMatSituations])

    if (pointsLoaded && variousMatSituations.length) {
        const eventsMat = matEventsExploitation.filter(({ idMateriel }) => [...pumpsIds, ...countersIds].includes(idMateriel))

        const getIcon = (mat) => {
            if (mat.pump) {
                return <><PumpLogo style={{ paddingRight: '10px' }}/>{i18n.pump}</>
            }
            return <><CounterLogo style={{ paddingRight: '10px' }}/>{i18n.counter}</>
        }

        const getNameId = (mat) => (mat.name ? `${mat.name}/${mat.reference}` : mat.reference)

        const getEvent = (event) => (matEventsTypes.find(e => e.id === event.idType)?.name)

        const getRows = () => (
            eventsMat.map((event) => {
                const mat = variousMateriels.find(m => m.id === event.idMateriel)
                return {
                    [i18n.materiel]: { value: getIcon(mat) },
                    [i18n.nameId]: { value: getNameId(mat) },
                    [i18n.event]: { value: getEvent(event) },
                    [i18n.date]: { value: event.updateDate, toDate: true },
                    onClickValue: event
                }
            })
        )
        return (
            <Grid container item xs={12} sx={{ ...sx }}>
                <Table
                    rowClickable
                    sortable
                    transparent

                    rows={getRows()}
                    headers={[
                        [
                            { value: i18n.materiel, colSpan: 1, group: 0 },
                            { value: i18n.nameId, colSpan: 1, group: 1 },
                            { value: i18n.event, colSpan: 1, group: 2 },
                            { value: i18n.date, colSpan: 1, group: 3 }
                        ],
                    ]}
                    headersLabel={[i18n.materiel, i18n.nameId, i18n.event, i18n.date]}

                    onClickRow={(row) => {
                        setOpenDialog(true)
                        setEventDialog({ ...row.onClickValue, typeLabel: matEventsTypes.find((t) => t.id === row.onClickValue.idType)?.name ?? '', mat: row[i18n.nameId]?.value })
                    }}

                    bodyColor={screenColor}

                    defaultSort={{ sortColumn: [i18n.date, i18n.event], sortDirection: { [i18n.date]: 'desc', [i18n.event]: 'desc' } }}
                />
                <DialogMUI open={openDialog} PaperProps={{ style: { overflowX: 'hidden' } }} onClick={() => {
                    setOpenDialog(false)
                    setEventDialog('')
                }}>
                    <DialogTitleMUIv2 sx={{ padding: '24px', color: mainColor, fontSize: '24px' }}>
                        {eventDialog.mat}
                    </DialogTitleMUIv2>
                    <DialogContentMUIDesktop sx={{ color: mainColor, padding: '0 10px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item><b>{i18n.eventType}</b> : {eventDialog.typeLabel}</Grid>
                            <Grid item><b>{i18n.date}</b> : {getDate(eventDialog.eventDate)}</Grid>
                            <Grid item><b>{i18n.comment}</b> : {eventDialog.comment}</Grid>
                        </Grid>
                    </DialogContentMUIDesktop>
                    <DialogActionsMUI>
                        <Grid
                            sx={{ paddingTop: '10px' }}
                            className='clickable'
                        >
                            {i18n.close}
                        </Grid>
                    </DialogActionsMUI>
                </DialogMUI>
            </Grid>
        )
    }
    return <LoadingCard />
}

PointEventsAppDesktop.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            idMat: PropTypes.string,
        })
    }),
    sx: PropTypes.shape({})
}

export default PointEventsAppDesktop
